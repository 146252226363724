import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomerDataModel, CustomerContactDataModel } from '../../../variables/customer';
import { CustomerService } from '../../../services/customer.service';
import { NgbActiveModal, NgbNavChangeEvent, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { StatesDataModel } from '../../../variables/states';
import { StatesService } from '../../../services/states.service';
import { SortEvent, SortDirection, SortColumn } from '../../../services/sortable.directive';
import { compare } from '../../../variables/constant';

const updateCustomer = async (customerService: CustomerService, customer: CustomerDataModel): Promise<CustomerDataModel> => { 

  let response = await customerService.updateCustomer(customer,'admin')
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      if (response["message"] != "" && response["message"] != undefined) {
        alert(response["message"]);
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CustomerDataModel>response["result"];

    return result;
  }

}

const updateCustomerContact = async (customerService: CustomerService, contact: CustomerContactDataModel): Promise<CustomerContactDataModel> => {

  let response = await customerService.updateCustomerContact(contact, 'admin')
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CustomerContactDataModel>response["result"];

    return result;
  }

}


const getCustomerContact = async (customerService: CustomerService, customerId: number): Promise<CustomerContactDataModel[]> => {

  let response = await customerService.getCustomerContactsByCustomerId(customerId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        id: obj.id,
        customerId: obj.customerId,
        customerNumber: obj.customerNumber,
        emailAddress: obj.emailAddress,
        firstName: obj.firstName,
        lastName: obj.lastName,
        phoneNumber: obj.phoneNumber,
        position: obj.position,
        accessCode: obj.accessCode,
        expirationDate: obj.expirationDate,
        campaignExpiration: obj.campaignExpiration,
        hasCampaign: obj.hasCampaign
      }
    });
  }
}


const getStateList = async (statesService: StatesService): Promise<StatesDataModel[]> => {

  let response = await statesService.getAllStateAddress()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        state: obj.state,
        stateName: obj.stateName,
        stateNameFull: obj.stateNameFull,
      }
    });
  }
}

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss']
})
export class CustomerModalComponent implements OnInit {
  @Input() customer: CustomerDataModel;
  frmCustomer: UntypedFormGroup;
  submitted: boolean = false;

  frmContact: UntypedFormGroup;
  submittedContact: boolean = false;
   
  stateList: StatesDataModel[] = [];

  active: number = 0;

  contactListAll: CustomerContactDataModel[] = [];
  contactList: CustomerContactDataModel[] = [];
  collectionSizeContact: number = 0;
  pageContact: number = 1;
  pageSizeContact: number = 10;

  selectedContact: CustomerContactDataModel;

  isAddNewContact: boolean = false;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  constructor(
    public formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private customerService: CustomerService,
    private statesService: StatesService) {
    this.frmCustomer = this.formBuilder.group({
      customerId: null,
      customerNumber: ["", Validators.required],
      customerName: ["", Validators.required],
      customerName2: [""],
      address1: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
    });

    this.frmContact = this.formBuilder.group({
      id: null,
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      position: ["", Validators.required],
      phoneNumber: [""],
      emailAddress: ["", Validators.required],
      customerId: null
    });

    this.getStateList();
  }

  ngOnInit(): void {
    if (this.customer != undefined) {
      this.frmCustomer.patchValue({
        customerId: this.customer.customerId,
        customerNumber: this.customer.customerNumber,
        customerName: this.customer.customerName,
        customerName2: this.customer.customerName2,
        address1: this.customer.address1,
        city: this.customer.city,
        state: this.customer.state,
        postalCode: this.customer.postalCode,        
      });

      this.getCustomerContact();
    }
    
  }

  get f() { return this.frmCustomer.controls; }
  get fc() { return this.frmContact.controls; }

  onSort(event) {
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.contactList = sortData(this.contactList, column, this.currentSortBy);
  }

  getCustomerContact() {
    getCustomerContact(this.customerService, this.customer.customerId).then(data => {
      this.contactListAll = data;
      this.contactList = data;
      this.collectionSizeContact = data.length
    });
  }

  getStateList() {
    getStateList(this.statesService).then(data => {
      this.stateList = data;
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  submitForm() {
    if ((this.selectedContact != null || this.isAddNewContact) && this.active == 1) {
      this.submitContact();
    }
    else {
      this.submitted = true;
      if (!this.frmCustomer.invalid && this.active == 0) { //prevent update when not editing customer profile
        this.updateCustomer();
      }
    }    
  }

  updateCustomer() {
    updateCustomer(this.customerService, this.frmCustomer.value).then(data => {
      if (data != null && data != undefined) {
        if (this.active == 1) {
          this.updateCustomerContact();
        }
        this.closeModal();
      }
    });
  }

  cancelSubmit() {    
    if ((this.selectedContact != null || this.isAddNewContact) && this.active == 1) {
      this.isAddNewContact = false;
      this.submittedContact = false;      
      this.selectedContact = null;
      this.clearfrmContact();
    }
    else {
      this.closeModal();
    }
  }

  submitContact() {
    this.submittedContact = true;
    if (!this.frmContact.invalid) {
      if (this.f.customerId.value == null) {
        this.updateCustomer();
        return;
      }
      
      this.updateCustomerContact();
    }
  }

  updateCustomerContact() {
    if (this.fc.customerId.value == null) {
      this.fc.customerId.setValue(this.f.customerId.value);
    }

    if (this.fc.id.value == null) {
      this.fc.id.setValue(0);
    }
    updateCustomerContact(this.customerService, this.frmContact.value).then(data => {
      if (data != null && data != undefined) {
        this.isAddNewContact = false;
        this.selectedContact = null;
        this.getCustomerContact();
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent, nav: NgbNav) {
    if (changeEvent.nextId > 0) {
      if (this.f.customerId.value == null) {
        changeEvent.activeId = 0;
      }
    }
  }

  selectContact(data: CustomerContactDataModel) {
    if (data == null) {
      this.clearfrmContact();
      this.isAddNewContact = true;
    }
    else {
      this.frmContact.patchValue({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        position: data.position,
        phoneNumber: data.phoneNumber,
        emailAddress: data.emailAddress,
        customerId: this.f.customerId.value
      });
      this.selectedContact = data;
    }    
  }

  updateContact() {
    this.selectedContact = null;
  }

  clearfrmContact() {
    this.submittedContact = false;
    this.frmContact.patchValue({
      id: null,
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: "",
      emailAddress: ""
    });
  }

  resetPage() {
    this.pageContact = 1;
  }

}
