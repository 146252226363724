import { Routes } from '@angular/router';
import { UploadComponent } from '../../pages/eCert/upload/upload.component';
import { FillInComponent } from '../../pages/eCert/fill-in/fill-in.component';
import { CustomerComponent } from '../../pages/eCert/customer/customer.component';
import { CertificatesComponent } from '../../pages/eCert/certificates/certificates.component';



export const CustomerLayoutRoutes: Routes = [    
  { path: 'upload/:id', component: UploadComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'certificates', component: CertificatesComponent },  
  { path: 'fill/:id', component: FillInComponent },  
];
