import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerDataModel, CustomerListModel, CustomerShippingDataModel } from '../../../variables/customer';
import { CustomerService } from '../../../services/customer.service';
import { SortColumn, SortDirection, SortEvent } from '../../../services/sortable.directive';
import { compare } from '../../../variables/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerManagementAddEditComponent } from '../customer-management-add-edit/customer-management-add-edit.component';
import { ImportStatusModelComponent } from '../import-status-model/import-status-model.component';
import { CustomerSubmittedCertificatesModelComponent } from '../customer-submitted-certificates-model/customer-submitted-certificates-model.component';

@Component({
    selector: 'app-customer-management-list',
    templateUrl: './customer-management-list.component.html',
    styleUrls: ['./customer-management-list.component.scss']
})
export class CustomerManagementListComponent implements OnInit {

    @ViewChild('selectAll', { static: false }) selectAll!: ElementRef;
    @ViewChild('importFile') importFile: ElementRef;

    searchResultListAll: CustomerListModel[] = [];
    searchResultList: CustomerListModel[];
    shippingListAll: CustomerShippingDataModel[] = [];
    shippingList: CustomerShippingDataModel[] = [];
    selectedCustomer: CustomerListModel;

    //paging
    collectionSize: number = 0;
    page: number = 1;
    pageSize: number = 10;
    collectionSizeShipping: number = 0;
    pageShipping: number = 1;
    pageSizeShipping: number = 10;

    //search
    searchTerm: string = "";

    //sorting
    currentColumn: SortColumn = "";
    currentSortBy: SortDirection = "desc";
    companyCode = localStorage.getItem('company_code');

    selectedIds: any[] = [];

    constructor(private customerService: CustomerService,
        private activeModal: NgbModal) {
        this.loadData();
    }

    ngOnInit(): void {
    }

    loadData() {
        this.getCustomerList(this.customerService).then(data => {
            this.searchResultListAll = data;
            this.searchResultList = data;
            this.collectionSize = data.length;

            if (this.searchTerm != "") {
                this.searchData(this.searchTerm);
            }
        });
    }

    async getCustomerList(customerService: CustomerService): Promise<CustomerListModel[]> {
        let response = await customerService.getCustomers(this.companyCode)
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
                console.log(error);
            });
        if (response instanceof Object) {
            if (response["message"] != "" && response["message"] != undefined) {
                alert(response["message"]);
            }
            if (response["result"] instanceof Array) {
                return response["result"].map(obj => {
                    return obj;
                });
            }
            return null;
        }
    }

    async getCustomerShipping(customerService: CustomerService, customerId: number): Promise<CustomerShippingDataModel[]> {
        let response = await customerService.getCustomerShippingsByCustomerId(customerId)
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
                console.log(error);
            });
        if (response["result"] instanceof Array) {
            return response["result"].map(obj => {
                return obj;
            });
        }
    }

    approveCustomer(id: any) {
        const confirmed = confirm('Are you sure you want to approve ?');
        if (confirmed) {
            this.customerService.approveCustomerMaster(id).subscribe((res) => {
                if (res) {
                    this.loadData();
                }
            }, (error) => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
            })
        }
    }

    deleteCustomer(id: any) {
        const confirmed = confirm('Are you sure you want to delete this record?');
        if (confirmed) {
            this.customerService.deleteCustomerMaster(id).subscribe((res) => {
                if (res) {
                    this.loadData();
                }
            }, (error) => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
            })
        }
    }

    toggleCustomer(customer) {
        if (this.selectedCustomer == null) {
            this.selectedCustomer = customer;
            this.getCustomerShipping(this.customerService, customer.id).then(data => {
                if (data) {
                    this.shippingListAll = data;
                    this.shippingList = data;
                    this.collectionSizeShipping = data?.length
                }
            });
        }
        else {
            this.closeShipping();
        }
    }

    changeFile(fileInputEvent: { target: any }) {
        const files = fileInputEvent.target.files;
        if (files) {
            this.importCustomer(files[0]);
        }
    }

    importCustomer(file: any) {
        const formData = new FormData();
        formData.append('file', file);

        this.customerService
            .importCustomer(formData, this.companyCode)
            .subscribe((res) => {
                if (res) {
                    this.clearFile();
                    const modalRef = this.activeModal.open(ImportStatusModelComponent,
                        {
                            centered: true,
                            size: 'xl',
                            scrollable: true
                        });

                    modalRef.componentInstance.customersData = res?.result;
                    modalRef.result.then(
                        (data: any) => {
                            this.loadData();
                        },
                        (reason: any) => { }
                    );
                }
            }, (error) => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
                console.log(error);
            });
    }

    exportCustomer() {
        const payload = {
            customerIds: this.selectedIds || []
        }
        this.customerService
            .exportFile(payload, this.companyCode)
            .subscribe((res) => {
                if (res) {
                    this.selectAll.nativeElement.checked = false;
                    this.selectedIds = [];
                    // alert('Customer file export successfully!');
                    const base64Excel = res['file']['fileContents'];
                    this.downloadExcel(base64Excel, res['fileName']);
                }
            }, (error) => {
                if (error instanceof Object) {
                    if (error["ResponseException"] != "") {
                        alert(error["ResponseException"]);
                    }
                }
                console.log(error);
            })
    }

    exportAllCustomers() {
        this.selectedIds = [];
        this.exportCustomer();
    }

    exportSelectedCustomers() {
        if (!this.selectedIds?.length) {
            alert("Please select customers");
            return;
        } else {
            this.exportCustomer();
        }
    }

    // Handle single checkbox change
    onCheckboxChange(event: any, id: string) {
        if (event.target.checked) {
            this.selectedIds.push(id);
        } else {
            this.selectedIds = this.selectedIds.filter(i => i !== id);
        }

        if (this.selectedIds.length == 0) {
            this.selectAll.nativeElement.checked = false;
        } else {
            this.selectAll.nativeElement.checked = true;
        }
    }

    // Handle "Select All" functionality
    selectAllCheckbox(event: any) {
        if (event.target.checked) {
            this.selectedIds = this.searchResultList.map(item => item.id);
        } else {
            this.selectedIds = [];
        }
    }

    // Check if the checkbox is selected
    isChecked(id: string) {
        return this.selectedIds.includes(id);
    }

    editCustomer(customer) {
        const modalRef = this.activeModal.open(CustomerManagementAddEditComponent, { centered: true, size: 'lg', scrollable: true });
        modalRef.componentInstance.customer = customer;
        modalRef.result.then(
            (data: any) => {
                this.loadData();
            },
            (reason: any) => { }
        );
    }

    showSubmittedCertificates(customer) {
        const modalRef = this.activeModal.open(CustomerSubmittedCertificatesModelComponent, { centered: true, size: 'xl', scrollable: true });
        modalRef.componentInstance.customer = customer;
        modalRef.result.then(
            (data: any) => {
                this.loadData();
            },
            (reason: any) => { }
        );
    }

    onChangeCustomerStatus(data: CustomerListModel) {
        const confirmed = confirm('Are you sure you want to change status?');

        if (confirmed) {
            let payload = {
                payorAccountNumber: data.payorAccountNumber,
                status: data.status && data.status == 'Active' ? 2 : 1
            }

            this.customerService
                .changeCustomerMasterStatus(payload)
                .subscribe((res) => {
                    if (res) {
                        this.loadData();
                    }
                }, (error: Error) => {
                    if (error instanceof Object) {
                        if (error["ResponseException"] != "") {
                            alert(error["ResponseException"]);
                        }
                    }
                    console.log(error);
                });
        }
    }

    downloadExcel(base64String: string, fileName: string) {
        // Convert base64 string to binary data
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link for the Blob and trigger download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName || 'download.xlsx';

        // Append link to body, click, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL to free memory
        URL.revokeObjectURL(url);
    }

    isTaxableLabel(val) {
        switch (val) {
            case 0:
                return "Pending";
            case 1:
                return "Taxable";

            case 2:
                return "Non Taxable";

            default:
                return "Pending";
        }
    }

    closeShipping() {
        this.selectedCustomer = null;
    }

    resetPage() {
        this.page = 1;
        this.pageShipping = 1;
    }

    clearFile(): void {
        this.importFile.nativeElement.value = '';
    }

    onSort(event) {
        this.page = 1; //bring back to page 1
        var column = event.target.getAttribute("sortable");
        if (this.currentColumn !== column && this.currentColumn !== "") {
            this.currentColumn = column;
            this.currentSortBy = "asc"; //revert to default sort      
        } else {
            if (this.currentColumn == "") { //first sort
                this.currentColumn = column;
                if (this.currentColumn == "customerName") { //by default, customerName is already sorted to asc
                    this.currentSortBy = "desc";
                }
            } else {
                if (this.currentSortBy == "asc") {
                    this.currentSortBy = "desc";
                } else {
                    this.currentSortBy = "asc";
                }
            }
        }
        this.searchResultList = this.sortData(this.searchResultList, column, this.currentSortBy);
    }

    onSortShipping(event) {
        this.pageShipping = 1; //bring back to page 1
        var column = event.target.getAttribute("sortable");
        if (this.currentColumn !== column && this.currentColumn !== "") {
            this.currentColumn = column;
            this.currentSortBy = "asc"; //revert to default sort      
        } else {
            if (this.currentColumn == "") { //first sort
                this.currentColumn = column;
                if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
                    this.currentSortBy = "desc";
                }
            } else {
                if (this.currentSortBy == "asc") {
                    this.currentSortBy = "desc";
                } else {
                    this.currentSortBy = "asc";
                }
            }
        }
        this.shippingList = this.sortData(this.shippingList, column, this.currentSortBy);
    }

    searchData(_term: string) {
        let term = _term.toLowerCase();
        this.searchTerm = term;
        if (_term == "") {
            this.searchResultList = this.searchResultListAll;
        }
        else {
            this.searchResultList = this.searchResultListAll.filter(data =>
                (data.customerName == null ? "" : data.customerName).toLowerCase().indexOf(term) > -1
                || (data.phoneNumber == null ? "" : data.phoneNumber).toLowerCase().indexOf(term) > -1
                || (data.email == null ? "" : data.email).toLowerCase().indexOf(term) > -1
                || (data.payorAccountNumber == null ? "" : data.payorAccountNumber).toLowerCase().indexOf(term) > -1
                || (data.dateModified == null ? "" : data.dateModified).toLowerCase().indexOf(term) > -1
                || (data.status == null ? "" : data.status).toLowerCase().indexOf(term) > -1
            );
        }
        this.collectionSize = this.searchResultList.length;
        this.page = 1;
    }

    sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
        if (direction === '' || column === '') {
            return datalist;
        } else {
            return [...datalist].sort((a, b) => {
                const res = compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }
}
