import { Routes } from '@angular/router';
import { ClientComponent } from '../../pages/client/client.component';
import { CustomerSearchComponent } from '../../pages/customer/customer-search/customer-search.component';
import { CertificatesComponent } from '../../pages/certificates/certificates/certificates.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { UpdatePasswordComponent } from '../../pages/update-password/update-password.component';
import { CustomerManagementListComponent } from 'src/app/pages/customer-management/customer-management-list/customer-management-list.component';



export const ClientLayoutRoutes: Routes = [  
  { path: '', component: ClientComponent },
  { path: 'customer', component: CustomerSearchComponent },
  { path: 'customer-management', component: CustomerManagementListComponent },
  { path: 'certificates', component: CertificatesComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'update-password/:userId', component: UpdatePasswordComponent }
];
