import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { ClientLayoutRoutes } from './client-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ClientComponent } from '../../pages/client/client.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ClientLayoutRoutes),
    FormsModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    SignaturePadModule
  ],
  declarations: [    
    ClientComponent
  ],
  exports: [
    
  ],
  bootstrap: [
    
  ],
  providers: [
    DecimalPipe],
  schemas: [NO_ERRORS_SCHEMA]
})

export class ClientLayoutModule { }
