import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIResponse } from '../variables/api-response';
import { CommonService } from "./common.service";
import { IEmailTemplateModel } from "../variables/editor";


@Injectable({
  providedIn: 'root'
})

export class EditorService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }

  getEmailTemplates() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/editor/emailTemplates')
  }

  updateTemplate(editor: IEmailTemplateModel) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/editor/updateEmailTemplate', editor)
  }
}
