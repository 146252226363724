import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StatesService } from '../../../services/states.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { StatesDataModel } from '../../../variables/states';
import { NgbPanelChangeEvent, NgbAccordion, NgbPanel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../../services/customer.service';
import { CampaignService } from '../../../services/campaign.service';
import { CustomerDataModel } from '../../../variables/customer';
import { DeliveryMethod, Exposures } from '../../../variables/constant';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { CampaignModel, CampaignCustomerModel, ExposureModel } from '../../../variables/campaign';
import { CommonService } from '../../../services/common.service';
import { LoadingComponent } from '../../../helper/loading'




const createCampaign = async (campaignService: CampaignService, campaign: CampaignModel): Promise<CampaignCustomerModel[]> => {

  let response = await campaignService.createCampaign(campaign)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        campaignId: obj.campaignId,
        customerId: obj.customerId,
        customerName: obj.customerName,
        customerNumber: obj.customerNumber,
        emailAddress: obj.emailAddress,
        emailStatus: obj.emailStatus,
        mailingAddress: obj.mailingAddress,
        mailStatus: obj.mailStatus,
        formList: obj.formList
      }
    });
  }
}


const getCustomerList = async (customerService: CustomerService): Promise<CustomerDataModel[]> => {

  let response = await customerService.getCustomerByCompanyId()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          customerId: obj.customerId,
          customerName: obj.customerName,
          customerNumber: obj.customerNumber,
          address1: obj.address1,
          city: obj.city,
          state: obj.state,
          postalCode: obj.postalCode,
          emailAddress: obj.emailAddress          
        }
      });
    }
    return null;
  }

}

const getStateList = async (stateService: StatesService): Promise<StatesDataModel[]> => {

  let response = await stateService.getAllStates()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          state: obj.state,
          stateName: obj.stateName,
          stateNameFull: obj.stateNameFull,
          isSelected: false
        }
      });
    }
    return null;
  }

}

const getViewPDFMail = async (campaignService: CampaignService, campaignId: number, customerID: number): Promise<string> => {

  let data = await campaignService.getViewPDFMail(campaignId, customerID)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f: any = data["result"];

    if (data["result"] !== null) {
      return f;
    }
  }
  return null;
}


@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCampaignComponent implements OnInit {
  
  currStep: string="1";
  stateList: StatesDataModel[]=[];
  customerList: CustomerDataModel[]=[];  
  campaignCustomerList: CampaignCustomerModel[] = [];  
  exposureList: any[] = [];

  submittedSearch: boolean = false;
  submittedDM: boolean = false;
  submittedCM: boolean = false;

  @ViewChild('acc') acc: NgbAccordion;

  frmSearch: UntypedFormGroup;
  frmDeliveryMethod: UntypedFormGroup;
  frmCampaignName: UntypedFormGroup;

  //EmailContent
  EmailContent: string = "";
  

  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  stateSettings = {};
  customerSettings = {};
  exposureSettings = {};

  recipients: string = "";

  question: string = "";

  public get deliveryMethods(): typeof DeliveryMethod {
    return DeliveryMethod;
  }

  public get exposures(): typeof Exposures {
    return Exposures;
  }


  constructor(private router: Router,
    public formBuilder: UntypedFormBuilder,
    private stateService: StatesService,
    private customerService: CustomerService,
    private campaignService: CampaignService,
    private commonService: CommonService,
    private activeModal: NgbModal) {
   
  }

  ngOnInit(): void {
    this.commonService.setCurrentNav("Create Campaign");
    this.stateSettings = {
      singleSelection: false,
      idField: 'state',
      textField: 'stateName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.customerSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'customerName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.exposureSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    
    this.loadData();    
    
  }


  loadData() {
    getStateList(this.stateService).then(data => {
      this.stateList = data;
    });

    getCustomerList(this.customerService).then(data => {
      this.customerList = data;          
    });

    Object.entries(this.exposures).map(([key, value]) => {      
      this.exposureList.push({ value: value, key: key });
    });

    this.frmSearch = this.formBuilder.group({
      states: ["", Validators.required],
      customers: ["", Validators.required],
      exposures: ["", Validators.required]
    });   
    this.frmDeliveryMethod = this.formBuilder.group({
      deliveryMethod: ["", Validators.required]
    });
    this.frmCampaignName = this.formBuilder.group({
      campaignName: ["", Validators.required]
    });

    
  }

  get fs() { return this.frmSearch.controls; }
  get fd() { return this.frmDeliveryMethod.controls; }
  get fc() { return this.frmCampaignName.controls; }

  gotoNext() {    
    this.currStep = (parseInt(this.currStep) + 1).toString();
    let pnl = this.acc.panels.find(x => x.id == this.currStep);
    if (pnl != null) {
      pnl.disabled = false;
    }
  }

  submitSearch() {    
    this.submittedSearch = true;    
    if (!this.frmSearch.invalid) {      
      this.recipients = this.fs.states.value.length + " States, " + this.fs.customers.value.length + " Customers";
      this.gotoNext(); 
    }
  }

  submitDM() {
    this.submittedDM = true;
    if (!this.frmDeliveryMethod.invalid) {      
      this.gotoNext();
    }
  }

  submitCM() {
    this.commonService.setCursorWait(true);
    this.submittedCM = true;
    //document.getElementById("loader").style.display = "block";
    if (!this.frmCampaignName.invalid) {
      let _stateList: StatesDataModel[] =[];
      if (this.fs.states.value instanceof Array) {
        _stateList = this.fs.states.value;
      }

      let _customerList: CustomerDataModel[] = [];
      if (this.fs.customers.value instanceof Array) {
        let _tmpCL = this.customerList;
        this.fs.customers.value.forEach(x => {
          let _tmp: CustomerDataModel = _tmpCL.find(t => t.customerId == x.customerId);
          _customerList.push(_tmp);
        });        
      }

      let _exposureList: ExposureModel[] = [];
      if (this.fs.exposures.value instanceof Array) {
        _exposureList = this.fs.exposures.value;
      }    

      let campaign: CampaignModel = {
        campaignName: this.fc.campaignName.value,
        deliveryMethod: this.fd.deliveryMethod.value,
        stateList: _stateList,
        customerList: _customerList,
        exposureList: _exposureList
      };
      createCampaign(this.campaignService, campaign).then(data => {        
        this.campaignCustomerList = data;
        this.collectionSize = data.length;
        this.gotoNext();
        this.commonService.setCursorWait(false);

        if (data.length > 0) {
          let pnls = this.acc.panels.filter(x => parseInt(x.id) < parseInt(this.currStep));
          pnls.forEach(pnl => {
            pnl.disabled = true;
          });
        }
        this.submittedCM = false;
      });      
      
    }
  }

  startOver(modal) {
    this.question = "Reset selected options?";
    this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: false });
    
  }

  answerModal(answer) {
    if (answer == "Yes") {
      this.recipients = "";
      this.fs.states.setValue("");
      this.fs.customers.setValue("");
      this.fs.exposures.setValue("");

      this.fd.deliveryMethod.setValue(null);

      this.fc.campaignName.setValue("");       
    }
    this.submittedSearch = false;
    this.submittedDM = false;
    this.submittedCM = false;
    this.campaignCustomerList = [];
    this.collectionSize = 0;
    this.currStep = "1";
    this.acc.panels.find(x => x.id == this.currStep).disabled = false;
    this.closeModal();
  }


  closeModal() {
    this.activeModal.dismissAll();
  }

  getViewPDFMail(modal, certificate) {
    getViewPDFMail(this.campaignService, certificate.campaignId, certificate.customerId).then(data => {
      if (data != "" && data != undefined && data != null) {
        data = data.split('<p>').join('');
        data = data.split('</p>').join('<br />');
        this.EmailContent = data;
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
  }

  printPDF(elem) {
    var mywindow = window.open('', 'PRINT', 'height=720,width=1280');
    let content: HTMLInputElement = <HTMLInputElement>document.getElementById(elem);
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style>body{margin: 20mm 20mm 20mm 20mm;}</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(content.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    mywindow.close();

    return true;
  }
}
