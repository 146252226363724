import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomerDataModel, CustomerListModel, CustomerShippingDataModel, CustomerShippingListModel, createCustomerModel, createShippingModel, updateShippingModel } from '../../../variables/customer';
import { CustomerService } from '../../../services/customer.service';
import { NgbActiveModal, NgbNavChangeEvent, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { StatesDataModel } from '../../../variables/states';
import { StatesService } from '../../../services/states.service';
import { SortEvent, SortDirection, SortColumn } from '../../../services/sortable.directive';
import { compare } from '../../../variables/constant';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-customer-modal',
    templateUrl: './customer-management-add-edit.component.html',
    styleUrls: ['./customer-management-add-edit.component.scss']
})
export class CustomerManagementAddEditComponent implements OnInit {
    @Input() customer: any;
    NoWhiteSpaceValidator = Validators.pattern(/^\S*$/);

    frmCustomer: UntypedFormGroup;
    submitted: boolean = false;
    frmShipping: UntypedFormGroup;
    submittedShipping: boolean = false;
    stateList: StatesDataModel[] = [];
    active: number = 0;
    shippingListAll: CustomerShippingDataModel[] = [];
    shippingList: CustomerShippingDataModel[] = [];
    collectionSizeShipping: number = 0;
    pageShipping: number = 1;
    pageSizeShipping: number = 10;
    selectedShipping: CustomerShippingDataModel;
    isAddNewShipping: boolean = false;
    //sorting
    currentColumn: SortColumn = "";
    currentSortBy: SortDirection = "desc";
    stateSettings = {};
    companyCode = localStorage.getItem('company_code');
    formattedDateTime: string = ''; // For displaying the formatted date-time

    selectedCountry: any;
    selectedSecondaryCountry: any;

    // List of countries with flags and codes
    countries = [
        { code: '+91', name: 'India', flag: './assets/svg/in.svg' },
        { code: '+1', name: 'United States', flag: './assets/svg/us.svg' },
    ];

    constructor(
        public formBuilder: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private customerService: CustomerService,
        private statesService: StatesService,
        private datePipe: DatePipe
    ) {
        this.initForm();
        this.getStateList();
    }

    ngOnInit(): void {
        this.stateSettings = {
            singleSelection: true,
            idField: 'state',
            textField: 'stateName',
            enableCheckAll: true,
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect All',
            allowSearchFilter: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 200,
            itemsShowLimit: 5,
            searchPlaceholderText: 'Search',
            noDataAvailablePlaceholderText: 'No records found',
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false
        };
        this.patchFormValues();
        this.frmCustomer.controls['isVip'].valueChanges.subscribe((res) => {
            if (res == true) {
                this.frmCustomer.controls['isTaxable'].enable();
            } else {
                this.frmCustomer.controls['isTaxable'].disable();
            }
        })
    }

    get f() { return this.frmCustomer.controls; }
    get fc() { return this.frmShipping.controls; }

    initForm() {
        const formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

        this.frmCustomer = this.formBuilder.group({
            id: null,
            companyCode: [this.companyCode || null],
            payorAccountNumber: ["", [Validators.required,Validators.maxLength(15)]],
            customerName: ["", Validators.required],
            email: ["", [Validators.required,Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
            // shippingAccountNumber: ["", Validators.required],
            phoneNumber: ["", [
                Validators.required,
                this.NoWhiteSpaceValidator,
                this.onlyNumbersValidator,
                Validators.minLength(7),
                Validators.maxLength(15)
            ]],
            secondaryPhoneNumber: ["", [
                // Validators.required,
                this.NoWhiteSpaceValidator,
                this.onlyNumbersValidator,
                Validators.minLength(7),
                Validators.maxLength(15)
            ]],
            address: ["", Validators.required],
            state: ["", Validators.required],
            pincode: ["", Validators.required],
            isTaxable: ["0"],
            isVip: [false],
            createdInSAP: [formattedDate, Validators.required]
        });

        this.frmShipping = this.formBuilder.group({
            id: null,
            companyCode: [this.companyCode || null],
            payorAccountNumber: [""],
            shippingAccountNumber: ["", Validators.required],
            // email: ["", Validators.required],
            // phoneNumber: ["", Validators.required],
            // shipToAddress: ["", Validators.required],
            shipToState: ["", Validators.required],
            // shipToPincode: ["", Validators.required],
            isTaxable: ["0"],
            createdInSAP: [formattedDate, Validators.required],
        });

        setTimeout(() => {
            if (this.customer) {
                this.frmCustomer.controls['createdInSAP'].disable();
                this.frmCustomer.controls['payorAccountNumber'].disable();
            }
            if (this.frmCustomer.controls['isVip'].value == true) {
                this.frmCustomer.controls['isTaxable'].enable();
            } else {
                this.frmCustomer.controls['isTaxable'].disable();
            }
        })
    }

    patchFormValues() {
        if (this.customer != undefined) {
            // this.frmCustomer.patchValue(this.customer);
            const selectedCountry = this.splitPhoneNumber(this.customer.phoneNumber).countryCode;
            this.selectedCountry = this.countries.find((el) => el.code == selectedCountry);

            const selectedSecondaryCountry = this.splitPhoneNumber(this.customer.secondaryPhoneNumber).countryCode;
            this.selectedSecondaryCountry = this.countries.find((el) => el.code == selectedSecondaryCountry);

            this.frmCustomer.patchValue({
                id: this.customer.id,
                companyCode: this.companyCode || null,
                payorAccountNumber: this.customer.payorAccountNumber,
                customerName: this.customer.customerName,
                email: this.customer.email,
                phoneNumber: this.splitPhoneNumber(this.customer.phoneNumber).localNumber,
                secondaryPhoneNumber: this.splitPhoneNumber(this.customer.secondaryPhoneNumber).localNumber,
                address: this.customer.address,
                state: this.customer.state,
                pincode: this.customer.pincode,
                isTaxable: this.customer.isTaxable || 0,
                isVip: this.customer.isVip,
                createdInSAP: this.datePipe.transform(this.customer.createdInSAP, 'yyyy-MM-dd')
            });
            this.getCustomerShippingList();
        } else {
            this.selectedCountry = this.countries[0];
            this.selectedSecondaryCountry = this.countries[0];
        }
    }

    async getCustomerShipping(customerId: number): Promise<CustomerShippingListModel[]> {
        let response = await this.customerService.getCustomerShippingsByCustomerId(customerId)
            .toPromise()
            .catch(error => {
                console.log(error);
            });
        if (response instanceof Array) {
            return response.map(obj => {
                return obj;
            });
        }
    }

    getCustomerShippingList() {
        this.getCustomerShipping(this.customer.id).then(data => {
            this.shippingListAll = data;
            this.shippingList = data;
            this.collectionSizeShipping = data.length
        });
    }

    getStateList() {
        this.getStateListData().then(data => {
            this.stateList = data;
        });
    }

    async getStateListData(): Promise<StatesDataModel[]> {
        let response = await this.statesService.getAllStateAddress()
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["message"] != "") {
                        alert(error["message"]);
                    }
                }
                console.log(error);
            });
        if (response["result"] instanceof Array) {
            return response["result"].map(obj => {
                return {
                    state: obj.state,
                    stateName: obj.stateName,
                    stateNameFull: obj.stateNameFull,
                }
            });
        }
    }

    submitForm() {
        if (this.active === 0) {
            this.submitted = true;
            if (!this.frmCustomer.invalid) {
                let formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
                if (this.f.createdInSAP.value) {
                    const currentDate = new Date(this.f.createdInSAP.value);
                    formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd') + 'T' + this.datePipe.transform(currentDate, 'HH:mm:ss');
                }

                const formData = this.frmCustomer.getRawValue();

                let payload = {
                    id: formData.id,
                    companyCode: formData.companyCode,
                    payorAccountNumber: formData.payorAccountNumber,
                    customerName: formData.customerName,
                    email: formData.email,
                    phoneNumber: this.selectedCountry['code'] + formData.phoneNumber,
                    secondaryPhoneNumber: formData.secondaryPhoneNumber,
                    address: formData.address,
                    state: formData.state,
                    pincode: formData.pincode,
                    isTaxable: Number(formData.isTaxable),
                    isVip: formData.isVip,
                    createdInSAP: formattedDate
                }

                if (this.frmCustomer.controls.secondaryPhoneNumber.value) {
                    payload.secondaryPhoneNumber = this.selectedSecondaryCountry['code'] + this.frmCustomer.controls.secondaryPhoneNumber.value;
                }

                if (this.f.id.value == null) {
                    this.createCustomer(payload).then(data => {
                        if (data != null && data != undefined) {
                            this.frmCustomer.reset();
                            this.closeModal();
                        }
                    });
                } else {
                    this.updateCustomer(payload);
                }
            }
        } else {
            this.submittedShipping = true;
            if (!this.frmShipping.invalid) {
                if (this.fc.id.value == null) {
                    const payload = {
                        id: this.fc.id.value || null,
                        companyCode: this.companyCode,
                        payorAccountNumber: this.customer.payorAccountNumber || null,
                        shippingAccountNumber: this.fc.shippingAccountNumber.value || null,
                        // email: this.fc.email.value || null,
                        // phoneNumber: this.fc.phoneNumber.value || null,
                        // shipToAddress: this.fc.shipToAddress.value || null,
                        shipToState: this.fc.shipToState.value || null,
                        // shipToPincode: this.fc.shipToPincode.value || null,
                        isTaxable: Number(this.fc.isTaxable.value) || 0,
                        createdInSAP: this.fc.createdInSAP.value || null,
                    }
                    this.createShipping(payload).then(data => {
                        if (data != null && data != undefined) {
                            this.frmShipping.reset();
                            this.cancelSubmit();
                            this.getCustomerShippingList();
                        }
                    });
                } else {
                    // update

                    const payload = {
                        id: this.fc.id.value || null,
                        payorAccountNumber: this.customer.payorAccountNumber || null,
                        customerName: this.customer.customerName,
                        email: this.fc.email.value || null,
                        shippingAccountNumber: this.fc.shippingAccountNumber.value || null,
                        state: this.fc.shipToState.value || null,
                        created: this.fc.createdInSAP.value || null,
                        companyCode: this.companyCode,
                    }
                    this.updateShipping(payload).then(data => {
                        if (data != null && data != undefined) {
                            this.frmShipping.reset();
                            this.cancelSubmit();
                            this.getCustomerShippingList();
                        }
                    });
                }
            }
        }
    }

    async createCustomer(customer: createCustomerModel): Promise<CustomerDataModel> {
        let response = await this.customerService.createCustomer(customer)
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["message"] != "") {
                        alert(error["message"]);
                    }
                }
                if (response["message"] != "" && response["message"] != undefined) {
                    alert(response["message"]);
                }
                console.log(error);
            });
        if (response instanceof Object) {
            if (response["message"] != "" && response["message"] != undefined) {
                alert(response["message"]);
            }
            let result = <any>response["result"];

            return result;
        }

    }

    updateCustomer(customer: createCustomerModel) {
        this.customerService.updateCustomerMaster(customer).subscribe((res) => {
            if (res) {
                this.frmCustomer.reset();
                this.closeModal();
            }
        }, (error) => {
            if (error instanceof Object) {
                if (error["ResponseException"] != "") {
                    alert(error["ResponseException"]);
                }
            }
        })
    }

    cancelSubmit() {
        if ((this.selectedShipping != null || this.isAddNewShipping) && this.active == 1) {
            this.isAddNewShipping = false;
            this.submittedShipping = false;
            this.selectedShipping = null;
            this.clearfrmShipping();
        }
        else {
            this.closeModal();
        }
    }

    async createShipping(data: createShippingModel): Promise<CustomerShippingDataModel> {
        let response = await this.customerService.createShipping(data)
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["ResponseExceptionTitle"] != "") {
                        alert(error["ResponseExceptionTitle"]);
                    }
                }
                if (response["ResponseExceptionTitle"] != "" && response["ResponseExceptionTitle"] != undefined) {
                    alert(response["ResponseExceptionTitle"]);
                }
                console.log(error);
            });
        if (response instanceof Object) {
            // if (response["message"] != "" && response["message"] != undefined) {
            //     alert(response["message"]);
            // }
            let result = <any>response["result"];

            return result;
        }

    }

    async updateShipping(data: updateShippingModel): Promise<any> {
        let response = await this.customerService.updateShipping(data)
            .toPromise()
            .catch(error => {
                if (error instanceof Object) {
                    if (error["message"] != "") {
                        alert(error["message"]);
                    }
                }
                if (response["message"] != "" && response["message"] != undefined) {
                    alert(response["message"]);
                }
                console.log(error);
            });
        if (response instanceof Object) {
            if (response["message"] != "" && response["message"] != undefined) {
                alert(response["message"]);
            }
            let result = <any>response["result"];

            return result;
        }
    }

    deleteShipping(data: CustomerShippingDataModel) {
        const confirmed = confirm('Are you sure you want to delete this record?');
        if (confirmed) {
            this.customerService.deleteShipping(data.id).subscribe((res) => {
                if (res) {
                    this.getCustomerShippingList();
                }
            }, (error) => {
                if (error instanceof Object) {
                    if (error["message"] != "") {
                        alert(error["message"]);
                    }
                }
            })
        }
    }

    onNavChange(changeEvent: NgbNavChangeEvent, nav: NgbNav) {
        if (changeEvent.nextId > 0) {
            if (this.f.id.value == null) {
                changeEvent.activeId = 0;
            }
        }

        if (changeEvent.activeId == 1) {
            this.isAddNewShipping = false;
            this.submittedShipping = false;
            this.selectedShipping = null;
        }
    }

    selectShipping(data: CustomerShippingDataModel) {
        if (data == null) {
            this.clearfrmShipping();
            this.isAddNewShipping = true;
        }
        else {
            this.frmShipping.patchValue(
                {
                    id: data?.id,
                    companyCode: this.companyCode,
                    payorAccountNumber: this.customer.payorAccountNumber,
                    shippingAccountNumber: data.shippingAccountNumber,
                    shipToState: data?.shipToState || null,
                    isTaxable: data?.isTaxable || '0',
                    createdInSAP: data?.createdInSAP,
                }
            );
            this.selectedShipping = data;
        }
    }


    clearfrmShipping() {
        this.submittedShipping = false;
        this.selectedShipping = null;
        this.frmShipping.reset();
        this.frmShipping.controls['companyCode'].setValue(this.companyCode);
        const formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.frmShipping.controls['createdInSAP'].setValue(formattedDate);

        // this.frmShipping.controls['isTaxable'].setValue(false);
        if (this.customer) {
            this.frmShipping.controls['payorAccountNumber'].setValue(this.customer.payorAccountNumber);
            this.frmShipping.controls['payorAccountNumber'].disable();
        }
        this.frmShipping.controls['shipToState'].setValue("");
        const isTaxableVal = this.frmShipping.controls['isTaxable'].value;
        this.frmShipping.controls['isTaxable'].setValue(isTaxableVal ? isTaxableVal : '0');
        if (this.customer?.isVip == true) {
            this.frmShipping.controls['isTaxable'].enable();
        } else {
            this.frmShipping.controls['isTaxable'].disable();
        }
    }

    // Handle country selection
    selectCountry(country: any) {
        this.selectedCountry = country;
    }

    selectSecondaryCountry(country: any) {
        this.selectedSecondaryCountry = country;
    }

    resetPage() {
        this.pageShipping = 1;
    }

    closeModal() {
        this.activeModal.close();
    }

    onSort(event) {
        var column = event.target.getAttribute("sortable");
        if (this.currentColumn !== column && this.currentColumn !== "") {
            this.currentColumn = column;
            this.currentSortBy = "asc"; //revert to default sort      
        } else {
            if (this.currentColumn == "") { //first sort
                this.currentColumn = column;
                if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
                    this.currentSortBy = "desc";
                }
            } else {
                if (this.currentSortBy == "asc") {
                    this.currentSortBy = "desc";
                } else {
                    this.currentSortBy = "asc";
                }
            }
        }
        this.shippingList = this.sortData(this.shippingList, column, this.currentSortBy);
    }

    sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
        if (direction === '' || column === '') {
            return datalist;
        } else {
            return [...datalist].sort((a, b) => {
                const res = compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }

    onDateChange(): void {
        if (this.fc.createdInSAP.value) {
            const dateTime = new Date(this.fc.createdInSAP.value);
            this.fc.createdInSAP.setValue(this.toDateTimeLocal(new Date(dateTime.toISOString())));
        }
    }

    toDateTimeLocal(date: Date): string {
        const ten = (i: number) => (i < 10 ? '0' : '') + i;
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());
        return `${YYYY}-${MM}-${DD}T${HH}:${II}`;
    }

    splitPhoneNumber(phoneNumber: string) {
        let countryCode = "";
        let localNumber = "";

        // Check for +1 (USA) or +91 (India) as the country code
        if (phoneNumber.startsWith("+1")) {
            countryCode = "+1";
            localNumber = phoneNumber.substring(2); // Remove the country code part
        } else if (phoneNumber.startsWith("+91")) {
            countryCode = "+91";
            localNumber = phoneNumber.substring(3); // Remove the country code part
        } else {
            countryCode = this.countries[0]['code'];
            localNumber = phoneNumber; // Remove the country code part
        }
        return { countryCode: countryCode || this.countries[0]['code'], localNumber };
    }

    // Custom validator to check if phone number contains only numeric characters
    onlyNumbersValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (!/^[0-9]*$/.test(value)) {
            return { invalidCharacters: true };
        }
        return null;
    }

    isTaxableLabel(val) {
        switch (val) {
            case 0:
                return "Pending";
            case 1:
                return "Taxable";

            case 2:
                return "Non Taxable";

            default:
                return "Pending";
        }
    }

}
