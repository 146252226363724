import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, Validator, ValidatorFn } from '@angular/forms';
import { IUserDataModel, IRoleDataModel } from '../../variables/user';
import { NgbTypeahead, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { first, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { CompanyDataModel } from '../../variables/common';
import { SystemRole } from '../../variables/constant';


const updateUser = async (userService: UserService, user: IUserDataModel): Promise<IUserDataModel> => {
  console.log(user);
  let response = await userService.updateUser(user)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}
const getUserById = async (userService: UserService, id: string): Promise<IUserDataModel> => {

  let response = await userService.getUserById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}


const getAllCompany = async (companyService: CompanyService): Promise<CompanyDataModel[]> => {

  let response = await companyService.getAllCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          companyId: obj.companyId,
          companyCode: obj.companyCode,
          companyName: obj.companyName,
          emailAddress: obj.emailAddress
        }
      });
    }
    return null;
  }

}

const getUserRoles = async (userService: UserService): Promise<IRoleDataModel[]> => {

  let response = await userService.getAllRoles()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          id: obj.id,
          name: obj.name
        }
      });
    }
    return null;
  }

}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userProfileForm: UntypedFormGroup;
  @Input() user: IUserDataModel;
  public roleList: IRoleDataModel[];
  public companyList: CompanyDataModel[];
  model: any;
  defaultRole: IRoleDataModel;
  filterRoleList: IRoleDataModel[];
  submitted: boolean = false;
  public accountID: any = "0"


  public get systemRoles(): typeof SystemRole {
    return SystemRole;
  }

  constructor(private router: Router, route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private companyService: CompanyService) {

  }


  ngOnInit(): void {
    this.accountID = this.authService.getId()
    this.userProfileForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      middleName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      isActive: new UntypedFormControl(''),
      role: new UntypedFormControl('', [Validators.required]),
      roleId: new UntypedFormControl(''),
      temporaryPassword: new UntypedFormControl(''),
      isPasswordReset: new UntypedFormControl(''),
      tmpRole: new UntypedFormControl(''),
      companyId: new UntypedFormControl('')
    });


    getUserRoles(this.userService).then(data => {
      this.roleList = data.filter(x => x.name != SystemRole.CUSTOMER);
      if (this.user != undefined) {
        this.defaultRole = this.roleList.filter(x => x.name == this.user.role)[0];
        alert(this.roleList.filter(x => x.name == this.user.role)[0])
        if (this.defaultRole != null)
          this.f.tmpRole.setValue(this.defaultRole);
      }
    });

    getAllCompany(this.companyService).then(data => {
      this.companyList = data;
    });

    let identityUserId = this.accountID;

    

    if (identityUserId != "") {
      getUserById(this.userService, identityUserId).then(data => {
        if (data.roleId != null && this.roleList != null) {
          this.defaultRole = this.roleList.filter(x => x.id == data.roleId)[0];
          data.roleId = this.defaultRole.name;
        }
        
        this.userProfileForm.patchValue({
          id: data.id,
          userName: data.userName,
          email: data.email,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          isActive: data.isActive,
          role: data.role,
          temporaryPassword: data.temporaryPassword,
          isPasswordReset: data.isPasswordReset,
          roleId: data.roleId,
          companyId: data.companyId
        });
      });
    }
   

  }


  get f() { return this.userProfileForm.controls; }

  onGenerateTemporaryPassword() {
    this.f.temporaryPassword.setValue(Math.random().toString(36).slice(-8));
    return false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.f.role.value == SystemRole.CLIENT && this.f.companyId.value == null) {
      return false;
    }
    if (this.f.companyId.value != null) {
      this.f.companyId.setValue(parseInt(this.f.companyId.value));
    }
    if (!this.userProfileForm.invalid) {
      updateUser(this.userService, this.userProfileForm.value).then(data => {
        if (data != null) {
          alert("Profile Updated Successfully")
        }
      });
    }
  }


  profileredirect() {
    if (this.authService.userRole$ == SystemRole.CLIENT) {
      this.router.navigate(['/client/update-password/' + this.f.id.value])
    } else {
      this.router.navigate(['/admin/update-password/' + this.f.id.value])
    }
  }


  role_change(role) {
    if (role != SystemRole.CLIENT) {
      this.f.companyId.setValue(null);
    }
  }

  formatter = (x: { name: string }) => x.name;


}

function roleValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (Object.keys(control.value).length > 0) {
    if (Object.keys(control.value)[0] == "roleId" && (control.value["roleId"] == null)) {
      return { "roleInvalid": true };
    } else if (control.value == "") {
      return { "roleInvalid": true };
    }
  }

  return null;
}


