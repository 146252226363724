import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthLayoutRoutes } from './auth-layout.routing';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginComponent } from '../../pages/login/login.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ECertComponent } from '../../pages/eCert/e-cert.component';
import { PasswordChangeComponent } from '../../pages/password-change/password-change.component';
import { AuthImgComponent } from 'src/assets/svg/auth-img.component';
import { InfoModalComponent } from 'src/app/pages/eCert/info-modal/info-modal.component';
import { AuthTaxImgComponent } from 'src/assets/svg/auth-tax-img.component';
import { AuthTrackImgComponent } from 'src/assets/svg/auth-track-img.component';
import { ManageCustomerImgComponent } from 'src/assets/svg/manage-customer-img.component';
import { RequestImgComponent } from 'src/assets/svg/request-img.component';
import { TrackDocImgComponent } from 'src/assets/svg/track-doc-img.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthLayoutRoutes),
    FormsModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    NgbCarouselModule,
  ],
  declarations: [
    LoginComponent,
    ECertComponent,    
    PasswordChangeComponent,
    AuthImgComponent,
    AuthTaxImgComponent,
    AuthTrackImgComponent,
    ManageCustomerImgComponent,
    RequestImgComponent,
    TrackDocImgComponent,
  ]
})
export class AuthLayoutModule { }
