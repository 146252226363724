export const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;


export enum SystemRole {  
  ADMIN = 'Admin',
  COMPANYADMIN = 'CompanyAdmin',
  ADMINUSER = 'AdminUser',
  CUSTOMER= 'Customer',
  CLIENT= 'Client'
}

export enum DeliveryMethod {
  ECERT= 'E-CERT',
  PAPERCERT= 'PAPER-CERT'
}

export enum CampaignStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  COMPLETED = "Completed"
}

export enum CertificateStatus {
  PENDING = "Pending",  
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  DELETED = "Deleted",
  RESOLVED = "Resolved"
}

export enum MailStatus {
  PREPARED = "Prepared",
  SENT = "Sent",
  RECEIVED = "Received",
  RETURNTOSENDER = "Return To Sender",
  INVALIDADDRESS = "Invalid Address"

}
export enum EmailStatus {
  OUTBOUND = "Outbound",
  SENT = "Sent",
  OPENED = "Opened",
  CLICKED = "Clicked",
  FAILED = "Failed",
  INVALIDEMAIL = "Invalid Email"
}
export enum CertificateType {
  PDF = "PDF",
  FILLIN = "Fill in"
}

export enum EmailErrors {
  INVALIDADDRESS = "Invalid Address",
  INVALIDEMAIL = "Invalid Email",
  FAILED = "Failed",
  RETURNTOSENDER = "Return To Sender"
}

export enum FolderPath {
  TEMPLATE = "./assets/blankforms/",
  DESTINATIONFOLDER = "./assets/files/",
  COMPANYLOGOFOLDER = "./assets/img/companylogo/"
}

export enum EmailSubject {
  FillInSubmit = "SUBMITTED CERTIFICATE",
  UploadPDFSubmit = "SUBMITTED CERTIFICATE",
  CampaignCreated = "NEW CAMPAIGN",
  ApprovedCertificate = "APPROVE CERTIFICATE",
  DeniedCertificated = "DENIED CERTIFICATE",
  UPDATECUSTOMERDETAILS = "UPDATE CUSTOMER DETAILS"
}

export enum EmailBody {
  FillInSubmit = "Form: #form \nDateSubmitted:#dateSubmitted\nReferenceNumber: #referenceNumber\nThank you.",
  UploadPDFSubmit = "Form: #form \nDateSubmitted: #dateSubmitted\nReferenceNumber: #referenceNumber\nThank you.",
  CampaignCreated = "Form: #form "
}

export enum Exposures {
  MISSING = "Missing",
  EXPIRING = "Expiring",
  EXPIRED = "Expired"
}


export enum Validity {
  ExpirationTerm = 3,
  ExpiringTerm = 2
}

export enum SmtpSettings {
  HOST = "in-v3.mailjet.com",
  USERNAME = "5bfa1401b71f3ac514d083414c734c4c",
  PASSWORD = "12bd10267cf97addc5c6ac84ffd1ebdd",
  SENDEREMAIL = "salestax@bertelsmann.com",
  SENDERNAME = "Bertelsmann",
  PORT = 587,
  TESTRECIPIENT = "chelle@cambria.com"
}

export enum EmailType {
  NEWACCESS = "Request for New Access",
  UPLOACERTIFICATE = "Upload Certificate",
  FILLINCERTIFICATE = "Fill In Certificate",
  UPDATECUSTOMERDETAILS = "Update Customer Details - email notification to client",
  CREATECAMPAIGN_ECERT = "Create Campaign - if eCert",
  CREATECAPAIGN_PAPERCERT = "Create Campaign - if Paper Cert",
  DENIEDCERTIFICATED = "Denied Certificate",
  APPROVECERTIFICATE = "Approve Certificate"
}

export enum FieldType {
  HTML = "Html",
  WEB = "Web",
  FIXED = "Fixed",
  OPTIONS = "options"
}

export enum  DesktopPDFCopyPath {
  PATH = "D:/CERTIFICATE/DEV/Arvato_CVT_Local/3-LIVE/Certificates/00-Pending_Priority"
}


export enum Placeholders {
  SALUTATION = "{Salutation}",
  FIRSTNAME = "{FirstName}",
  LASTNAME = "{LastName}",
  TITLE = "{Title}",
  ADDRESS = "{Address}",
  CUSTOMERNAME = "{CustomerName}",
  CUSTOMERNUMBER = "{CustomerNumber}",
  CERTIFICATELIST = "{CertificateList}",
  ACCESSCODE = "{AccessCode}",
  URL = "{URL}",
  REFERENCENUMBER = "{ReferenceNumber}",
  FORMNAME = "{FormName}",
  FORMDATE = "{FormDate}",
}
