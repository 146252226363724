import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { CreateCampaignComponent } from '../../pages/campaign/create-campaign/create-campaign.component';
import { CampaignSearchComponent } from '../../pages/campaign/campaign-search/campaign-search.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserSearchComponent } from '../../pages/user/user-search/user-search.component';
import { UserModalComponent } from '../../pages/user/user-modal/user-modal.component';
import { FormSearchComponent } from '../../pages/form/form-search/form-search.component';
import { CompanyProfileComponent } from '../../pages/company/company-profile/company-profile.component';
import { TemplateEditorComponent } from '../../pages/editor/template-editor/template-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { FormExpiryModalComponent } from '../../pages/form/form-expiry-modal/form-expiry-modal.component';
import { CertificatesComponent } from '../../pages/certificates/certificates/certificates.component';
import { LoadingComponent } from '../../helper/loading';
import { InfoModalComponent } from 'src/app/pages/eCert/info-modal/info-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule
  ],
  declarations: [    
    DashboardComponent,
    CreateCampaignComponent,    
    CampaignSearchComponent,    
    UserSearchComponent,
    UserModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    FormExpiryModalComponent,
    LoadingComponent,
    CertificatesComponent,
    InfoModalComponent,
  ],
  exports: [
    DashboardComponent,
    CreateCampaignComponent,    
    CampaignSearchComponent,
    UserSearchComponent,
    UserModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    FormExpiryModalComponent,
    LoadingComponent,
    CertificatesComponent,
    InfoModalComponent
  ],
  bootstrap: [
    DashboardComponent,
    CreateCampaignComponent,
    CampaignSearchComponent,
    UserSearchComponent,
    UserModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    UserProfileComponent,
    FormExpiryModalComponent,
    CertificatesComponent
  ],
  providers: [
    DecimalPipe],
  schemas: [NO_ERRORS_SCHEMA] 
})

export class AdminLayoutModule { }
