import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIResponse } from '../variables/api-response';
import { CommonService } from './common.service';
import { IUserDataModel } from '../variables/user';
import { IResetPasswordDataModel } from '../variables/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }

  getAllUsers() {
    return this.http.get<APIResponse>(this.baseUrl + "api/user");
  }

  getAllRoles() {
    return this.http.get<APIResponse>(this.baseUrl + "api/role");
  }

  getUserById(userId: string) {
    return this.http.get<APIResponse>(this.baseUrl + "api/user/" + userId);
  }

  updateUser(user: IUserDataModel) {
    if (user.id == null || user.id == "") {
      return this.http.post<any>(this.baseUrl + "api/user", user);
    }
    else {
      return this.http.put<APIResponse>(this.baseUrl + "api/user", user);
    }
  }

  resetUserPasswordById(userId: string, temporaryPassword: string) {
    let body: IResetPasswordDataModel = {
      UserId: userId,
      TemporaryPassword: temporaryPassword,
      IsPasswordReset: true
    };
    
    return this.http.post<any>(this.baseUrl + "api/user/resetpassword", body);
  }

  deleteUserById(userId: string) {
    return this.http.delete<APIResponse>(this.baseUrl + "api/user/" + userId);
  }
}
