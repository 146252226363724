import { Component, OnInit } from '@angular/core';
import { compare, DeliveryMethod, CertificateStatus } from '../../../variables/constant';
import { CustomerFormModel, ReasonDeniedModel } from '../../../variables/form';
import { SortColumn, SortEvent, SortDirection } from '../../../services/sortable.directive';
import { FormService } from '../../../services/form.service';
import { PdfService } from '../../../services/pdf.services';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}


const approvePdf = async (formService: FormService, customerFormId: number, fileName: string): Promise<any> => {

  let response = await formService.approvePdf(customerFormId, fileName)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      alert("Something went wrong. Please take note of the time this happened and contact your administrator. Error:" + error);
      console.log(error);
      location.reload();
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }

}

const denyPdf = async (formService: FormService, customerFormId: number, reasonDenied: string): Promise<any> => {

  let response = await formService.denyPdf(customerFormId, reasonDenied)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }
}


const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {

  let data = await pdfService.getPdf(fileName)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.ResponseException != undefined && error.ResponseException != "") {
        alert(error.ResponseException);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));
    
    return window.URL.createObjectURL(blob);
  }

  return null;
}


const getFormsSubmittedList = async (formService: FormService): Promise<CustomerFormModel []> => {

  let response = await formService.getFormsSubmittedByCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          customerFormId: obj.customerFormId,
          formId: obj.formId,
          customerId: obj.customerId,
          campaignId: obj.campaignId,
          customerName: obj.customerName,
          customerNumber: obj.customerNumber,
          stateAbbreviation: obj.stateAbbreviation,
          formName: obj.formName,
          campaignName: obj.campaignName,
          dateSubmitted: obj.dateSubmitted,
          fileName: obj.fileName,
          certificateStatus: obj.certificateStatus,
          desktopFileName: obj.desktopFileName,
          referenceNumber: obj.referenceNumber,
          expirationDate: obj.expirationDate,
          isExpiringForm: obj.isExpiringForm
        }
      });
    }
    return null;
  }

}

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  eCert: string = DeliveryMethod.ECERT;
  paperCert: string = DeliveryMethod.PAPERCERT;

  searchResultListAll: CustomerFormModel [] = [];
  searchResultList: CustomerFormModel [];
    
  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  //Reason Denied
  customerFormReason: ReasonDeniedModel;
  reasonDenied: string = "";
  
  //search
  searchTerm: string = "";

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  //loading
  approveLoading: boolean = false;

  pdfFile: SafeResourceUrl;

  selectedCertificate: CustomerFormModel;

  public get certificateStatus(): typeof CertificateStatus {
    return CertificateStatus;
  }

  constructor(private formService: FormService,
    private pdfService: PdfService,
    private activeModal: NgbModal,
    public sanitizer: DomSanitizer) {
    this.loadData();
  }

  ngOnInit(): void {
  }


  onSort(event) {
    var column = event.target.getAttribute("sortable");
    this.page = 1; //bring back to page 1
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "dateSubmitted") { //by default, customerName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  loadData() {
    getFormsSubmittedList(this.formService).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;
      if (this.searchTerm != "") {
        this.searchData(this.searchTerm);
      }
    });
  }
  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    }
    else {
      this.searchResultList = this.searchResultListAll.filter(data =>
        (data.customerName == null ? "" : data.customerName).toLowerCase().indexOf(term) > -1
        || (data.customerNumber == null ? "" : data.customerNumber).toLowerCase().indexOf(term) > -1
        || (data.stateAbbreviation == null ? "" : data.stateAbbreviation).toLowerCase().indexOf(term) > -1
        || (data.formName == null ? "" : data.formName).toLowerCase().indexOf(term) > -1        
        || (data.campaignName == null ? "" : data.campaignName).toLowerCase().indexOf(term) > -1        
        //|| (data.dateSubmitted == null ? "" : data.dateSubmitted.toDateString()).toLowerCase().indexOf(term) > -1
        || (data.certificateStatus == null ? "" : data.certificateStatus).toLowerCase().indexOf(term) > -1
        || (data.fileName == null ? "" : data.fileName).toLowerCase().indexOf(term) > -1
        || (data.referenceNumber == null ? "" : data.referenceNumber).toLowerCase().indexOf(term) > -1
      );
      this.page = 1;
    }
    this.collectionSize = this.searchResultList.length;
  }

  getPdf(modal, certificate) {
    //this.activeModal.dismissAll();
    getPdf(this.pdfService, certificate.fileName).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  checkModalBody() {
    //add class modal-open to fix 
    if (document.getElementsByClassName("modal").length !== 0 && document.getElementsByClassName("modal-backdrop").length !== 0) {
      //alert(document.getElementsByClassName("modal-open").length);
      if (document.getElementsByClassName("modal-open").length == 0) {
        document.body.classList.add("modal-open");
      }
    }
  }


  closeModal() {
    this.activeModal.dismissAll();
  }

  approvePdf() {
    this.approveLoading = true;
    approvePdf(this.formService, this.selectedCertificate.customerFormId, this.selectedCertificate.fileName).then(data => {
      if (data != null) {
        this.loadData();
        this.closeModal();
        this.approveLoading = false;
      }
    });
  }

  getReasonDenied(modal) {
    this.reasonDenied = "";
    this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
  }

  denyPdf() {
    denyPdf(this.formService, this.selectedCertificate.customerFormId, this.reasonDenied).then(data => {
      if (data != null) {
        this.loadData();
        this.closeModal();
      }
    });
  }

  resetPage() {
    this.page = 1;
  }
}
