import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemRole } from '../../variables/constant';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router,
    public authService: AuthService) {
    if ((this.authService.userRole$ != SystemRole.ADMIN && this.authService.userRole$ != SystemRole.COMPANYADMIN) || this.authService.currentUserValue == null) {
      this.router.navigate(['/login']);
    }  
  }

  ngOnInit(): void {
  }

}
