import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    baseUrl: string;

    constructor(
        private http: HttpClient,
        public commonService: CommonService
    ) {
        this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
    }

    themeSettings(code: string) {
        return this.http.get<any>(this.baseUrl + "api/company/get-company-detail?companyCode=" + code)
            .pipe(map(response => {
                return response;
            }));
    }

    loadTheme(code: string) {
        this.themeSettings(code).subscribe({
            next: (res) => {
                const result = res['result']['companyColorTheme'] ? JSON.parse(res['result']['companyColorTheme']) : null;
                this.setCSSVariables({
                    "primary-color": result ? result['primaryColor'] : '#005372',
                    "secondary-color": result ? result['secondaryColor'] : '#147498'
                });
                if (res['result']['companyLogoURL']) {
                    this.commonService.setCompanyLogo(res['result']['companyLogoURL'] || "./assets/img/bertelsmann.png");
                } else {
                    this.commonService.setCompanyLogo("./assets/img/bertelsmann.png");
                }
                
                if (res?.result?.isOktaLoginEnabled) {
                    this.commonService.setOktaLink(res?.result['oktaAppEmbedLoginLink']);
                }
            },
            error: () => {
                this.setCSSVariables({
                    "primary-color": "#005372",
                    "secondary-color": "#147498"
                });
                this.commonService.setCompanyLogo("./assets/img/bertelsmann.png");
            }
        })
    }

    private setCSSVariables(theme: any) {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', theme['primary-color']);
        root.style.setProperty('--secondary-color', theme['secondary-color']);
    }
}
