import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';
import { CampaignModel, CampaignParamModel } from '../variables/campaign';
import { StatesService } from './states.service';
import { CustomerService } from './customer.service';
import { StatesDataModel } from '../variables/states';
import { CustomerDataModel } from '../variables/customer';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }

  getCampaignsByCompanyId() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/company/' + this.commonService.currentCompany$);
  }

  getCampaigns(stateList, customerList, deliveryMethodList, exposureList, emailErrorList) {

    let searchFilter: CampaignParamModel = {
      stateList: stateList,
      customerLists: customerList,
      deliveryMethodList: deliveryMethodList,
      exposureList: exposureList,
      emailErrorList: emailErrorList,
      currentCompany: this.commonService.currentCompany$
    };
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/search', searchFilter);
  }

  createCampaign(campaign: CampaignModel) {
    campaign.companyId = this.commonService.currentCompany$;
    console.log(campaign);
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign', campaign);
  }

  getCampaignCustomers(campaignId: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/' + campaignId);
  }

  getViewPDFMail(campaignId: number, customerID: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/viewpdfemail/' + campaignId + "/" + customerID);
  }

  getCampaignCustomerForms(campaignId: number, customerID: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/campaigncustomerforms/' + campaignId + "/" + customerID);
  }  

}

