import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SortColumn, SortDirection } from "src/app/services/sortable.directive";
import { CustomerService } from "src/app/services/customer.service";

export interface ImportCustomersModel {
    csvIndexNumber: number
    status: string
    message: string
    requestedCustomerDetails: RequestedCustomerDetailsModel
    mismatchFields: MismatchFieldModel[]
}

export interface RequestedCustomerDetailsModel {
    payorAccountNumber: string
    customerName: string
    email: string
    shippingAccountNumber: string
    state: string
    created: string
}

export interface MismatchFieldModel {
    fieldName: string
    oldValue: string
    newValue: string
}

@Component({
    selector: 'app-import-status-model',
    templateUrl: './import-status-model.component.html',
    styleUrls: ['./import-status-model.component.scss']
})
export class ImportStatusModelComponent implements OnInit {

    @Input() customersData: ImportCustomersModel[] = [];

    collectionSizeCustomer: number = 0;
    pageCustomer: number = 1;
    pageSizeCustomer: number = 10;
    selectedCustomer: ImportCustomersModel;
    isAddNewCustomer: boolean = false;

    //sorting
    currentColumn: SortColumn = "";
    currentSortBy: SortDirection = "desc";
    stateSettings = {};
    companyCode = localStorage.getItem('company_code');

    constructor(
        public activeModal: NgbActiveModal,
        private customerService: CustomerService
    ) { }

    ngOnInit(): void {
        this.collectionSizeCustomer = this.customersData.length;
    }

    overWriteCustomer(data: ImportCustomersModel, i: number) {
        this.customerService.overwriteShippingAccount(data.requestedCustomerDetails, this.companyCode).subscribe((res) => {
            if (res) {
                this.customersData[i]['status'] = 'success';
                this.customersData[i]['message'] = null;
            }
        }, (error) => {
            if (error instanceof Object) {
                if (error["ResponseExceptionTitle"] != "") {
                    alert(error["ResponseExceptionTitle"]);
                }
            }
            console.log(error);
        })
    }

    resetPage() {
        this.pageCustomer = 1;
    }

    closeModal() {
        this.activeModal.close();
    }

}