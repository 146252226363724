import { Component, OnInit } from '@angular/core';
import { CustomerDataModel, CustomerContactDataModel } from '../../../variables/customer';
import { CustomerService } from '../../../services/customer.service';
import { SortColumn, SortDirection, SortEvent } from '../../../services/sortable.directive';
import { compare } from '../../../variables/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerModalComponent } from '../customer-modal/customer-modal.component';



function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

const getCustomerContact = async (customerService: CustomerService, customerId: number): Promise<CustomerContactDataModel[]> => {

  let response = await customerService.getCustomerContactsByCustomerId(customerId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        id: obj.id,
        customerId: obj.customerId,
        customerNumber: obj.customerNumber,
        emailAddress: obj.emailAddress,
        firstName: obj.firstName,
        lastName: obj.lastName,
        phoneNumber: obj.phoneNumber,
        position: obj.position,
        accessCode: obj.accessCode,
        expirationDate: obj.expirationDate,
        campaignExpiration: obj.campaignExpiration,
        hasCampaign: obj.hasCampaign
      }
    });
  }
}

const getCustomerList = async (customerService: CustomerService): Promise<CustomerDataModel[]> => {

  let response = await customerService.getCustomerByCompanyId()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          customerId: obj.customerId,
          customerName: obj.customerName,
          customerName2: obj.customerName2,
          customerNumber: obj.customerNumber,
          address1: obj.address1,
          city: obj.city,
          state: obj.state,
          postalCode: obj.postalCode,
          emailAddress: obj.emailAddress,
          contactsCount: obj.contactsCount
        }
      });
    }
    return null;
  }

}

const dateNow = new Date();


@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
  searchResultListAll: CustomerDataModel[] = [];
  searchResultList: CustomerDataModel[];

  contactListAll: CustomerContactDataModel[] = [];
  contactList: CustomerContactDataModel[] = [];

  selectedCustomer: CustomerDataModel;

  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  collectionSizeContact: number = 0;
  pageContact: number = 1;
  pageSizeContact: number = 10;

  //search
  searchTerm: string = "";

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";


  constructor(private customerService: CustomerService,
    private activeModal: NgbModal) {
    this.loadData();
  }

  ngOnInit(): void {
  }

  loadData() {    
    getCustomerList(this.customerService).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;      
      this.collectionSize = data.length;

      if (this.searchTerm != "") {
        this.searchData(this.searchTerm);
      }
    });
  }
  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "customerName") { //by default, customerName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  onSortContact(event) {
    this.pageContact = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.contactList = sortData(this.contactList, column, this.currentSortBy);
  }


  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    }
    else {
      this.searchResultList = this.searchResultListAll.filter(data =>
        (data.customerNumber == null ? "" : data.customerNumber).toLowerCase().indexOf(term) > -1
        || (data.customerName == null ? "" : data.customerName).toLowerCase().indexOf(term) > -1
        || (data.customerName2 == null ? "" : data.customerName2).toLowerCase().indexOf(term) > -1
        || (data.address1 == null ? "" : data.address1).toLowerCase().indexOf(term) > -1
        || (data.city == null ? "" : data.city).toLowerCase().indexOf(term) > -1
        || (data.state == null ? "" : data.state).toLowerCase().indexOf(term) > -1
        || (data.postalCode == null ? "" : data.postalCode).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.searchResultList.length;
    this.page = 1;
  }
  toggleCustomer(customer) {
    if (this.selectedCustomer == null) {
      this.selectedCustomer = customer;
      getCustomerContact(this.customerService, customer.customerId).then(data => {
        this.contactListAll = data;
        this.contactList = data;
        this.collectionSizeContact = data.length
      });
    }
    else {
      this.closeContact();
    }
  }

  closeContact() {
    this.selectedCustomer = null;
  }

  editCustomer(customer) {
    const modalRef = this.activeModal.open(CustomerModalComponent, { centered: true, size: 'lg', scrollable: true });    
    modalRef.componentInstance.customer = customer;
    modalRef.result.then(
      (data: any) => {
        this.loadData();
      },
      (reason: any) => { }
    );
  }

  resetPage() {
    this.page = 1;
    this.pageContact = 1;
  }
}
