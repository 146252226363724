import { Routes } from '@angular/router';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { CreateCampaignComponent } from '../../pages/campaign/create-campaign/create-campaign.component';
import { CertificatesComponent } from '../../pages/certificates/certificates/certificates.component';
import { CampaignSearchComponent } from '../../pages/campaign/campaign-search/campaign-search.component';
import { CompanyProfileComponent } from '../../pages/company/company-profile/company-profile.component';
import { CustomerSearchComponent } from '../../pages/customer/customer-search/customer-search.component';
import { FormSearchComponent } from '../../pages/form/form-search/form-search.component';
import { UserSearchComponent } from '../../pages/user/user-search/user-search.component';
import { TemplateEditorComponent } from '../../pages/editor/template-editor/template-editor.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { UpdatePasswordComponent } from '../../pages/update-password/update-password.component';
import { CustomerManagementListComponent } from 'src/app/pages/customer-management/customer-management-list/customer-management-list.component';




export const AdminLayoutRoutes: Routes = [        
  { path: '', component: DashboardComponent },
  { path: 'campaigns', component: CampaignSearchComponent },
  { path: 'campaign', component: CreateCampaignComponent },
  { path: 'certificates', component: CertificatesComponent },
  { path: 'company', component: CompanyProfileComponent },
  { path: 'customer', component: CustomerSearchComponent },
  { path: 'customer-management', component: CustomerManagementListComponent },
  { path: 'form', component: FormSearchComponent },
  { path: 'user', component: UserSearchComponent },
  { path: 'editor', component: TemplateEditorComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'update-password/:userId', component: UpdatePasswordComponent }
];
