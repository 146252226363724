import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SystemRole } from '../../variables/constant';
import { LoadingComponent } from '../../helper/loading';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideInOutMain', [
      state('in', style({
        'margin-left': '0px'        
      })),
      state('out', style({
        'margin-left': '250px'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideInOutIcon', [
      state('in', style({
        left: '0px'
      })),
      state('out', style({
        left: '200px'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class AdminLayoutComponent implements OnInit {
  menuState: string = 'out';
  _component: any;
  sidebarExpanded = true;
  constructor(public commonService: CommonService,
    public authService: AuthService,
    public router: Router,
    private location: Location) {
    this.commonService.setMenuState(this.menuState);    


    if ((this.authService.userRole$ != SystemRole.ADMIN && this.authService.userRole$ != SystemRole.COMPANYADMIN) || this.authService.currentUserValue == null) {
      this.router.navigate(['/login']);
    }  
  }

  ngOnInit() {
    // document.querySelector('body').style.setProperty('--primary-color', colors['primary-color']);
    // document.querySelector('body').style.setProperty('--secondary-color', colors['secondary-color']);
   }

  ngAfterViewInit() {    
  }
 
  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.commonService.setMenuState(this.menuState);
    this.adjustModal();
  }

  onActivate(componentReference) {   
    let _title = this.location.prepareExternalUrl(this.location.path());    
    //this.commonService.setCurrentNav(_title);    
    
    this._component = componentReference;    
    this.adjustModal();
  }

  adjustModal() {
    try {
      this._component.adjustModal();
    }
    catch (ex) {
    }
  }
}
