import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomerLayoutRoutes } from './customer-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { CustomerComponent } from '../../pages/eCert/customer/customer.component';
import { CertificatesComponent } from '../../pages/eCert/certificates/certificates.component';
import { UploadComponent } from '../../pages/eCert/upload/upload.component';
import { FillInComponent } from '../../pages/eCert/fill-in/fill-in.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(CustomerLayoutRoutes),
    FormsModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    SignaturePadModule
  ],
  declarations: [    
    CustomerComponent,
    CertificatesComponent,
    UploadComponent,
    FillInComponent
  ],
  exports: [
    
  ],
  bootstrap: [
    
  ],
  providers: [
    DecimalPipe],
  schemas: [NO_ERRORS_SCHEMA]
})

export class CustomerLayoutModule { }
