import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';
import { CompanyDataModel } from '../variables/common';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }


  getAllCompany() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/company');
  }


  getCurrentCompany() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/company/' + this.commonService.currentCompany$);
  }


  getLogo(fileName: string) {
    return this.http.get(this.baseUrl + 'api/company/logo/' + fileName);
  }

  uploadPdfFile(fileName: string, obj: FormData) {
    return this.http.post(this.baseUrl + 'api/company/upload/' + fileName, obj);
  }

  updateCompany(company: CompanyDataModel) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/company/' + this.commonService.currentCompany$, company);
  }
}

