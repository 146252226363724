import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ReactiveFormsModule, UntypedFormControl, Validators, AbstractControl, Validator, ValidatorFn } from '@angular/forms';
import { NgbTypeahead, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsBasicDataModel, FormCertificateModel, FormFilledInResultDataModel } from '../../../variables/form';
import { FormService } from '../../../services/form.service';
  

  const getFormById = async (formService: FormService, id: number): Promise<FormsBasicDataModel> => {

    let response = await formService.GetFormById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <FormsBasicDataModel>response["result"];
  }

  }

const updateCampaignForm = async (formService: FormService, formId: number, data: FormsBasicDataModel): Promise<FormsBasicDataModel> => {

  let response = await formService.updateCampaignForm(formId, data)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <FormsBasicDataModel>response["result"];

    return result;
  }

}
@Component({
  selector: 'app-form-expiry-modal',
  templateUrl: './form-expiry-modal.component.html',
  styleUrls: ['./form-expiry-modal.component.scss']
})
export class FormExpiryModalComponent implements OnInit {
  formExpiryForm: UntypedFormGroup;
  @Input() form: FormsBasicDataModel; 
  submittedExpireTerm: boolean = false;
  constructor(public activeModal: NgbActiveModal,
    public formBuilder: UntypedFormBuilder,
    private formService: FormService) {

  }

  ngOnInit(): void {
    this.formExpiryForm = new UntypedFormGroup({
      formId: new UntypedFormControl(this.form.formId),
      formState: new UntypedFormControl(this.form.state),
      formName: new UntypedFormControl(this.form.form),
      formType: new UntypedFormControl(this.form.type),
      expiringTerm: new UntypedFormControl(this.form.expiringTerm, [Validators.required]),
      expiredTerm: new UntypedFormControl(this.form.expiredTerm)
    });

    let identityFormId = this.formExpiryForm.controls['formId'].value;
       
  }

  

  get fExpiry() { return this.formExpiryForm.controls; }

  SaveExpiry() {
    this.submittedExpireTerm = true;
    if (!this.formExpiryForm.invalid) {
      updateCampaignForm(this.formService, this.formExpiryForm.controls['formId'].value, this.formExpiryForm.value).then(data => {
        if (data != null) {
          this.activeModal.close('Close click');
        }
      });
    }    
  }



  get f() { return this.formExpiryForm.controls; }
}
