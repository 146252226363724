import { Routes } from '@angular/router';

import { LoginComponent } from '../../pages/login/login.component';
import { ECertComponent } from '../../pages/eCert/e-cert.component';
import { PasswordChangeComponent } from '../../pages/password-change/password-change.component';
import { UpdatePasswordComponent } from '../../pages/update-password/update-password.component';


export const AuthLayoutRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'activate/:token', component: ECertComponent },
  { path: 'eCert', component: ECertComponent },  
  { path: 'changepassword/:userId', component: PasswordChangeComponent },
  { path: 'update-password/:userId', component: UpdatePasswordComponent }
];
