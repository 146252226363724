import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, Validator, ValidatorFn, ValidationErrors } from '@angular/forms';
import { IUserDataModel, IRoleDataModel } from '../../variables/user';
import { NgbTypeahead, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { first, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { CompanyDataModel } from '../../variables/common';
import { SystemRole } from '../../variables/constant';


const updateUser = async (userService: UserService, user: IUserDataModel): Promise<IUserDataModel> => {
  console.log(user);
  let response = await userService.updateUser(user)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}
const getUserById = async (userService: UserService, id: string): Promise<IUserDataModel> => {

  let response = await userService.getUserById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}


const getAllCompany = async (companyService: CompanyService): Promise<CompanyDataModel[]> => {

  let response = await companyService.getAllCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          companyId: obj.companyId,
          companyCode: obj.companyCode,
          companyName: obj.companyName,
          emailAddress: obj.emailAddress
        }
      });
    }
    return null;
  }

}

const getUserRoles = async (userService: UserService): Promise<IRoleDataModel[]> => {

  let response = await userService.getAllRoles()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          id: obj.id,
          name: obj.name
        }
      });
    }
    return null;
  }

}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  NoWhiteSpaceValidator = Validators.pattern(/^\S*$/);

  userProfileForm: UntypedFormGroup;
  // @Input() user: IUserDataModel;
  public roleList: IRoleDataModel[];
  public companyList: CompanyDataModel[];
  model: any;
  defaultRole: IRoleDataModel;
  filterRoleList: IRoleDataModel[];
  submitted: boolean = false;
  public accountID: any = "0"
  currentCompanyId = localStorage.getItem('currentCompany');

  // List of countries with flags and codes
  countries = [
    { code: '+91', name: 'India', flag: './assets/svg/in.svg' },
    { code: '+1', name: 'United States', flag: './assets/svg/us.svg' },
  ];

  selectedCountry: any = this.countries[1];


  public get systemRoles(): typeof SystemRole {
    return SystemRole;
  }

  constructor(private router: Router, route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private companyService: CompanyService) {

  }


  ngOnInit(): void {
    this.accountID = this.authService.getId()
    this.userProfileForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      userName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      middleName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl('', [
        Validators.required,
        this.NoWhiteSpaceValidator,
        this.onlyNumbersValidator,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]),
      isActive: new UntypedFormControl(''),
      role: new UntypedFormControl('', [Validators.required]),
      roleId: new UntypedFormControl(''),
      temporaryPassword: new UntypedFormControl(''),
      isPasswordReset: new UntypedFormControl(''),
      tmpRole: new UntypedFormControl(''),
      companyId: new UntypedFormControl('')
    });


    getUserRoles(this.userService).then(data => {
      let roleList = data.filter(x => x.name != SystemRole.CUSTOMER);
      // if (this.authService.userRole$ != undefined) {
      //   this.defaultRole = roleList.filter(x => x.name == this.authService.userRole$)[0];
      //   alert(roleList.filter(x => x.name == this.authService.userRole$)[0])
      //   if (this.defaultRole != null)
      //     this.f.tmpRole.setValue(this.defaultRole);
      // }

      this.roleList = roleList.map((el) => {
        return {
          id: el.id,
          name: el.name,
          label: el.name == 'Admin' ? 'Company User' : el.name,
          roleId: el?.roleId
        }
      });

      setTimeout(() => {
        this.userProfileForm.patchValue({
          role: this.authService.userRole$ ? this.authService.userRole$ : this.roleList[0]['name'],
        });
      })
    });

    this.userProfileForm.controls['role'].disable();
    this.userProfileForm.controls['companyId'].disable();

    getAllCompany(this.companyService).then(data => {
      this.companyList = data;
    });

    let identityUserId = this.accountID;

    if (identityUserId != "") {
      getUserById(this.userService, identityUserId).then(data => {
        if (data.roleId != null && this.roleList != null) {
          this.defaultRole = this.roleList.filter(x => x.id == data.roleId)[0];
          data.roleId = this.defaultRole.name;
        }
        const selectedCountry = this.splitPhoneNumber(data.phoneNumber).countryCode;
        this.selectedCountry = this.countries.find((el) => el.code == selectedCountry);

        this.userProfileForm.patchValue({
          id: data.id,
          userName: data.userName,
          email: data.email,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          phoneNumber: this.splitPhoneNumber(data.phoneNumber).localNumber,
          isActive: data.isActive,
          role: data.role,
          temporaryPassword: data.temporaryPassword,
          isPasswordReset: data.isPasswordReset,
          roleId: data.roleId,
          companyId: data.companyId
        });
      });
    }
  }

  get f() { return this.userProfileForm.controls; }

  onGenerateTemporaryPassword() {
    this.f.temporaryPassword.setValue(Math.random().toString(36).slice(-8));
    return false;
  }

  onSubmit() {
    const formData = this.userProfileForm.getRawValue();

    let payload = {
      id: formData?.id,
      userName: formData?.userName,
      email: formData?.email,
      firstName: formData?.firstName,
      middleName: formData?.middleName,
      lastName: formData?.lastName,
      phoneNumber: this.selectedCountry['code'] + formData?.phoneNumber,
      isActive: formData?.isActive,
      role: formData?.role,
      roleId: formData?.roleId,
      temporaryPassword: formData?.temporaryPassword,
      isPasswordReset: formData?.isPasswordReset,
      tmpRole: formData?.tmpRole,
      companyId: formData?.companyId
    }

    this.submitted = true;
    if (this.f.role.value == SystemRole.CLIENT && this.f.companyId.value == null) {
      return false;
    }
    if (this.f.companyId.value != null) {
      this.f.companyId.setValue(parseInt(this.f.companyId.value));
    }
    if (!this.userProfileForm.invalid) {
      updateUser(this.userService, payload).then(data => {
        if (data != null) {
          this.userProfileForm.controls.phoneNumber.setValue(this.splitPhoneNumber(this.userProfileForm.controls.phoneNumber.value).localNumber);
          // alert("Profile Updated Successfully")
        }
      });
    }
  }


  profileredirect() {
    if (this.authService.userRole$ == SystemRole.CLIENT) {
      this.router.navigate(['/client/update-password/' + this.f.id.value])
    } else {
      this.router.navigate(['/admin/update-password/' + this.f.id.value])
    }
  }

  // Handle country selection
  selectCountry(country: any) {
    this.selectedCountry = country;
  }

  splitPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      let countryCode = "";
      let localNumber = "";

      // Check for +1 (USA) or +91 (India) as the country code
      if (phoneNumber.startsWith("+1")) {
        countryCode = "+1";
        localNumber = phoneNumber.substring(2); // Remove the country code part
      } else if (phoneNumber.startsWith("+91")) {
        countryCode = "+91";
        localNumber = phoneNumber.substring(3); // Remove the country code part
      } else {
        countryCode = this.countries[0]['code'];
        localNumber = phoneNumber; // Remove the country code part
      }
      return { countryCode: countryCode || this.countries[0]['code'], localNumber };
    } else {
      this.selectedCountry = this.countries[1];
      return { countryCode: this.selectedCountry.code || this.countries[0]['code'], localNumber: null };
    }
  }

  // Custom validator to check if phone number contains only numeric characters
  onlyNumbersValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!/^[0-9]*$/.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  role_change(role) {
    if (role != SystemRole.CLIENT) {
      this.f.companyId.setValue(null);
    }
  }

  formatter = (x: { name: string }) => x.name;
}

function roleValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (Object.keys(control.value).length > 0) {
    if (Object.keys(control.value)[0] == "roleId" && (control.value["roleId"] == null)) {
      return { "roleInvalid": true };
    } else if (control.value == "") {
      return { "roleInvalid": true };
    }
  }

  return null;
}


