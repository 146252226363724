import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SystemRole } from '../../variables/constant';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  module: string = SystemRole.ADMIN;
  public isCollapsed = true;
  public companylogo = "./assets/img/brand/companylogo.png";

  public get systemRole(): typeof SystemRole {
    return SystemRole;
  }

  constructor(private router: Router) {    
    switch (router.url.toLowerCase()) {
      case "/ecert":
        this.module = SystemRole.CUSTOMER;
        break;
      case "/login":
        this.module = SystemRole.CLIENT;
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    // body.classList.add("bg-default");
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });

  }
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
  }
}
