
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';


import { AppRoutingModule } from './app.routing.module';
import { ComponentsModule } from './components/components.module';
import { ErrorInterceptor } from './helper/error.interceptor';
import { JwtInterceptor } from './helper/jwt.interceptor';
// import { SignaturePadModule } from '@ng-plus/signature-pad';
import { CustomerLayoutComponent } from './layouts/customer-layout/customer-layout.component';
import { ClientLayoutComponent } from './layouts/client-layout/client-layout.component';
import { CustomerSearchComponent } from './pages/customer/customer-search/customer-search.component';
import { CustomerModalComponent } from './pages/customer/customer-modal/customer-modal.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ThemeService } from './common/theme.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomerManagementListComponent } from './pages/customer-management/customer-management-list/customer-management-list.component';
import { CustomerManagementAddEditComponent } from './pages/customer-management/customer-management-add-edit/customer-management-add-edit.component';
import { ImportStatusModelComponent } from './pages/customer-management/import-status-model/import-status-model.component';
import { CustomerSubmittedCertificatesModelComponent } from './pages/customer-management/customer-submitted-certificates-model/customer-submitted-certificates-model.component';
import { DatePipe } from '@angular/common';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgMultiSelectDropDownModule,
    ToastrModule.forRoot(),
    NgbTooltipModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    CustomerLayoutComponent,
    ClientLayoutComponent,
    CustomerSearchComponent,
    CustomerManagementListComponent,
    CustomerManagementAddEditComponent,
    CustomerModalComponent,
    UserProfileComponent,
    UpdatePasswordComponent,
    ImportStatusModelComponent,
    CustomerSubmittedCertificatesModelComponent
  ],
  exports: [
    CustomerSearchComponent,
    CustomerManagementListComponent,
    CustomerModalComponent,
    CustomerManagementAddEditComponent,
    ImportStatusModelComponent,
    CustomerSubmittedCertificatesModelComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ThemeService,
    DatePipe
  ]
})
export class AppModule { }
