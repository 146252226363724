import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { waitForAsync } from '@angular/core/testing';
import { EditorService } from '../../../services/editor.service';
import { IEmailTemplateModel } from '../../../variables/editor';
import { SortEvent, SortColumn, SortDirection } from '../../../services/sortable.directive';
import { compare, Placeholders } from '../../../variables/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';


const getEmailTemplateList = async (editorService: EditorService): Promise<IEmailTemplateModel[]> => {

  let response = await editorService.getEmailTemplates()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          emailTemplateId: obj.emailTemplateId,
          emailType: obj.emailType,
          description: obj.description,
          htmlContent: obj.htmlContent,
          content: obj.content,
        }
      });
    }
    return null;
  }
}

const updateTemplateContent = async (editorService: EditorService, editor: IEmailTemplateModel): Promise<any> => {
  let response = await editorService.updateTemplate(editor)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }
}

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})


export class TemplateEditorComponent implements OnInit {
  searchResultListAll: IEmailTemplateModel[] = [];
  searchResultList: IEmailTemplateModel[] = [];

  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  //search
  searchTerm: string = "";
  searchActive: string = "";

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  // Editor
  editorHtmlContent: string = "";
  selectedEmailTemplate: IEmailTemplateModel;

  // Placeholder
  placeholderList: any[] = [];
  //selectedPlaceholder: string = this.placeholderList[0];

  public get placeholders(): typeof Placeholders {
    return Placeholders;
  }

  constructor(private editorService: EditorService,
    private activeModal: NgbModal) {
  }

  ngOnInit(): void {
    this.loadData();

    Object.entries(this.placeholders).map(([key, value]) => {
      this.placeholderList.push({ value: value, key: key });
    });
  }


  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "emailType") { //by default, emailType is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  loadData() {
    getEmailTemplateList(this.editorService).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;
    });

    
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter email template content here...',
    translate: 'no',
    //defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ///Modals
  editTemplateModal(modalEditor, data) {
    this.editorHtmlContent = data.htmlContent == null ? "" : data.htmlContent;
    this.selectedEmailTemplate = data;
    this.activeModal.open(modalEditor, { centered: true, size: 'lg', scrollable: true });
      
  }

  updateEmailTemplate(selectedEmailTemplate) {
    selectedEmailTemplate.htmlContent = this.editorHtmlContent;
    updateTemplateContent(this.editorService, selectedEmailTemplate).then(data => {
      if (data != null) {
        this.loadData();
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  insertPlaceholder(selectedPlaceholder) {
    if (selectedPlaceholder != "" && selectedPlaceholder != null) {
      this.editorHtmlContent += selectedPlaceholder;
    }
  }
}

