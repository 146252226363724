
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './helper/auth.guard';
import { CustomerLayoutComponent } from './layouts/customer-layout/customer-layout.component';
import { ClientLayoutComponent } from './layouts/client-layout/client-layout.component';
import { ECertComponent } from './pages/eCert/e-cert.component';
import { CustomerGuard } from './helper/customer.guard';
import { ClientGuard } from './helper/client.guard';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { CustomerLayoutModule } from './layouts/customer-layout/customer-layout.module';
import { ClientLayoutModule } from './layouts/client-layout/client-layout.module';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  {
    path: '',
    redirectTo: "/eCert",
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => AdminLayoutModule
      }
    ],
    canActivate: [AuthGuard]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => AuthLayoutModule
      }
    ]
  }, {
    path: '',
    component: CustomerLayoutComponent,
    children: [
      {
        path: 'eCert',
        loadChildren: () => CustomerLayoutModule
      }
    ],
    canActivate: [CustomerGuard]
  }, {
    path: 'client',
    component: ClientLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => ClientLayoutModule
      }
    ],
    canActivate: [ClientGuard]
  },
  {
    path: 'eCert/activate/:token',
    component: ECertComponent
  }, {
    path: '**',
    redirectTo: 'eCert'
  },
  {
    path: 'eCert',
    component: ECertComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
