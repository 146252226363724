import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { CertificateModel, UploadModel, PdfPreviewModel } from '../variables/customer';


@Injectable({
  providedIn: 'root'
})
export class PdfService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }
  prefillPdf(certificate: CertificateModel) {
    //return this.http.post(this.baseUrl + 'api/pdf', certificate, { responseType: 'arraybuffer' });
    return this.http.post(this.baseUrl + 'api/pdf', certificate);    
  }

  prefillPdfTemp(certificate: CertificateModel) {
    //return this.http.post(this.baseUrl + 'api/pdf', certificate, { responseType: 'arraybuffer' });
    return this.http.post(this.baseUrl + 'api/pdf/temp', certificate);
  }

  getHtml(formName: string, stateName: string, isEsign?: boolean) {
    if (isEsign) {
      return this.http.get(this.baseUrl + 'api/pdf/html/' + formName + "/" + stateName + "/true");
    }
    else {
      return this.http.get(this.baseUrl + 'api/pdf/html/' + formName + "/" + stateName);
    }
  }

  afterUploadPdf(obj: UploadModel) {
    return this.http.post(this.baseUrl + 'api/pdf/upload', obj);   

  }

  uploadPdfFile(fileName: string, obj: FormData) {    
    return this.http.post(this.baseUrl + 'api/pdf/upload/' + fileName, obj);

  }

  getPdf(fileName: string) {    
    return this.http.get(this.baseUrl + 'api/pdf/' + fileName);
  }

  getHTMLRules(formName: string, stateName: string) {
    return this.http.get(this.baseUrl + 'api/pdf/htmlrules/' + formName + "/" + stateName);    
  }

  getPdfPreview(formName: string, customerId: number) {
    let obj: PdfPreviewModel = {
      formName: formName,
      customerId: customerId
    }
    return this.http.post(this.baseUrl + 'api/pdf/pdfpreview', obj);
  }
}

