import { Component } from "@angular/core";

@Component({
  selector: "auth-img-svg",
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 500 500"
      fill="none"
    >
      <path
        d="M452.368 211.51L414.948 193.65C413.707 193.055 412.753 191.993 412.295 190.695C411.838 189.397 411.914 187.971 412.508 186.73L437.228 134.94C437.824 133.701 438.888 132.749 440.185 132.293C441.483 131.838 442.908 131.915 444.148 132.51L467.878 143.83L477.208 171.51L459.298 209.09C459.002 209.704 458.588 210.253 458.079 210.707C457.571 211.161 456.978 211.51 456.334 211.735C455.691 211.96 455.009 212.056 454.329 212.017C453.648 211.978 452.982 211.806 452.368 211.51Z"
        fill="#EBEBEB"
      />
      <path
        d="M467.878 143.84L460.878 158.42C460.605 158.992 460.447 159.612 460.414 160.244C460.381 160.877 460.473 161.51 460.684 162.108C460.896 162.705 461.224 163.254 461.648 163.725C462.072 164.196 462.585 164.578 463.158 164.85L477.158 171.55L467.878 143.84Z"
        fill="#F5F5F5"
      />
      <path
        d="M460.498 159.79C459.981 160.898 459.858 162.149 460.149 163.336C460.44 164.524 461.128 165.576 462.098 166.32C462.228 166.44 462.368 166.54 462.508 166.65C464.188 167.85 475.208 175.74 475.338 175.46L477.208 171.51L463.208 164.82C463.208 164.82 459.748 163.07 460.498 159.79Z"
        fill="#E0E0E0"
      />
      <path
        d="M458.067 156.329C457.977 156.509 454.067 154.819 449.247 152.529C444.427 150.239 440.667 148.249 440.757 148.059C440.847 147.869 444.757 149.569 449.577 151.859C454.397 154.149 458.157 156.139 458.067 156.329Z"
        fill="#FAFAFA"
      />
      <path
        d="M465.298 169.91C465.208 170.09 458.758 167.2 450.898 163.44C443.038 159.68 436.718 156.44 436.808 156.3C436.898 156.16 443.348 159.01 451.218 162.77C459.088 166.53 465.398 169.72 465.298 169.91Z"
        fill="#FAFAFA"
      />
      <path
        d="M464.587 178.01C464.507 178.2 457.477 175.01 448.897 170.94C440.317 166.87 433.447 163.37 433.537 163.19C433.627 163.01 440.647 166.19 449.227 170.26C457.807 174.33 464.677 177.83 464.587 178.01Z"
        fill="#FAFAFA"
      />
      <path
        d="M461.497 184.51C461.407 184.69 454.377 181.51 445.807 177.43C437.237 173.35 430.347 169.87 430.437 169.68C430.527 169.49 437.547 172.68 446.127 176.75C454.707 180.82 461.577 184.32 461.497 184.51Z"
        fill="#FAFAFA"
      />
      <path
        d="M458.398 191C458.308 191.19 451.278 188 442.708 183.92C434.138 179.84 427.298 176.36 427.298 176.17C427.298 175.98 434.408 179.17 442.988 183.25C451.568 187.33 458.478 190.81 458.398 191Z"
        fill="#FAFAFA"
      />
      <path
        d="M455.297 197.51C455.207 197.7 448.177 194.51 439.607 190.44C431.037 186.37 424.147 182.87 424.237 182.69C424.327 182.51 431.347 185.69 439.927 189.76C448.507 193.83 455.387 197.3 455.297 197.51Z"
        fill="#FAFAFA"
      />
      <path
        d="M66.4297 360.63L51.9297 373.77H60.6897V392.37H66.0997H66.7597H72.1797V373.77H80.9397L66.4297 360.63Z"
        fill="#EBEBEB"
      />
      <path
        d="M42.6929 170L31 222.268L96.3741 236.892L108.067 184.624L42.6929 170Z"
        fill="#EBEBEB"
      />
      <path
        d="M44.2097 173.801L33.9883 219.491L94.7758 233.09L104.997 187.399L44.2097 173.801Z"
        fill="#F5F5F5"
      />
      <path
        d="M33.9873 219.485L61.5673 195.835L70.7473 216.535L81.7873 209.035L94.7773 233.075L33.9873 219.485Z"
        fill="#E0E0E0"
      />
      <path
        d="M81.0871 196.545C80.8757 197.493 80.3879 198.357 79.6854 199.028C78.9829 199.698 78.0973 200.146 77.1406 200.313C76.1838 200.48 75.1989 200.36 74.3105 199.968C73.4221 199.575 72.67 198.928 72.1495 198.108C71.6289 197.288 71.3633 196.332 71.3861 195.361C71.409 194.39 71.7193 193.448 72.2778 192.653C72.8364 191.858 73.618 191.247 74.5239 190.897C75.4298 190.547 76.4193 190.473 77.3671 190.685C78.6372 190.969 79.7425 191.746 80.44 192.845C81.1376 193.944 81.3703 195.275 81.0871 196.545Z"
        fill="#E0E0E0"
      />
      <path
        d="M403.939 102.189H131.319V342.269H403.939V102.189Z"
        fill="#F5F5F5"
      />
      <path
        d="M403.939 342.27C403.939 340.42 403.849 246.67 403.719 102.19L403.939 102.41H131.349L131.609 102.15C131.609 189.41 131.609 271.58 131.609 342.23L131.379 342.01L403.999 342.23L131.379 342.46H131.149V342.23C131.149 271.58 131.149 189.41 131.149 102.15V101.89H131.439H404.029H404.249V102.11C403.999 246.67 403.939 340.42 403.939 342.27Z"
        fill="#263238"
      />
      <path
        d="M174.86 193.21H159.4C159.146 193.211 158.894 193.162 158.658 193.066C158.423 192.969 158.209 192.827 158.029 192.648C157.848 192.469 157.705 192.255 157.608 192.021C157.51 191.786 157.46 191.534 157.46 191.28V169.87C157.46 169.355 157.664 168.862 158.028 168.498C158.392 168.134 158.885 167.93 159.4 167.93H169.2L176.8 175.76V191.28C176.8 191.534 176.75 191.786 176.652 192.021C176.554 192.255 176.411 192.469 176.231 192.648C176.051 192.827 175.837 192.969 175.602 193.066C175.366 193.162 175.114 193.211 174.86 193.21Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M169.199 167.93V173.93C169.202 174.406 169.392 174.863 169.729 175.2C170.066 175.537 170.523 175.727 170.999 175.73H176.799L169.199 167.93Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <g opacity="0.2">
        <path
          d="M169.279 174.489C169.283 174.945 169.443 175.387 169.732 175.739C170.022 176.092 170.423 176.336 170.869 176.429H171.059C171.819 176.569 176.799 177.429 176.799 177.339V175.719H170.999C170.999 175.719 169.559 175.709 169.279 174.489Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M169.199 167.93V173.93C169.202 174.406 169.392 174.863 169.729 175.2C170.066 175.537 170.523 175.727 170.999 175.73H176.799L169.199 167.93Z"
          fill="white"
        />
      </g>
      <path
        d="M167.91 173.71C166.722 173.851 165.525 173.898 164.33 173.85C163.135 173.898 161.938 173.851 160.75 173.71C161.938 173.574 163.135 173.528 164.33 173.57C165.525 173.528 166.722 173.574 167.91 173.71Z"
        fill="#FAFAFA"
      />
      <path
        d="M172.53 177.119C172.53 177.199 169.89 177.259 166.64 177.259C163.39 177.259 160.75 177.199 160.75 177.119C160.75 177.039 163.39 176.979 166.64 176.979C169.89 176.979 172.53 176.999 172.53 177.119Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 179.999C173.59 180.079 170.72 180.139 167.17 180.139C163.62 180.139 160.75 180.079 160.75 179.999C160.75 179.919 163.63 179.859 167.17 179.859C170.71 179.859 173.59 179.889 173.59 179.999Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 182.65C173.59 182.73 170.72 182.79 167.17 182.79C163.62 182.79 160.75 182.73 160.75 182.65C160.75 182.57 163.63 182.51 167.17 182.51C170.71 182.51 173.59 182.57 173.59 182.65Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 185.329C173.59 185.409 170.72 185.469 167.17 185.469C163.62 185.469 160.75 185.409 160.75 185.329C160.75 185.249 163.63 185.189 167.17 185.189C170.71 185.189 173.59 185.259 173.59 185.329Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 187.999C173.59 188.069 170.72 188.139 167.17 188.139C163.62 188.139 160.75 188.069 160.75 187.999C160.75 187.929 163.63 187.859 167.17 187.859C170.71 187.859 173.59 187.939 173.59 187.999Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.939 170.62C190.244 170.608 190.547 170.684 190.809 170.84L190.539 171.55C190.381 171.448 190.198 171.392 190.009 171.39C189.619 171.39 189.409 171.61 189.409 172.04V172.29H190.529V173.05H189.439V176.33H188.489V173.05H187.819V172.29H188.489V172C188.478 171.81 188.508 171.619 188.578 171.442C188.648 171.265 188.756 171.105 188.894 170.973C189.032 170.842 189.197 170.742 189.378 170.681C189.558 170.62 189.75 170.599 189.939 170.62ZM191.649 171.01C191.651 170.932 191.667 170.855 191.699 170.784C191.731 170.713 191.776 170.649 191.833 170.595C191.89 170.542 191.957 170.501 192.03 170.474C192.104 170.448 192.182 170.436 192.259 170.44C192.336 170.434 192.413 170.445 192.485 170.47C192.558 170.494 192.624 170.534 192.681 170.585C192.738 170.636 192.784 170.699 192.817 170.768C192.849 170.838 192.867 170.913 192.869 170.99C192.869 171.152 192.805 171.307 192.691 171.421C192.576 171.536 192.421 171.6 192.259 171.6C192.098 171.6 191.942 171.536 191.828 171.421C191.714 171.307 191.649 171.152 191.649 170.99V171.01ZM191.779 172.26H192.779V176.33H191.779V172.26Z"
        fill="#263238"
      />
      <path d="M194 170.67H195V176.33H194V170.67Z" fill="#263238" />
      <path
        d="M199.999 174.6H196.809C196.867 174.9 197.034 175.168 197.278 175.352C197.522 175.537 197.825 175.625 198.129 175.6C198.334 175.607 198.538 175.57 198.728 175.493C198.917 175.415 199.088 175.298 199.229 175.15L199.739 175.74C199.535 175.968 199.281 176.146 198.996 176.261C198.712 176.375 198.405 176.423 198.099 176.4C197.811 176.425 197.521 176.389 197.247 176.295C196.973 176.201 196.722 176.051 196.51 175.854C196.298 175.657 196.13 175.418 196.016 175.152C195.902 174.886 195.845 174.599 195.849 174.31C195.838 174.031 195.885 173.753 195.987 173.493C196.089 173.234 196.245 172.998 196.444 172.802C196.642 172.606 196.88 172.454 197.141 172.356C197.402 172.257 197.681 172.214 197.959 172.23C198.237 172.221 198.512 172.27 198.77 172.374C199.027 172.477 199.26 172.633 199.454 172.832C199.647 173.03 199.798 173.267 199.895 173.526C199.992 173.786 200.035 174.063 200.019 174.34C199.999 174.39 199.999 174.51 199.999 174.6ZM196.799 173.97H199.109C199.065 173.695 198.924 173.446 198.713 173.266C198.501 173.085 198.232 172.986 197.954 172.986C197.676 172.986 197.408 173.085 197.196 173.266C196.984 173.446 196.844 173.695 196.799 173.97Z"
        fill="#263238"
      />
      <path
        d="M204.999 174V176.33H203.999V174.12C203.999 173.39 203.639 173.04 202.999 173.04C202.839 173.03 202.678 173.055 202.529 173.114C202.38 173.174 202.246 173.266 202.136 173.384C202.027 173.501 201.946 173.642 201.898 173.796C201.85 173.949 201.837 174.111 201.859 174.27V176.33H200.859V172.26H201.769V172.78C201.946 172.587 202.163 172.436 202.405 172.337C202.647 172.239 202.908 172.195 203.169 172.21C203.415 172.17 203.667 172.188 203.905 172.264C204.142 172.339 204.358 172.469 204.537 172.643C204.715 172.817 204.849 173.031 204.93 173.266C205.01 173.502 205.034 173.753 204.999 174Z"
        fill="#263238"
      />
      <path
        d="M209.59 173.94V176.33H208.69V175.83C208.542 176.023 208.346 176.176 208.122 176.272C207.898 176.369 207.652 176.406 207.41 176.38C206.47 176.38 205.89 175.87 205.89 175.16C205.89 174.45 206.34 173.949 207.58 173.949H208.58V173.889C208.58 173.329 208.25 172.999 207.58 172.999C207.132 172.994 206.695 173.138 206.34 173.41L205.97 172.71C206.476 172.365 207.078 172.19 207.69 172.21C208.91 172.21 209.59 172.77 209.59 173.94ZM208.59 175.059V174.58H207.59C206.94 174.58 206.77 174.83 206.77 175.13C206.77 175.43 207.06 175.69 207.54 175.69C207.766 175.725 207.998 175.682 208.196 175.568C208.395 175.453 208.548 175.274 208.63 175.059H208.59Z"
        fill="#263238"
      />
      <path
        d="M217.62 174V176.33H216.62V174.12C216.62 173.39 216.29 173.04 215.71 173.04C215.13 173.04 214.64 173.45 214.64 174.26V176.33H213.69V174.12C213.69 173.39 213.36 173.04 212.78 173.04C212.2 173.04 211.71 173.45 211.71 174.26V176.33H210.76V172.26H211.67V172.78C211.835 172.591 212.04 172.442 212.27 172.344C212.5 172.245 212.75 172.199 213 172.21C213.269 172.191 213.539 172.245 213.781 172.364C214.024 172.483 214.23 172.665 214.38 172.89C214.564 172.668 214.797 172.492 215.061 172.374C215.324 172.256 215.611 172.2 215.9 172.21C216.138 172.185 216.379 172.214 216.604 172.296C216.829 172.378 217.033 172.511 217.199 172.683C217.365 172.856 217.489 173.065 217.562 173.293C217.635 173.521 217.655 173.763 217.62 174Z"
        fill="#263238"
      />
      <path
        d="M222.7 174.6H219.51C219.568 174.9 219.735 175.168 219.978 175.352C220.222 175.537 220.525 175.625 220.83 175.6C221.034 175.607 221.238 175.57 221.428 175.493C221.617 175.415 221.788 175.298 221.93 175.15L222.45 175.74C222.139 176.044 221.749 176.254 221.324 176.346C220.898 176.437 220.456 176.406 220.048 176.256C219.64 176.107 219.282 175.844 219.017 175.5C218.752 175.155 218.59 174.743 218.55 174.31C218.538 174.031 218.585 173.753 218.687 173.493C218.79 173.234 218.945 172.998 219.144 172.802C219.343 172.606 219.58 172.454 219.841 172.356C220.102 172.257 220.381 172.214 220.66 172.23C220.937 172.221 221.213 172.27 221.47 172.374C221.727 172.477 221.96 172.633 222.154 172.832C222.347 173.03 222.498 173.267 222.595 173.526C222.693 173.786 222.735 174.063 222.72 174.34C222.72 174.39 222.71 174.51 222.7 174.6ZM219.5 173.97H221.81C221.782 173.688 221.647 173.427 221.433 173.241C221.219 173.055 220.943 172.958 220.66 172.97C220.371 172.961 220.091 173.063 219.875 173.254C219.659 173.446 219.525 173.712 219.5 174V173.97Z"
        fill="#263238"
      />
      <path
        d="M223.319 175.749C223.308 175.661 223.316 175.57 223.342 175.485C223.369 175.399 223.413 175.321 223.472 175.254C223.531 175.186 223.604 175.133 223.685 175.096C223.767 175.059 223.855 175.04 223.944 175.04C224.034 175.04 224.122 175.059 224.204 175.096C224.285 175.133 224.358 175.186 224.417 175.254C224.476 175.321 224.52 175.399 224.547 175.485C224.573 175.57 224.581 175.661 224.569 175.749C224.581 175.838 224.573 175.928 224.547 176.014C224.52 176.099 224.476 176.178 224.417 176.245C224.358 176.312 224.285 176.366 224.204 176.403C224.122 176.439 224.034 176.458 223.944 176.458C223.855 176.458 223.767 176.439 223.685 176.403C223.604 176.366 223.531 176.312 223.472 176.245C223.413 176.178 223.369 176.099 223.342 176.014C223.316 175.928 223.308 175.838 223.319 175.749Z"
        fill="#263238"
      />
      <path
        d="M229.42 170.67V176.33H228.5V175.8C228.334 175.993 228.126 176.146 227.892 176.246C227.658 176.347 227.404 176.393 227.15 176.38C226.622 176.343 226.127 176.108 225.766 175.721C225.405 175.334 225.204 174.824 225.204 174.295C225.204 173.766 225.405 173.256 225.766 172.869C226.127 172.482 226.622 172.247 227.15 172.21C227.395 172.2 227.64 172.244 227.866 172.339C228.092 172.434 228.295 172.578 228.46 172.76V170.67H229.42ZM228.48 174.29C228.468 174.052 228.386 173.822 228.245 173.629C228.104 173.437 227.91 173.29 227.686 173.206C227.463 173.123 227.22 173.108 226.987 173.161C226.754 173.215 226.543 173.335 226.378 173.508C226.213 173.681 226.103 173.898 226.061 174.133C226.018 174.368 226.046 174.61 226.139 174.83C226.233 175.049 226.389 175.236 226.588 175.368C226.788 175.499 227.021 175.57 227.26 175.57C227.426 175.573 227.591 175.542 227.744 175.478C227.898 175.414 228.036 175.318 228.151 175.198C228.266 175.078 228.354 174.935 228.411 174.779C228.467 174.622 228.491 174.456 228.48 174.29Z"
        fill="#263238"
      />
      <path
        d="M230.33 174.29C230.312 173.855 230.425 173.424 230.653 173.054C230.882 172.683 231.217 172.39 231.614 172.211C232.011 172.033 232.452 171.977 232.881 172.052C233.31 172.126 233.707 172.327 234.02 172.629C234.334 172.931 234.55 173.32 234.64 173.746C234.731 174.172 234.692 174.615 234.528 175.019C234.365 175.422 234.084 175.767 233.723 176.01C233.361 176.252 232.935 176.381 232.5 176.38C232.217 176.396 231.935 176.354 231.669 176.256C231.404 176.158 231.161 176.006 230.957 175.81C230.754 175.613 230.593 175.377 230.485 175.115C230.377 174.854 230.324 174.572 230.33 174.29ZM233.71 174.29C233.696 174.054 233.614 173.827 233.473 173.637C233.332 173.448 233.139 173.304 232.917 173.223C232.695 173.142 232.454 173.128 232.224 173.182C231.994 173.236 231.785 173.357 231.623 173.528C231.46 173.7 231.352 173.915 231.311 174.148C231.269 174.381 231.297 174.62 231.39 174.837C231.484 175.054 231.638 175.239 231.836 175.369C232.033 175.5 232.264 175.569 232.5 175.57C232.666 175.574 232.832 175.544 232.985 175.48C233.139 175.417 233.278 175.321 233.392 175.201C233.506 175.08 233.593 174.936 233.648 174.779C233.703 174.622 233.724 174.455 233.71 174.29Z"
        fill="#263238"
      />
      <path
        d="M235.26 174.289C235.256 174.005 235.312 173.723 235.423 173.461C235.534 173.199 235.698 172.963 235.905 172.768C236.111 172.572 236.356 172.422 236.624 172.326C236.891 172.23 237.176 172.19 237.46 172.209C237.811 172.183 238.162 172.258 238.471 172.427C238.779 172.595 239.033 172.85 239.2 173.159L238.47 173.579C238.368 173.406 238.221 173.263 238.045 173.165C237.87 173.066 237.671 173.016 237.47 173.019C237.151 173.048 236.855 173.194 236.639 173.43C236.423 173.666 236.303 173.974 236.303 174.294C236.303 174.614 236.423 174.923 236.639 175.159C236.855 175.395 237.151 175.541 237.47 175.569C237.672 175.571 237.871 175.519 238.046 175.418C238.222 175.318 238.368 175.174 238.47 174.999L239.2 175.429C239.031 175.737 238.777 175.99 238.469 176.159C238.16 176.327 237.81 176.404 237.46 176.379C237.176 176.397 236.891 176.356 236.623 176.259C236.355 176.162 236.111 176.01 235.904 175.814C235.698 175.618 235.534 175.381 235.423 175.119C235.312 174.857 235.257 174.574 235.26 174.289Z"
        fill="#263238"
      />
      <path
        d="M187.999 182.62C187.995 182.341 188.048 182.063 188.155 181.805C188.261 181.547 188.42 181.313 188.62 181.118C188.821 180.923 189.059 180.771 189.32 180.672C189.581 180.573 189.86 180.528 190.139 180.54C190.417 180.53 190.694 180.579 190.953 180.682C191.211 180.785 191.445 180.941 191.639 181.14L191.359 181.41C191.204 181.251 191.017 181.126 190.81 181.044C190.603 180.961 190.382 180.922 190.159 180.93C189.711 180.93 189.281 181.108 188.964 181.425C188.648 181.742 188.469 182.172 188.469 182.62C188.469 183.068 188.648 183.498 188.964 183.815C189.281 184.132 189.711 184.31 190.159 184.31C190.383 184.316 190.604 184.276 190.811 184.191C191.018 184.107 191.204 183.98 191.359 183.82L191.639 184.09C191.445 184.289 191.211 184.445 190.953 184.548C190.694 184.652 190.417 184.7 190.139 184.69C189.859 184.71 189.578 184.671 189.314 184.575C189.05 184.478 188.809 184.328 188.608 184.133C188.406 183.937 188.247 183.702 188.142 183.441C188.038 183.181 187.989 182.901 187.999 182.62Z"
        fill="#E0E0E0"
      />
      <path
        d="M192.05 183.129C192.05 182.821 192.141 182.519 192.313 182.263C192.484 182.006 192.728 181.806 193.013 181.688C193.298 181.57 193.611 181.539 193.914 181.599C194.217 181.66 194.495 181.808 194.713 182.026C194.931 182.244 195.08 182.522 195.14 182.825C195.2 183.128 195.169 183.441 195.051 183.726C194.933 184.011 194.733 184.255 194.476 184.426C194.22 184.598 193.918 184.689 193.61 184.689C193.403 184.696 193.197 184.661 193.005 184.585C192.812 184.509 192.637 184.394 192.491 184.248C192.345 184.102 192.23 183.927 192.154 183.734C192.078 183.542 192.043 183.336 192.05 183.129ZM194.75 183.129C194.74 182.906 194.665 182.691 194.534 182.51C194.403 182.329 194.221 182.191 194.012 182.112C193.803 182.034 193.576 182.018 193.358 182.068C193.14 182.117 192.942 182.229 192.788 182.391C192.633 182.552 192.529 182.755 192.489 182.975C192.449 183.194 192.475 183.421 192.562 183.626C192.65 183.832 192.796 184.007 192.982 184.13C193.168 184.253 193.386 184.319 193.61 184.319C193.765 184.322 193.918 184.293 194.061 184.234C194.204 184.175 194.334 184.086 194.441 183.974C194.548 183.863 194.631 183.73 194.684 183.584C194.737 183.439 194.759 183.284 194.75 183.129Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.07 182.88V184.66H200.66V182.92C200.66 182.28 200.33 181.92 199.78 181.92C199.639 181.919 199.499 181.948 199.37 182.005C199.241 182.062 199.125 182.145 199.031 182.25C198.937 182.355 198.865 182.479 198.822 182.613C198.779 182.747 198.764 182.889 198.78 183.03V184.64H198.37V182.9C198.37 182.26 198.04 181.9 197.48 181.9C197.339 181.899 197.199 181.928 197.07 181.985C196.941 182.042 196.825 182.125 196.731 182.23C196.637 182.335 196.565 182.459 196.522 182.593C196.479 182.727 196.464 182.869 196.48 183.01V184.62H196V181.55H196.4V182.11C196.518 181.922 196.684 181.769 196.881 181.667C197.078 181.564 197.299 181.517 197.52 181.53C197.747 181.512 197.973 181.565 198.168 181.682C198.363 181.798 198.518 181.972 198.61 182.18C198.734 181.97 198.914 181.798 199.129 181.684C199.344 181.569 199.587 181.516 199.83 181.53C200.007 181.513 200.185 181.537 200.351 181.601C200.517 181.665 200.665 181.766 200.785 181.896C200.905 182.027 200.994 182.184 201.043 182.355C201.092 182.525 201.102 182.705 201.07 182.88Z"
        fill="#E0E0E0"
      />
      <path
        d="M205.299 183.129C205.309 183.334 205.276 183.539 205.203 183.731C205.13 183.922 205.019 184.097 204.875 184.243C204.731 184.39 204.559 184.505 204.369 184.582C204.179 184.659 203.974 184.695 203.769 184.689C203.539 184.696 203.31 184.644 203.105 184.537C202.9 184.43 202.726 184.273 202.599 184.079V185.789H202.189V181.589H202.589V182.199C202.712 181.999 202.886 181.835 203.094 181.724C203.301 181.613 203.534 181.56 203.769 181.569C203.974 181.564 204.179 181.6 204.369 181.677C204.559 181.754 204.731 181.869 204.875 182.016C205.019 182.162 205.13 182.337 205.203 182.528C205.276 182.72 205.309 182.925 205.299 183.129ZM204.879 183.129C204.87 182.906 204.795 182.691 204.663 182.51C204.532 182.33 204.351 182.191 204.142 182.113C203.933 182.034 203.706 182.018 203.488 182.068C203.27 182.117 203.072 182.23 202.917 182.391C202.763 182.552 202.659 182.755 202.619 182.975C202.579 183.194 202.604 183.421 202.692 183.626C202.779 183.832 202.925 184.007 203.112 184.13C203.298 184.253 203.516 184.319 203.739 184.319C203.894 184.323 204.048 184.293 204.191 184.234C204.334 184.175 204.463 184.086 204.57 183.974C204.678 183.863 204.76 183.73 204.814 183.584C204.867 183.439 204.889 183.284 204.879 183.129Z"
        fill="#E0E0E0"
      />
      <path d="M206.14 180.33H206.55V184.66H206.14V180.33Z" fill="#E0E0E0" />
      <path
        d="M210.37 183.25H207.81C207.816 183.4 207.852 183.547 207.917 183.682C207.981 183.818 208.072 183.939 208.183 184.039C208.295 184.138 208.426 184.215 208.568 184.263C208.71 184.311 208.86 184.331 209.01 184.32C209.186 184.327 209.362 184.295 209.525 184.226C209.688 184.157 209.833 184.052 209.95 183.92L210.19 184.19C210.04 184.354 209.855 184.484 209.65 184.57C209.444 184.657 209.223 184.697 209 184.69C208.789 184.702 208.579 184.671 208.381 184.598C208.183 184.524 208.003 184.411 207.852 184.264C207.7 184.117 207.581 183.941 207.501 183.745C207.422 183.55 207.384 183.34 207.39 183.13C207.379 182.927 207.41 182.724 207.48 182.534C207.551 182.344 207.66 182.17 207.801 182.024C207.942 181.877 208.111 181.762 208.298 181.684C208.486 181.605 208.687 181.567 208.89 181.57C209.093 181.565 209.295 181.603 209.482 181.68C209.67 181.758 209.839 181.874 209.979 182.021C210.119 182.168 210.227 182.342 210.296 182.533C210.365 182.724 210.394 182.927 210.38 183.13C210.38 183.13 210.37 183.21 210.37 183.25ZM207.81 182.95H210C209.974 182.68 209.848 182.429 209.648 182.247C209.447 182.065 209.186 181.964 208.915 181.964C208.644 181.964 208.383 182.065 208.182 182.247C207.981 182.429 207.856 182.68 207.83 182.95H207.81Z"
        fill="#E0E0E0"
      />
      <path
        d="M212.829 184.47C212.651 184.62 212.423 184.699 212.189 184.69C212.069 184.704 211.946 184.691 211.831 184.651C211.716 184.612 211.612 184.547 211.525 184.461C211.439 184.376 211.373 184.272 211.332 184.158C211.291 184.043 211.276 183.921 211.289 183.8V181.94H210.739V181.59H211.289V180.92H211.699V181.59H212.639V181.94H211.699V183.77C211.688 183.843 211.694 183.918 211.717 183.989C211.739 184.06 211.777 184.125 211.828 184.179C211.879 184.233 211.942 184.274 212.011 184.301C212.081 184.327 212.155 184.337 212.229 184.33C212.395 184.334 212.557 184.281 212.689 184.18L212.829 184.47Z"
        fill="#E0E0E0"
      />
      <path
        d="M216.139 183.25H213.579C213.586 183.4 213.622 183.547 213.686 183.682C213.75 183.818 213.841 183.939 213.953 184.039C214.065 184.138 214.196 184.215 214.338 184.263C214.48 184.311 214.63 184.331 214.779 184.32C214.956 184.327 215.132 184.295 215.295 184.226C215.457 184.157 215.602 184.052 215.719 183.92L215.959 184.19C215.809 184.354 215.625 184.484 215.419 184.57C215.214 184.657 214.992 184.697 214.769 184.69C214.559 184.702 214.348 184.671 214.15 184.598C213.953 184.524 213.773 184.411 213.621 184.264C213.47 184.117 213.35 183.941 213.271 183.745C213.191 183.55 213.153 183.34 213.159 183.13C213.148 182.927 213.179 182.724 213.25 182.534C213.321 182.344 213.43 182.17 213.57 182.024C213.711 181.877 213.88 181.762 214.068 181.684C214.255 181.605 214.456 181.567 214.659 181.57C214.862 181.565 215.064 181.603 215.252 181.68C215.439 181.758 215.608 181.874 215.749 182.021C215.889 182.168 215.997 182.342 216.066 182.533C216.135 182.724 216.163 182.927 216.149 183.13C216.149 183.13 216.139 183.21 216.139 183.25ZM213.579 182.95H215.749C215.723 182.68 215.598 182.429 215.397 182.247C215.197 182.065 214.935 181.964 214.664 181.964C214.393 181.964 214.132 182.065 213.931 182.247C213.731 182.429 213.605 182.68 213.579 182.95Z"
        fill="#E0E0E0"
      />
      <path
        d="M365.8 187.989H187.91C186.993 187.989 186.25 188.732 186.25 189.649C186.25 190.566 186.993 191.309 187.91 191.309H365.8C366.717 191.309 367.46 190.566 367.46 189.649C367.46 188.732 366.717 187.989 365.8 187.989Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M376.67 186.68C375.995 187.713 375.18 188.649 374.25 189.46C373.44 190.388 372.504 191.199 371.47 191.87C372.143 190.838 372.954 189.902 373.88 189.09C374.692 188.16 375.631 187.348 376.67 186.68Z"
        fill="#263238"
      />
      <path
        d="M376.55 191.87C375.515 191.199 374.58 190.388 373.77 189.46C372.839 188.649 372.025 187.713 371.35 186.68C372.384 187.35 373.319 188.161 374.13 189.09C375.06 189.901 375.875 190.836 376.55 191.87Z"
        fill="#263238"
      />
      <path
        d="M351.179 183.49C351.179 184.17 350.679 184.7 349.699 184.7C349.16 184.715 348.635 184.526 348.229 184.17L348.429 183.84C348.777 184.153 349.232 184.322 349.699 184.31C350.379 184.31 350.749 184 350.749 183.49C350.749 182.98 350.399 182.68 349.649 182.68H349.359V182.37L350.489 180.95H348.399V180.58H350.999V180.88L349.849 182.33C350.739 182.38 351.179 182.84 351.179 183.49Z"
        fill="#E0E0E0"
      />
      <path
        d="M357.889 180.58V184.66H357.479V181.4L355.869 184.14H355.669L354.069 181.41V184.66H353.649V180.58H353.999L355.769 183.58L357.519 180.58H357.889Z"
        fill="#E0E0E0"
      />
      <path
        d="M362.62 183.57C362.62 184.26 362.11 184.66 361.09 184.66H359.21V180.58H361C361.91 180.58 362.44 180.96 362.44 181.58C362.44 181.78 362.38 181.976 362.268 182.142C362.156 182.308 361.996 182.436 361.81 182.51C362.052 182.556 362.268 182.69 362.418 182.885C362.567 183.081 362.639 183.325 362.62 183.57ZM359.62 180.94V182.42H360.92C361.58 182.42 361.92 182.17 361.92 181.68C361.92 181.19 361.55 180.94 360.92 180.94H359.62ZM362.17 183.54C362.17 183 361.78 182.77 361.06 182.77H359.62V184.3H361.06C361.8 184.3 362.19 184.07 362.19 183.54H362.17Z"
        fill="#E0E0E0"
      />
      <path
        d="M174.86 233.559H159.4C158.885 233.559 158.392 233.355 158.028 232.991C157.664 232.627 157.46 232.134 157.46 231.619V210.209C157.46 209.955 157.51 209.703 157.608 209.468C157.705 209.234 157.848 209.02 158.029 208.841C158.209 208.662 158.423 208.52 158.658 208.423C158.894 208.327 159.146 208.278 159.4 208.279H169.2L176.8 216.099V231.619C176.8 232.134 176.596 232.627 176.232 232.991C175.868 233.355 175.374 233.559 174.86 233.559Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M169.199 208.279V214.279C169.199 214.757 169.389 215.215 169.726 215.552C170.064 215.89 170.522 216.079 170.999 216.079H176.799L169.199 208.279Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <g opacity="0.2">
        <path
          d="M169.279 214.83C169.283 215.286 169.443 215.727 169.732 216.08C170.022 216.433 170.423 216.677 170.869 216.77H171.059C171.819 216.91 176.799 217.77 176.799 217.68V216.1H170.999C170.999 216.1 169.559 216.05 169.279 214.83Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M169.199 208.279V214.279C169.199 214.757 169.389 215.215 169.726 215.552C170.064 215.89 170.522 216.079 170.999 216.079H176.799L169.199 208.279Z"
          fill="white"
        />
      </g>
      <path
        d="M167.91 214.06C166.722 214.195 165.525 214.242 164.33 214.2C163.135 214.242 161.938 214.195 160.75 214.06C161.938 213.918 163.135 213.872 164.33 213.92C165.525 213.872 166.722 213.918 167.91 214.06Z"
        fill="#FAFAFA"
      />
      <path
        d="M172.53 217.459C172.53 217.539 169.89 217.599 166.64 217.599C163.39 217.599 160.75 217.539 160.75 217.459C160.75 217.379 163.39 217.319 166.64 217.319C169.89 217.319 172.53 217.379 172.53 217.459Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 220.31C173.59 220.38 170.72 220.45 167.17 220.45C163.62 220.45 160.75 220.38 160.75 220.31C160.75 220.24 163.63 220.17 167.17 220.17C170.71 220.17 173.59 220.23 173.59 220.31Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 222.999C173.59 223.079 170.72 223.139 167.17 223.139C163.62 223.139 160.75 223.079 160.75 222.999C160.75 222.919 163.63 222.859 167.17 222.859C170.71 222.859 173.59 222.909 173.59 222.999Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 225.669C173.59 225.749 170.72 225.809 167.17 225.809C163.62 225.809 160.75 225.749 160.75 225.669C160.75 225.589 163.63 225.529 167.17 225.529C170.71 225.529 173.59 225.599 173.59 225.669Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 228.36C173.59 228.44 170.72 228.5 167.17 228.5C163.62 228.5 160.75 228.44 160.75 228.36C160.75 228.28 163.63 228.22 167.17 228.22C170.71 228.22 173.59 228.28 173.59 228.36Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.939 211C190.244 210.987 190.547 211.064 190.809 211.22L190.539 211.94C190.382 211.834 190.199 211.775 190.009 211.77C189.619 211.77 189.409 211.99 189.409 212.43V212.67H190.529V213.43H189.439V216.71H188.489V213.43H187.819V212.67H188.489V212.41C188.473 212.217 188.5 212.023 188.568 211.842C188.636 211.661 188.743 211.497 188.882 211.363C189.02 211.228 189.187 211.125 189.37 211.063C189.553 211 189.747 210.978 189.939 211ZM191.649 211.39C191.651 211.312 191.667 211.235 191.699 211.164C191.731 211.093 191.776 211.028 191.833 210.975C191.89 210.922 191.957 210.881 192.03 210.854C192.104 210.827 192.182 210.816 192.259 210.82C192.336 210.814 192.413 210.824 192.485 210.849C192.558 210.874 192.624 210.914 192.681 210.965C192.738 211.016 192.784 211.079 192.817 211.148C192.849 211.218 192.867 211.293 192.869 211.37C192.869 211.532 192.805 211.687 192.691 211.801C192.576 211.916 192.421 211.98 192.259 211.98C192.098 211.98 191.942 211.916 191.828 211.801C191.714 211.687 191.649 211.532 191.649 211.37V211.39ZM191.779 212.64H192.779V216.71H191.779V212.64Z"
        fill="#263238"
      />
      <path d="M194 211H195V216.66H194V211Z" fill="#263238" />
      <path
        d="M200 214.94H196.81C196.866 215.241 197.032 215.51 197.276 215.695C197.52 215.88 197.825 215.967 198.13 215.94C198.334 215.946 198.536 215.91 198.726 215.834C198.915 215.759 199.087 215.645 199.23 215.5L199.74 216.08C199.536 216.31 199.282 216.49 198.998 216.606C198.714 216.722 198.406 216.771 198.1 216.75C197.812 216.775 197.521 216.739 197.248 216.645C196.974 216.551 196.723 216.401 196.511 216.204C196.299 216.007 196.13 215.768 196.017 215.502C195.903 215.236 195.846 214.949 195.85 214.66C195.837 214.38 195.883 214.101 195.984 213.84C196.086 213.579 196.241 213.342 196.44 213.145C196.639 212.948 196.877 212.795 197.139 212.696C197.401 212.597 197.68 212.554 197.96 212.57C198.237 212.561 198.513 212.61 198.77 212.713C199.027 212.817 199.26 212.973 199.454 213.171C199.648 213.37 199.798 213.606 199.895 213.866C199.993 214.126 200.035 214.403 200.02 214.68C200 214.73 200 214.85 200 214.94ZM196.8 214.31H199.11C199.065 214.035 198.925 213.786 198.713 213.605C198.502 213.425 198.233 213.326 197.955 213.326C197.677 213.326 197.408 213.425 197.196 213.605C196.985 213.786 196.844 214.035 196.8 214.31Z"
        fill="#263238"
      />
      <path
        d="M204.999 214.34V216.67H203.999V214.46C203.999 213.74 203.639 213.38 202.999 213.38C202.84 213.371 202.68 213.398 202.531 213.458C202.383 213.518 202.25 213.61 202.141 213.727C202.032 213.845 201.95 213.985 201.902 214.137C201.853 214.29 201.839 214.451 201.859 214.61V216.67H200.859V212.6H201.769V213.13C201.944 212.934 202.16 212.78 202.403 212.68C202.645 212.579 202.907 212.535 203.169 212.55C203.415 212.51 203.667 212.528 203.905 212.603C204.142 212.679 204.358 212.808 204.537 212.983C204.715 213.157 204.849 213.37 204.93 213.606C205.01 213.842 205.034 214.093 204.999 214.34Z"
        fill="#263238"
      />
      <path
        d="M209.59 214.28V216.67H208.69V216.18C208.54 216.372 208.344 216.523 208.121 216.62C207.897 216.716 207.652 216.754 207.41 216.73C206.47 216.73 205.89 216.22 205.89 215.51C205.89 214.8 206.34 214.29 207.58 214.29H208.58V214.23C208.58 213.67 208.25 213.35 207.58 213.35C207.133 213.341 206.697 213.482 206.34 213.75L205.97 213.06C206.473 212.707 207.076 212.528 207.69 212.55C208.91 212.55 209.59 213.11 209.59 214.28ZM208.59 215.4V214.93H207.59C206.94 214.93 206.77 215.17 206.77 215.47C206.77 215.77 207.06 216.03 207.54 216.03C207.766 216.065 207.998 216.022 208.196 215.908C208.395 215.793 208.548 215.614 208.63 215.4H208.59Z"
        fill="#263238"
      />
      <path
        d="M217.62 214.34V216.67H216.62V214.46C216.62 213.74 216.29 213.38 215.71 213.38C215.13 213.38 214.64 213.8 214.64 214.61V216.67H213.69V214.46C213.69 213.74 213.36 213.38 212.78 213.38C212.2 213.38 211.71 213.8 211.71 214.61V216.67H210.76V212.6H211.67V213.12C211.835 212.931 212.04 212.782 212.27 212.683C212.5 212.585 212.75 212.539 213 212.55C213.269 212.531 213.539 212.584 213.781 212.704C214.024 212.823 214.23 213.005 214.38 213.23C214.564 213.008 214.797 212.831 215.061 212.713C215.324 212.596 215.611 212.54 215.9 212.55C216.138 212.524 216.379 212.554 216.604 212.636C216.829 212.718 217.033 212.85 217.199 213.023C217.365 213.196 217.489 213.404 217.562 213.633C217.635 213.861 217.655 214.103 217.62 214.34Z"
        fill="#263238"
      />
      <path
        d="M222.7 214.94H219.51C219.566 215.241 219.732 215.51 219.977 215.695C220.221 215.88 220.525 215.967 220.83 215.94C221.034 215.946 221.237 215.91 221.426 215.834C221.615 215.759 221.787 215.645 221.93 215.5L222.45 216.08C222.142 216.388 221.752 216.601 221.326 216.696C220.901 216.79 220.457 216.761 220.048 216.612C219.638 216.462 219.28 216.2 219.014 215.854C218.749 215.508 218.588 215.094 218.55 214.66C218.537 214.38 218.583 214.101 218.684 213.84C218.786 213.579 218.941 213.342 219.14 213.145C219.339 212.948 219.577 212.795 219.839 212.696C220.101 212.597 220.381 212.554 220.66 212.57C220.937 212.561 221.213 212.61 221.47 212.713C221.728 212.817 221.96 212.973 222.154 213.171C222.348 213.37 222.498 213.606 222.596 213.866C222.693 214.126 222.735 214.403 222.72 214.68C222.72 214.73 222.71 214.85 222.7 214.94ZM219.5 214.31H221.81C221.785 214.027 221.651 213.765 221.436 213.578C221.222 213.392 220.944 213.295 220.66 213.31C220.376 213.298 220.098 213.395 219.882 213.58C219.667 213.766 219.53 214.027 219.5 214.31Z"
        fill="#263238"
      />
      <path
        d="M223.319 216.09C223.308 216.001 223.316 215.911 223.342 215.826C223.369 215.74 223.413 215.661 223.472 215.594C223.531 215.527 223.604 215.474 223.685 215.437C223.767 215.4 223.855 215.381 223.944 215.381C224.034 215.381 224.122 215.4 224.204 215.437C224.285 215.474 224.358 215.527 224.417 215.594C224.476 215.661 224.52 215.74 224.547 215.826C224.573 215.911 224.581 216.001 224.569 216.09C224.581 216.179 224.573 216.269 224.547 216.354C224.52 216.44 224.476 216.519 224.417 216.586C224.358 216.653 224.285 216.707 224.204 216.743C224.122 216.78 224.034 216.799 223.944 216.799C223.855 216.799 223.767 216.78 223.685 216.743C223.604 216.707 223.531 216.653 223.472 216.586C223.413 216.519 223.369 216.44 223.342 216.354C223.316 216.269 223.308 216.179 223.319 216.09Z"
        fill="#263238"
      />
      <path
        d="M224.239 218L224.519 217.29C224.68 217.402 224.873 217.458 225.069 217.45C225.429 217.45 225.619 217.24 225.619 216.8V212.6H226.619V216.75C226.641 216.943 226.619 217.139 226.556 217.323C226.492 217.507 226.389 217.675 226.253 217.815C226.118 217.954 225.953 218.062 225.771 218.13C225.588 218.199 225.393 218.226 225.199 218.21C224.865 218.245 224.528 218.172 224.239 218ZM225.489 211.38C225.49 211.302 225.507 211.225 225.539 211.154C225.571 211.083 225.616 211.019 225.673 210.965C225.73 210.912 225.797 210.871 225.87 210.844C225.944 210.818 226.021 210.806 226.099 210.81C226.176 210.805 226.253 210.815 226.325 210.84C226.398 210.865 226.464 210.904 226.521 210.955C226.578 211.007 226.624 211.069 226.657 211.138C226.689 211.208 226.707 211.283 226.709 211.36C226.711 211.44 226.696 211.52 226.665 211.594C226.635 211.668 226.59 211.735 226.533 211.791C226.476 211.847 226.408 211.891 226.334 211.92C226.259 211.949 226.179 211.963 226.099 211.96C226.018 211.964 225.937 211.952 225.861 211.922C225.785 211.893 225.716 211.848 225.658 211.791C225.601 211.733 225.556 211.664 225.527 211.588C225.498 211.513 225.485 211.431 225.489 211.35V211.38Z"
        fill="#263238"
      />
      <path
        d="M232.16 214.64C232.173 214.916 232.128 215.192 232.029 215.45C231.929 215.708 231.778 215.942 231.583 216.139C231.389 216.335 231.156 216.489 230.899 216.591C230.642 216.692 230.366 216.74 230.09 216.73C229.845 216.738 229.601 216.693 229.375 216.598C229.149 216.503 228.946 216.361 228.78 216.18V218.18H227.83V212.6H228.74V213.13C228.906 212.937 229.114 212.784 229.348 212.683C229.582 212.583 229.836 212.537 230.09 212.55C230.366 212.54 230.642 212.587 230.899 212.689C231.156 212.791 231.389 212.945 231.583 213.141C231.778 213.338 231.929 213.572 232.029 213.83C232.128 214.088 232.173 214.364 232.16 214.64ZM231.16 214.64C231.148 214.404 231.068 214.176 230.928 213.985C230.788 213.794 230.595 213.648 230.373 213.566C230.151 213.483 229.91 213.468 229.679 213.521C229.449 213.574 229.239 213.694 229.076 213.865C228.912 214.037 228.803 214.252 228.761 214.485C228.719 214.718 228.746 214.958 228.839 215.176C228.932 215.393 229.087 215.579 229.284 215.709C229.482 215.84 229.713 215.91 229.95 215.91C230.117 215.917 230.285 215.889 230.44 215.828C230.596 215.766 230.737 215.672 230.854 215.552C230.971 215.433 231.062 215.289 231.12 215.132C231.178 214.975 231.202 214.807 231.19 214.64H231.16Z"
        fill="#263238"
      />
      <path
        d="M237.11 212.599V216.049C237.151 216.346 237.122 216.648 237.025 216.932C236.929 217.216 236.767 217.473 236.553 217.683C236.339 217.893 236.079 218.05 235.794 218.141C235.509 218.232 235.206 218.256 234.91 218.209C234.198 218.241 233.495 218.037 232.91 217.629L233.34 216.909C233.772 217.234 234.3 217.406 234.84 217.399C235.74 217.399 236.14 216.989 236.14 216.159V215.999C235.961 216.179 235.747 216.32 235.512 216.413C235.276 216.506 235.023 216.549 234.77 216.539C234.482 216.59 234.187 216.577 233.906 216.501C233.624 216.425 233.362 216.288 233.138 216.101C232.915 215.913 232.735 215.679 232.612 215.414C232.489 215.15 232.425 214.861 232.425 214.569C232.425 214.277 232.489 213.989 232.612 213.725C232.735 213.46 232.915 213.226 233.138 213.038C233.362 212.85 233.624 212.714 233.906 212.638C234.187 212.562 234.482 212.549 234.77 212.599C235.034 212.586 235.298 212.632 235.542 212.734C235.786 212.836 236.004 212.992 236.18 213.189V212.649L237.11 212.599ZM236.17 214.509C236.184 214.261 236.123 214.014 235.995 213.801C235.867 213.588 235.678 213.418 235.453 213.314C235.227 213.21 234.975 213.175 234.73 213.216C234.485 213.256 234.257 213.37 234.077 213.541C233.897 213.712 233.773 213.934 233.72 214.177C233.667 214.42 233.689 214.673 233.782 214.903C233.875 215.134 234.035 215.331 234.242 215.469C234.448 215.608 234.691 215.681 234.94 215.679C235.099 215.69 235.259 215.668 235.409 215.614C235.56 215.56 235.697 215.475 235.813 215.365C235.928 215.255 236.02 215.122 236.081 214.975C236.143 214.828 236.173 214.669 236.17 214.509Z"
        fill="#263238"
      />
      <path
        d="M188.399 220.92H188.829V224.63H191.109V225H188.399V220.92Z"
        fill="#E0E0E0"
      />
      <path
        d="M191.38 223.469C191.38 223.161 191.471 222.859 191.643 222.603C191.814 222.346 192.058 222.146 192.343 222.028C192.628 221.91 192.941 221.879 193.244 221.939C193.547 221.999 193.825 222.148 194.043 222.366C194.261 222.584 194.41 222.862 194.47 223.165C194.53 223.467 194.499 223.781 194.381 224.066C194.263 224.351 194.063 224.595 193.806 224.766C193.55 224.938 193.248 225.029 192.94 225.029C192.733 225.036 192.527 225.001 192.335 224.925C192.142 224.849 191.967 224.734 191.821 224.588C191.675 224.442 191.56 224.267 191.484 224.074C191.408 223.882 191.373 223.676 191.38 223.469ZM194.08 223.469C194.07 223.246 193.995 223.031 193.864 222.85C193.733 222.669 193.551 222.531 193.342 222.452C193.134 222.374 192.906 222.358 192.688 222.408C192.471 222.457 192.272 222.569 192.118 222.731C191.963 222.892 191.859 223.095 191.819 223.315C191.779 223.534 191.805 223.761 191.892 223.966C191.98 224.172 192.126 224.347 192.312 224.47C192.498 224.593 192.716 224.659 192.94 224.659C193.095 224.662 193.248 224.633 193.391 224.574C193.534 224.514 193.664 224.426 193.771 224.314C193.878 224.202 193.961 224.07 194.014 223.924C194.067 223.779 194.089 223.624 194.08 223.469Z"
        fill="#E0E0E0"
      />
      <path
        d="M197.639 223.099V224.999H197.239V224.519C197.127 224.692 196.97 224.831 196.785 224.92C196.6 225.01 196.394 225.048 196.189 225.029C195.489 225.029 195.069 224.669 195.069 224.139C195.069 223.609 195.369 223.269 196.249 223.269H197.249V223.079C197.26 222.966 197.244 222.852 197.204 222.745C197.164 222.639 197.1 222.543 197.017 222.465C196.934 222.387 196.834 222.329 196.726 222.295C196.617 222.261 196.502 222.252 196.389 222.269C196.024 222.269 195.67 222.396 195.389 222.629L195.199 222.329C195.552 222.045 195.996 221.896 196.449 221.909C196.609 221.894 196.771 221.914 196.922 221.968C197.074 222.023 197.211 222.11 197.325 222.224C197.439 222.337 197.526 222.475 197.58 222.626C197.635 222.778 197.655 222.939 197.639 223.099ZM197.219 224.099V223.599H196.219C195.629 223.599 195.439 223.829 195.439 224.149C195.439 224.469 195.719 224.719 196.209 224.719C196.424 224.736 196.639 224.682 196.821 224.567C197.003 224.452 197.142 224.281 197.219 224.079V224.099Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.569 220.679V224.999H201.169V224.399C201.044 224.593 200.87 224.75 200.664 224.855C200.459 224.961 200.23 225.01 199.999 224.999C199.586 224.999 199.189 224.835 198.896 224.543C198.604 224.25 198.439 223.853 198.439 223.439C198.439 223.026 198.604 222.629 198.896 222.336C199.189 222.044 199.586 221.879 199.999 221.879C200.23 221.872 200.459 221.925 200.664 222.032C200.869 222.139 201.043 222.296 201.169 222.489V220.649L201.569 220.679ZM201.159 223.469C201.15 223.246 201.075 223.031 200.943 222.85C200.812 222.669 200.631 222.531 200.422 222.453C200.213 222.374 199.986 222.358 199.768 222.408C199.55 222.457 199.352 222.57 199.197 222.731C199.043 222.892 198.939 223.095 198.899 223.315C198.859 223.534 198.884 223.761 198.972 223.966C199.059 224.172 199.205 224.347 199.392 224.47C199.578 224.593 199.796 224.659 200.019 224.659C200.174 224.662 200.328 224.633 200.471 224.574C200.614 224.515 200.743 224.426 200.85 224.314C200.958 224.203 201.04 224.07 201.094 223.924C201.147 223.779 201.169 223.624 201.159 223.469Z"
        fill="#E0E0E0"
      />
      <path
        d="M202.63 221C202.63 220.961 202.638 220.922 202.653 220.886C202.668 220.85 202.69 220.818 202.718 220.791C202.746 220.764 202.779 220.743 202.816 220.729C202.852 220.715 202.891 220.708 202.93 220.71C202.968 220.707 203.007 220.712 203.043 220.725C203.079 220.738 203.113 220.758 203.141 220.784C203.169 220.811 203.192 220.842 203.207 220.878C203.222 220.913 203.23 220.951 203.23 220.99C203.23 221.069 203.198 221.146 203.142 221.202C203.086 221.258 203.009 221.29 202.93 221.29C202.85 221.29 202.774 221.258 202.718 221.202C202.661 221.146 202.63 221.069 202.63 220.99V221ZM202.72 222H203.13V225H202.72V222Z"
        fill="#E0E0E0"
      />
      <path
        d="M207.109 223.22V224.999H206.689V223.259C206.713 223.13 206.707 222.996 206.67 222.87C206.634 222.743 206.568 222.627 206.479 222.53C206.39 222.433 206.279 222.358 206.156 222.311C206.033 222.264 205.9 222.246 205.769 222.259C205.623 222.248 205.477 222.27 205.34 222.321C205.203 222.373 205.079 222.454 204.977 222.559C204.875 222.664 204.797 222.79 204.749 222.929C204.701 223.067 204.684 223.214 204.699 223.359V224.999H204.279V221.94H204.679V222.499C204.805 222.306 204.979 222.15 205.185 222.046C205.39 221.943 205.62 221.895 205.849 221.91C206.022 221.897 206.196 221.922 206.358 221.984C206.521 222.046 206.667 222.143 206.787 222.268C206.908 222.393 206.999 222.543 207.055 222.708C207.11 222.872 207.129 223.047 207.109 223.22Z"
        fill="#E0E0E0"
      />
      <path
        d="M211.08 221.939V224.629C211.111 224.838 211.092 225.051 211.025 225.252C210.958 225.452 210.846 225.634 210.696 225.783C210.546 225.931 210.363 226.043 210.163 226.108C209.962 226.174 209.749 226.191 209.54 226.159C209.025 226.18 208.521 226.013 208.12 225.689L208.33 225.369C208.664 225.653 209.092 225.803 209.53 225.789C210.31 225.789 210.66 225.439 210.66 224.679V224.289C210.529 224.482 210.351 224.639 210.143 224.744C209.935 224.849 209.703 224.899 209.47 224.889C209.269 224.899 209.067 224.867 208.879 224.796C208.69 224.724 208.518 224.615 208.374 224.474C208.23 224.333 208.116 224.165 208.039 223.978C207.963 223.791 207.926 223.591 207.93 223.389C207.927 223.189 207.965 222.99 208.042 222.805C208.119 222.62 208.234 222.453 208.378 222.315C208.523 222.176 208.694 222.068 208.882 221.998C209.07 221.929 209.27 221.898 209.47 221.909C209.707 221.899 209.943 221.95 210.154 222.057C210.366 222.164 210.547 222.323 210.68 222.519V221.939H211.08ZM210.68 223.389C210.688 223.156 210.626 222.926 210.503 222.729C210.379 222.531 210.199 222.375 209.986 222.28C209.773 222.186 209.537 222.157 209.307 222.197C209.078 222.238 208.866 222.346 208.698 222.508C208.53 222.67 208.415 222.878 208.366 223.106C208.318 223.334 208.339 223.571 208.426 223.787C208.513 224.004 208.663 224.189 208.856 224.319C209.049 224.45 209.277 224.519 209.51 224.519C209.663 224.529 209.816 224.507 209.96 224.454C210.103 224.401 210.235 224.319 210.345 224.213C210.455 224.107 210.542 223.978 210.599 223.837C210.657 223.695 210.685 223.542 210.68 223.389Z"
        fill="#E0E0E0"
      />
      <path
        d="M213.489 224.709C213.489 224.627 213.522 224.548 213.58 224.49C213.638 224.432 213.717 224.399 213.799 224.399C213.881 224.399 213.96 224.432 214.018 224.49C214.077 224.548 214.109 224.627 214.109 224.709C214.109 224.792 214.077 224.87 214.018 224.929C213.96 224.987 213.881 225.019 213.799 225.019C213.717 225.019 213.638 224.987 213.58 224.929C213.522 224.87 213.489 224.792 213.489 224.709Z"
        fill="#E0E0E0"
      />
      <path
        d="M214.72 224.71C214.72 224.627 214.753 224.549 214.811 224.49C214.869 224.432 214.948 224.4 215.03 224.4C215.071 224.398 215.113 224.405 215.152 224.42C215.19 224.435 215.226 224.458 215.256 224.487C215.285 224.516 215.309 224.55 215.325 224.589C215.342 224.627 215.35 224.668 215.35 224.71C215.35 224.794 215.316 224.876 215.256 224.936C215.196 224.996 215.115 225.03 215.03 225.03C214.988 225.03 214.947 225.021 214.909 225.005C214.871 224.989 214.836 224.965 214.807 224.935C214.778 224.905 214.756 224.87 214.741 224.831C214.726 224.793 214.719 224.751 214.72 224.71Z"
        fill="#E0E0E0"
      />
      <path
        d="M216 224.709C216 224.627 216.033 224.548 216.091 224.49C216.149 224.432 216.228 224.399 216.31 224.399C216.392 224.399 216.471 224.432 216.529 224.49C216.587 224.548 216.62 224.627 216.62 224.709C216.62 224.792 216.587 224.87 216.529 224.929C216.471 224.987 216.392 225.019 216.31 225.019C216.228 225.019 216.149 224.987 216.091 224.929C216.033 224.87 216 224.792 216 224.709Z"
        fill="#E0E0E0"
      />
      <path
        d="M365.8 231.66H187.91C187.47 231.66 187.048 231.485 186.736 231.174C186.425 230.863 186.25 230.44 186.25 230C186.249 229.781 186.291 229.564 186.374 229.362C186.456 229.159 186.578 228.975 186.733 228.82C186.887 228.665 187.07 228.541 187.272 228.457C187.475 228.373 187.691 228.33 187.91 228.33H365.8C366.019 228.33 366.236 228.373 366.438 228.457C366.64 228.541 366.823 228.665 366.977 228.82C367.132 228.975 367.254 229.159 367.337 229.362C367.419 229.564 367.461 229.781 367.46 230C367.46 230.218 367.417 230.434 367.334 230.635C367.25 230.837 367.128 231.02 366.974 231.174C366.82 231.328 366.637 231.45 366.435 231.534C366.234 231.617 366.018 231.66 365.8 231.66Z"
        fill="#E0E0E0"
      />
      <path
        d="M291.85 231.66H187.91C187.47 231.66 187.048 231.485 186.736 231.174C186.425 230.863 186.25 230.44 186.25 230C186.249 229.781 186.291 229.564 186.374 229.362C186.456 229.159 186.578 228.975 186.733 228.82C186.887 228.665 187.07 228.541 187.272 228.457C187.475 228.373 187.691 228.33 187.91 228.33H291.85C292.069 228.33 292.286 228.373 292.488 228.457C292.69 228.541 292.873 228.665 293.027 228.82C293.182 228.975 293.304 229.159 293.387 229.362C293.469 229.564 293.511 229.781 293.51 230C293.51 230.44 293.335 230.863 293.024 231.174C292.713 231.485 292.29 231.66 291.85 231.66Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M376.67 227C375.998 228.036 375.183 228.972 374.25 229.78C373.446 230.715 372.51 231.527 371.47 232.19C372.143 231.158 372.954 230.223 373.88 229.41C374.692 228.48 375.631 227.669 376.67 227Z"
        fill="#263238"
      />
      <path
        d="M376.55 232.21C375.51 231.547 374.573 230.735 373.77 229.8C372.836 228.991 372.022 228.055 371.35 227.02C372.384 227.69 373.319 228.501 374.13 229.43C375.06 230.241 375.875 231.176 376.55 232.21Z"
        fill="#263238"
      />
      <path
        d="M351.269 224.629V224.999H348.409V224.699L350.099 222.999C350.559 222.549 350.649 222.269 350.649 221.999C350.649 221.529 350.319 221.239 349.649 221.239C349.442 221.228 349.234 221.264 349.042 221.343C348.85 221.423 348.678 221.544 348.539 221.699L348.239 221.439C348.424 221.243 348.65 221.089 348.9 220.989C349.151 220.889 349.42 220.844 349.689 220.859C350.519 220.859 351.039 221.279 351.039 221.959C351.031 222.208 350.966 222.451 350.85 222.671C350.734 222.891 350.569 223.082 350.369 223.229L348.999 224.629H351.269Z"
        fill="#E0E0E0"
      />
      <path
        d="M351.76 223C351.76 221.7 352.45 220.93 353.39 220.93C354.33 220.93 355.02 221.7 355.02 223C355.02 224.3 354.34 225.08 353.39 225.08C352.44 225.08 351.76 224.26 351.76 223ZM354.59 223C354.59 221.91 354.1 221.31 353.39 221.31C352.68 221.31 352.19 221.91 352.19 223C352.19 224.09 352.68 224.69 353.39 224.69C354.1 224.69 354.59 224.05 354.59 223Z"
        fill="#E0E0E0"
      />
      <path
        d="M358.56 223.46L357.83 224.13V225H357.42V220.68H357.83V223.61L359.66 221.94H360.18L358.86 223.19L360.3 225H359.79L358.56 223.46Z"
        fill="#E0E0E0"
      />
      <path
        d="M364 223.469C364.007 223.67 363.973 223.869 363.9 224.056C363.828 224.243 363.718 224.413 363.578 224.556C363.437 224.699 363.27 224.812 363.084 224.888C362.899 224.964 362.7 225.002 362.5 224.999C362.264 225.009 362.029 224.955 361.82 224.845C361.611 224.734 361.435 224.57 361.31 224.369V224.969H360.92V220.649H361.33V222.489C361.456 222.296 361.63 222.139 361.835 222.032C362.04 221.925 362.269 221.872 362.5 221.879C362.706 221.876 362.91 221.916 363.099 221.996C363.289 222.075 363.459 222.194 363.601 222.344C363.742 222.493 363.85 222.671 363.919 222.864C363.988 223.058 364.015 223.264 364 223.469ZM363.59 223.469C363.58 223.246 363.505 223.031 363.374 222.85C363.243 222.669 363.062 222.531 362.853 222.453C362.644 222.374 362.416 222.358 362.198 222.408C361.981 222.457 361.782 222.57 361.628 222.731C361.473 222.892 361.37 223.095 361.33 223.315C361.289 223.534 361.315 223.761 361.402 223.966C361.49 224.172 361.636 224.347 361.822 224.47C362.008 224.593 362.227 224.659 362.45 224.659C362.606 224.665 362.762 224.638 362.908 224.58C363.053 224.522 363.185 224.434 363.294 224.322C363.403 224.21 363.488 224.076 363.542 223.929C363.597 223.782 363.62 223.626 363.61 223.469H363.59Z"
        fill="#E0E0E0"
      />
      <path
        d="M174.86 274.45H159.4C158.885 274.45 158.392 274.246 158.028 273.882C157.664 273.518 157.46 273.024 157.46 272.51V251.1C157.463 250.587 157.668 250.096 158.032 249.735C158.395 249.373 158.887 249.17 159.4 249.17H169.2L176.8 257V272.51C176.8 273.024 176.596 273.518 176.232 273.882C175.868 274.246 175.374 274.45 174.86 274.45Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M169.199 249.17V255.17C169.195 255.409 169.239 255.646 169.328 255.868C169.416 256.09 169.548 256.292 169.716 256.462C169.883 256.632 170.083 256.768 170.304 256.86C170.524 256.952 170.761 257 170.999 257H176.799L169.199 249.17Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <g opacity="0.2">
        <path
          d="M169.279 255.72C169.283 256.176 169.443 256.617 169.732 256.97C170.022 257.323 170.423 257.566 170.869 257.66H171.059C171.819 257.79 176.799 258.66 176.799 258.56V257H170.999C170.999 257 169.559 257 169.279 255.72Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M169.199 249.17V255.17C169.195 255.409 169.239 255.646 169.328 255.868C169.416 256.09 169.548 256.292 169.716 256.462C169.883 256.632 170.083 256.768 170.304 256.86C170.524 256.952 170.761 257 170.999 257H176.799L169.199 249.17Z"
          fill="white"
        />
      </g>
      <path
        d="M167.91 255C166.722 255.142 165.525 255.188 164.33 255.14C163.135 255.188 161.938 255.142 160.75 255C161.938 254.859 163.135 254.812 164.33 254.86C165.525 254.812 166.722 254.859 167.91 255Z"
        fill="#FAFAFA"
      />
      <path
        d="M172.53 258.35C172.53 258.43 169.89 258.5 166.64 258.5C163.39 258.5 160.75 258.43 160.75 258.35C160.75 258.27 163.39 258.21 166.64 258.21C169.89 258.21 172.53 258.28 172.53 258.35Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 261.2C173.59 261.28 170.72 261.34 167.17 261.34C163.62 261.34 160.75 261.28 160.75 261.2C160.75 261.12 163.63 261.06 167.17 261.06C170.71 261.06 173.59 261.12 173.59 261.2Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 263.879C173.59 263.959 170.72 264.019 167.17 264.019C163.62 264.019 160.75 263.959 160.75 263.879C160.75 263.799 163.63 263.739 167.17 263.739C170.71 263.739 173.59 263.809 173.59 263.879Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 266.57C173.59 266.64 170.72 266.71 167.17 266.71C163.62 266.71 160.75 266.64 160.75 266.57C160.75 266.5 163.63 266.43 167.17 266.43C170.71 266.43 173.59 266.49 173.59 266.57Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 269.249C173.59 269.329 170.72 269.389 167.17 269.389C163.62 269.389 160.75 269.329 160.75 269.249C160.75 269.169 163.63 269.109 167.17 269.109C170.71 269.109 173.59 269.169 173.59 269.249Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.939 251.85C190.244 251.838 190.547 251.914 190.809 252.07L190.539 252.79C190.382 252.684 190.199 252.625 190.009 252.62C189.619 252.62 189.409 252.84 189.409 253.28V253.52H190.529V254.29H189.439V257.57H188.489V254.29H187.819V253.52H188.489V253.26C188.473 253.067 188.5 252.873 188.568 252.692C188.636 252.511 188.743 252.348 188.882 252.213C189.02 252.078 189.187 251.976 189.37 251.913C189.553 251.85 189.747 251.829 189.939 251.85ZM191.649 252.25C191.651 252.172 191.667 252.095 191.699 252.024C191.731 251.953 191.776 251.889 191.833 251.835C191.89 251.782 191.957 251.741 192.03 251.714C192.104 251.688 192.182 251.676 192.259 251.68C192.336 251.673 192.414 251.682 192.487 251.707C192.56 251.731 192.627 251.77 192.684 251.822C192.742 251.873 192.788 251.936 192.819 252.007C192.851 252.077 192.868 252.153 192.869 252.23C192.869 252.392 192.805 252.547 192.691 252.661C192.576 252.776 192.421 252.84 192.259 252.84C192.098 252.84 191.942 252.776 191.828 252.661C191.714 252.547 191.649 252.392 191.649 252.23V252.25ZM191.779 253.49H192.779V257.57H191.779V253.49Z"
        fill="#263238"
      />
      <path d="M194 251.909H195V257.569H194V251.909Z" fill="#263238" />
      <path
        d="M199.999 255.83H196.809C196.865 256.131 197.032 256.4 197.276 256.585C197.52 256.77 197.824 256.857 198.129 256.83C198.333 256.838 198.537 256.803 198.726 256.727C198.916 256.652 199.087 256.537 199.229 256.39L199.739 256.98C199.43 257.285 199.04 257.496 198.615 257.588C198.191 257.68 197.748 257.65 197.341 257.5C196.933 257.35 196.576 257.087 196.312 256.741C196.048 256.396 195.887 255.983 195.849 255.55C195.838 255.271 195.885 254.993 195.987 254.733C196.089 254.474 196.245 254.238 196.444 254.042C196.642 253.847 196.88 253.694 197.141 253.596C197.402 253.497 197.681 253.454 197.959 253.47C198.236 253.461 198.511 253.51 198.767 253.613C199.024 253.716 199.256 253.871 199.45 254.068C199.643 254.266 199.794 254.501 199.892 254.759C199.99 255.018 200.033 255.294 200.019 255.57C199.999 255.63 199.999 255.74 199.999 255.83ZM196.799 255.2H199.109C199.082 254.918 198.947 254.657 198.733 254.471C198.519 254.285 198.242 254.188 197.959 254.2C197.677 254.193 197.403 254.292 197.19 254.477C196.978 254.662 196.842 254.92 196.809 255.2H196.799Z"
        fill="#263238"
      />
      <path
        d="M204.999 255.229V257.569H203.999V255.349C203.999 254.629 203.639 254.279 202.999 254.279C202.839 254.269 202.678 254.294 202.529 254.354C202.38 254.413 202.246 254.505 202.136 254.623C202.027 254.741 201.946 254.881 201.898 255.035C201.85 255.188 201.837 255.35 201.859 255.509V257.569H200.859V253.489H201.769V253.999C201.944 253.805 202.161 253.652 202.404 253.553C202.646 253.455 202.908 253.412 203.169 253.429C203.417 253.387 203.67 253.403 203.91 253.478C204.149 253.553 204.367 253.684 204.546 253.86C204.725 254.036 204.859 254.252 204.938 254.49C205.017 254.728 205.038 254.981 204.999 255.229Z"
        fill="#263238"
      />
      <path
        d="M209.59 255.179V257.569H208.69V257.069C208.54 257.262 208.344 257.413 208.121 257.509C207.897 257.606 207.652 257.643 207.41 257.619C206.47 257.619 205.89 257.109 205.89 256.399C205.89 255.689 206.34 255.189 207.58 255.189H208.58V255.129C208.58 254.569 208.25 254.239 207.58 254.239C207.133 254.23 206.697 254.371 206.34 254.639L206 253.999C206.503 253.65 207.107 253.475 207.72 253.499C208.91 253.449 209.59 253.999 209.59 255.179ZM208.59 256.289V255.819H207.59C206.94 255.819 206.77 256.059 206.77 256.359C206.77 256.659 207.06 256.929 207.54 256.929C207.767 256.964 208 256.92 208.199 256.803C208.397 256.687 208.549 256.505 208.63 256.289H208.59Z"
        fill="#263238"
      />
      <path
        d="M217.62 255.23V257.57H216.62V255.35C216.62 254.63 216.29 254.28 215.71 254.28C215.13 254.28 214.64 254.69 214.64 255.5V257.57H213.69V255.35C213.69 254.63 213.36 254.28 212.78 254.28C212.2 254.28 211.71 254.69 211.71 255.5V257.57H210.76V253.49H211.67V254C211.835 253.813 212.04 253.665 212.271 253.568C212.501 253.471 212.75 253.427 213 253.44C213.269 253.421 213.539 253.474 213.781 253.594C214.024 253.713 214.23 253.895 214.38 254.12C214.564 253.898 214.797 253.721 215.061 253.604C215.324 253.486 215.611 253.43 215.9 253.44C216.138 253.412 216.38 253.44 216.606 253.522C216.832 253.603 217.037 253.736 217.203 253.909C217.37 254.082 217.494 254.291 217.566 254.521C217.638 254.75 217.657 254.993 217.62 255.23Z"
        fill="#263238"
      />
      <path
        d="M222.7 255.83H219.51C219.566 256.131 219.732 256.4 219.976 256.585C220.22 256.77 220.525 256.857 220.83 256.83C221.034 256.838 221.237 256.803 221.427 256.727C221.616 256.652 221.788 256.537 221.93 256.39L222.45 256.98C222.24 257.204 221.983 257.38 221.698 257.494C221.413 257.608 221.106 257.658 220.8 257.64C220.511 257.665 220.221 257.63 219.947 257.536C219.674 257.442 219.423 257.291 219.211 257.094C218.999 256.897 218.83 256.658 218.716 256.392C218.603 256.126 218.546 255.839 218.55 255.55C218.538 255.271 218.585 254.993 218.687 254.733C218.79 254.474 218.945 254.238 219.144 254.042C219.343 253.847 219.58 253.694 219.841 253.596C220.102 253.497 220.381 253.454 220.66 253.47C220.936 253.461 221.211 253.51 221.467 253.613C221.724 253.716 221.956 253.871 222.15 254.068C222.343 254.266 222.494 254.501 222.592 254.759C222.69 255.018 222.734 255.294 222.72 255.57C222.72 255.63 222.71 255.74 222.7 255.83ZM219.5 255.2H221.81C221.784 254.917 221.65 254.655 221.436 254.469C221.221 254.282 220.943 254.186 220.66 254.2C220.376 254.191 220.099 254.288 219.884 254.473C219.669 254.659 219.532 254.918 219.5 255.2Z"
        fill="#263238"
      />
      <path
        d="M223.319 257C223.319 256.875 223.357 256.753 223.426 256.649C223.496 256.545 223.595 256.464 223.711 256.417C223.826 256.37 223.954 256.358 224.076 256.383C224.199 256.408 224.311 256.469 224.398 256.558C224.486 256.647 224.545 256.761 224.569 256.883C224.592 257.006 224.578 257.133 224.529 257.248C224.479 257.363 224.397 257.461 224.292 257.529C224.187 257.597 224.064 257.632 223.939 257.63C223.857 257.63 223.776 257.614 223.7 257.582C223.624 257.55 223.555 257.504 223.497 257.445C223.44 257.386 223.394 257.317 223.364 257.24C223.333 257.164 223.318 257.082 223.319 257Z"
        fill="#263238"
      />
      <path
        d="M229.83 255.53C229.843 255.806 229.798 256.082 229.699 256.34C229.599 256.598 229.448 256.832 229.253 257.029C229.059 257.225 228.825 257.379 228.568 257.481C228.312 257.583 228.036 257.63 227.76 257.62C227.515 257.628 227.271 257.583 227.045 257.488C226.819 257.394 226.616 257.251 226.45 257.07V259.07H225.5V253.51H226.41V254C226.576 253.807 226.784 253.654 227.018 253.554C227.252 253.453 227.506 253.407 227.76 253.42C228.038 253.41 228.315 253.458 228.573 253.561C228.832 253.664 229.066 253.82 229.261 254.018C229.456 254.217 229.607 254.454 229.705 254.714C229.803 254.974 229.845 255.252 229.83 255.53ZM228.83 255.53C228.818 255.294 228.737 255.066 228.598 254.875C228.458 254.684 228.265 254.538 228.043 254.456C227.821 254.373 227.58 254.358 227.349 254.411C227.119 254.464 226.909 254.584 226.745 254.755C226.582 254.927 226.473 255.142 226.431 255.375C226.389 255.608 226.416 255.848 226.509 256.066C226.602 256.284 226.757 256.469 226.954 256.6C227.152 256.73 227.383 256.8 227.62 256.8C227.787 256.807 227.954 256.779 228.11 256.718C228.266 256.656 228.407 256.562 228.524 256.443C228.641 256.323 228.732 256.179 228.79 256.022C228.847 255.865 228.871 255.697 228.86 255.53H228.83Z"
        fill="#263238"
      />
      <path
        d="M234.75 255.23V257.57H233.8V255.35C233.8 254.63 233.44 254.28 232.8 254.28C232.64 254.269 232.479 254.295 232.33 254.354C232.18 254.413 232.046 254.505 231.937 254.623C231.828 254.741 231.746 254.882 231.698 255.035C231.65 255.189 231.637 255.351 231.66 255.51V257.57H230.71V253.49H231.62V254C231.796 253.806 232.013 253.654 232.255 253.556C232.498 253.457 232.759 253.414 233.02 253.43C233.26 253.404 233.502 253.434 233.728 253.517C233.954 253.599 234.159 253.732 234.326 253.906C234.493 254.08 234.618 254.289 234.691 254.519C234.765 254.748 234.785 254.991 234.75 255.23Z"
        fill="#263238"
      />
      <path
        d="M240.049 253.49V257C240.089 257.296 240.059 257.597 239.961 257.879C239.864 258.162 239.702 258.417 239.488 258.626C239.275 258.835 239.015 258.991 238.731 259.082C238.446 259.173 238.145 259.196 237.849 259.15C237.137 259.181 236.434 258.977 235.849 258.57L236.279 257.85C236.712 258.174 237.239 258.346 237.779 258.34C238.679 258.34 239.079 257.93 239.079 257.11V256.89C238.902 257.072 238.689 257.214 238.453 257.307C238.216 257.4 237.963 257.442 237.709 257.43C237.42 257.485 237.123 257.475 236.838 257.402C236.553 257.328 236.288 257.193 236.061 257.005C235.835 256.817 235.652 256.582 235.527 256.316C235.402 256.05 235.337 255.759 235.337 255.465C235.337 255.171 235.402 254.88 235.527 254.614C235.652 254.348 235.835 254.112 236.061 253.925C236.288 253.737 236.553 253.602 236.838 253.528C237.123 253.455 237.42 253.445 237.709 253.5C237.973 253.485 238.236 253.529 238.48 253.629C238.725 253.73 238.943 253.884 239.119 254.08V253.54L240.049 253.49ZM239.109 255.41C239.125 255.161 239.066 254.914 238.94 254.699C238.813 254.485 238.625 254.314 238.4 254.208C238.174 254.102 237.922 254.066 237.677 254.105C237.431 254.145 237.202 254.257 237.021 254.428C236.84 254.599 236.715 254.82 236.661 255.063C236.607 255.306 236.628 255.56 236.721 255.791C236.814 256.022 236.974 256.22 237.18 256.359C237.387 256.498 237.63 256.571 237.879 256.57C238.038 256.581 238.197 256.559 238.346 256.506C238.496 256.452 238.633 256.368 238.749 256.259C238.864 256.15 238.956 256.019 239.018 255.872C239.08 255.726 239.111 255.569 239.109 255.41Z"
        fill="#263238"
      />
      <path
        d="M188.399 261.81H188.829V265.52H191.109V265.89H188.399V261.81Z"
        fill="#E0E0E0"
      />
      <path
        d="M191.38 264.36C191.38 264.051 191.471 263.75 191.643 263.493C191.814 263.237 192.058 263.037 192.343 262.919C192.628 262.8 192.941 262.77 193.244 262.83C193.547 262.89 193.825 263.039 194.043 263.257C194.261 263.475 194.41 263.753 194.47 264.055C194.53 264.358 194.499 264.672 194.381 264.957C194.263 265.242 194.063 265.485 193.806 265.657C193.55 265.828 193.248 265.92 192.94 265.92C192.733 265.927 192.527 265.891 192.335 265.815C192.142 265.739 191.967 265.625 191.821 265.478C191.675 265.332 191.56 265.157 191.484 264.965C191.408 264.772 191.373 264.567 191.38 264.36ZM194.08 264.36C194.08 264.057 193.96 263.767 193.746 263.554C193.532 263.34 193.242 263.22 192.94 263.22C192.637 263.22 192.347 263.34 192.134 263.554C191.92 263.767 191.8 264.057 191.8 264.36C191.8 264.662 191.92 264.952 192.134 265.166C192.347 265.38 192.637 265.5 192.94 265.5C193.242 265.5 193.532 265.38 193.746 265.166C193.96 264.952 194.08 264.662 194.08 264.36Z"
        fill="#E0E0E0"
      />
      <path
        d="M197.639 263.999V265.899H197.239V265.429C197.125 265.598 196.967 265.734 196.782 265.822C196.598 265.91 196.393 265.947 196.189 265.929C195.489 265.929 195.069 265.569 195.069 265.039C195.069 264.509 195.369 264.169 196.249 264.169H197.249V263.999C197.249 263.469 196.959 263.189 196.389 263.189C196.024 263.189 195.67 263.316 195.389 263.549L195.199 263.239C195.558 262.966 195.998 262.821 196.449 262.829C196.608 262.812 196.768 262.831 196.919 262.883C197.07 262.936 197.207 263.021 197.321 263.133C197.435 263.245 197.522 263.381 197.577 263.53C197.632 263.68 197.654 263.84 197.639 263.999ZM197.219 264.999V264.499H196.219C195.629 264.499 195.439 264.729 195.439 265.049C195.439 265.369 195.719 265.629 196.209 265.629C196.423 265.646 196.636 265.594 196.818 265.481C197 265.368 197.141 265.199 197.219 264.999Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.57 261.569V265.889H201.17V265.289C201.047 265.49 200.873 265.654 200.666 265.765C200.458 265.876 200.225 265.929 199.99 265.919C199.599 265.888 199.234 265.711 198.968 265.422C198.702 265.134 198.555 264.756 198.555 264.364C198.555 263.972 198.702 263.594 198.968 263.306C199.234 263.018 199.599 262.841 199.99 262.809C200.22 262.8 200.449 262.851 200.654 262.956C200.858 263.061 201.033 263.217 201.16 263.409V261.569H201.57ZM201.16 264.359C201.148 264.137 201.071 263.923 200.939 263.743C200.807 263.564 200.625 263.427 200.416 263.35C200.207 263.273 199.98 263.258 199.763 263.309C199.546 263.36 199.348 263.473 199.195 263.634C199.041 263.796 198.939 263.999 198.899 264.218C198.86 264.437 198.886 264.663 198.974 264.868C199.061 265.073 199.207 265.248 199.393 265.371C199.579 265.493 199.797 265.559 200.02 265.559C200.176 265.563 200.33 265.533 200.474 265.473C200.618 265.413 200.747 265.324 200.855 265.211C200.962 265.098 201.045 264.964 201.097 264.817C201.15 264.671 201.171 264.515 201.16 264.359Z"
        fill="#E0E0E0"
      />
      <path
        d="M202.63 261.86C202.63 261.78 202.661 261.704 202.718 261.647C202.774 261.591 202.85 261.56 202.93 261.56C203.009 261.56 203.086 261.591 203.142 261.647C203.198 261.704 203.23 261.78 203.23 261.86C203.23 261.899 203.222 261.938 203.207 261.974C203.192 262.011 203.17 262.044 203.142 262.072C203.114 262.1 203.081 262.122 203.045 262.137C203.008 262.152 202.969 262.16 202.93 262.16C202.89 262.16 202.851 262.152 202.815 262.137C202.779 262.122 202.746 262.1 202.718 262.072C202.69 262.044 202.668 262.011 202.653 261.974C202.638 261.938 202.63 261.899 202.63 261.86ZM202.72 262.86H203.13V265.92H202.72V262.86Z"
        fill="#E0E0E0"
      />
      <path
        d="M207.109 264.12V265.89H206.689V264.16C206.711 264.03 206.703 263.898 206.666 263.772C206.628 263.646 206.563 263.531 206.474 263.434C206.385 263.338 206.276 263.263 206.154 263.215C206.031 263.168 205.9 263.149 205.769 263.16C205.622 263.148 205.475 263.17 205.337 263.222C205.2 263.274 205.075 263.357 204.973 263.463C204.871 263.569 204.793 263.696 204.746 263.836C204.699 263.975 204.683 264.123 204.699 264.27V265.87H204.279V262.81H204.679V263.37C204.806 263.178 204.981 263.024 205.186 262.922C205.392 262.82 205.62 262.774 205.849 262.79C206.024 262.776 206.2 262.802 206.364 262.865C206.528 262.928 206.675 263.027 206.796 263.155C206.917 263.282 207.007 263.435 207.062 263.602C207.116 263.769 207.132 263.946 207.109 264.12Z"
        fill="#E0E0E0"
      />
      <path
        d="M211.08 262.83V265.52C211.113 265.729 211.095 265.943 211.029 266.145C210.963 266.346 210.851 266.529 210.7 266.678C210.55 266.828 210.366 266.939 210.165 267.004C209.963 267.068 209.749 267.084 209.54 267.05C209.024 267.075 208.518 266.907 208.12 266.58L208.33 266.27C208.666 266.55 209.092 266.699 209.53 266.69C210.31 266.69 210.66 266.33 210.66 265.57V265.18C210.529 265.373 210.351 265.53 210.143 265.635C209.934 265.74 209.703 265.79 209.47 265.78C209.072 265.78 208.69 265.622 208.409 265.341C208.128 265.059 207.97 264.678 207.97 264.28C207.97 263.882 208.128 263.501 208.409 263.219C208.69 262.938 209.072 262.78 209.47 262.78C209.707 262.768 209.943 262.818 210.155 262.925C210.368 263.032 210.548 263.192 210.68 263.39V262.8L211.08 262.83ZM210.68 264.29C210.69 264.057 210.63 263.826 210.507 263.627C210.385 263.428 210.206 263.27 209.993 263.174C209.78 263.078 209.544 263.047 209.314 263.087C209.084 263.126 208.87 263.233 208.702 263.395C208.533 263.556 208.417 263.765 208.367 263.993C208.318 264.221 208.338 264.459 208.425 264.675C208.512 264.892 208.661 265.078 208.855 265.209C209.048 265.34 209.276 265.41 209.51 265.41C209.662 265.42 209.814 265.398 209.957 265.346C210.1 265.293 210.231 265.212 210.341 265.107C210.451 265.002 210.538 264.875 210.596 264.734C210.654 264.593 210.683 264.442 210.68 264.29Z"
        fill="#E0E0E0"
      />
      <path
        d="M213.489 265.6C213.489 265.518 213.522 265.439 213.58 265.381C213.638 265.323 213.717 265.29 213.799 265.29C213.881 265.29 213.96 265.323 214.018 265.381C214.077 265.439 214.109 265.518 214.109 265.6C214.109 265.682 214.077 265.761 214.018 265.819C213.96 265.877 213.881 265.91 213.799 265.91C213.717 265.91 213.638 265.877 213.58 265.819C213.522 265.761 213.489 265.682 213.489 265.6Z"
        fill="#E0E0E0"
      />
      <path
        d="M214.72 265.599C214.72 265.517 214.753 265.438 214.811 265.38C214.869 265.322 214.948 265.289 215.03 265.289C215.071 265.288 215.113 265.295 215.152 265.31C215.19 265.325 215.226 265.347 215.256 265.376C215.285 265.405 215.309 265.44 215.325 265.478C215.342 265.516 215.35 265.558 215.35 265.599C215.35 265.684 215.316 265.765 215.256 265.825C215.196 265.885 215.115 265.919 215.03 265.919C214.988 265.919 214.947 265.911 214.909 265.895C214.871 265.878 214.836 265.855 214.807 265.825C214.778 265.795 214.756 265.76 214.741 265.721C214.726 265.682 214.719 265.641 214.72 265.599Z"
        fill="#E0E0E0"
      />
      <path
        d="M216 265.6C216 265.518 216.033 265.439 216.091 265.381C216.149 265.323 216.228 265.29 216.31 265.29C216.392 265.29 216.471 265.323 216.529 265.381C216.587 265.439 216.62 265.518 216.62 265.6C216.62 265.682 216.587 265.761 216.529 265.819C216.471 265.877 216.392 265.91 216.31 265.91C216.228 265.91 216.149 265.877 216.091 265.819C216.033 265.761 216 265.682 216 265.6Z"
        fill="#E0E0E0"
      />
      <path
        d="M365.8 272.549H187.91C187.692 272.549 187.476 272.507 187.275 272.423C187.073 272.34 186.89 272.217 186.736 272.063C186.582 271.909 186.46 271.726 186.376 271.525C186.293 271.323 186.25 271.107 186.25 270.889C186.25 270.449 186.425 270.027 186.736 269.716C187.048 269.404 187.47 269.229 187.91 269.229H365.8C366.018 269.229 366.234 269.272 366.435 269.356C366.637 269.439 366.82 269.562 366.974 269.716C367.128 269.87 367.25 270.053 367.334 270.254C367.417 270.456 367.46 270.672 367.46 270.889C367.46 271.107 367.417 271.323 367.334 271.525C367.25 271.726 367.128 271.909 366.974 272.063C366.82 272.217 366.637 272.34 366.435 272.423C366.234 272.507 366.018 272.549 365.8 272.549Z"
        fill="#E0E0E0"
      />
      <path
        d="M227.36 272.549H187.91C187.692 272.549 187.476 272.507 187.275 272.423C187.073 272.34 186.89 272.217 186.736 272.063C186.582 271.909 186.46 271.726 186.376 271.525C186.293 271.323 186.25 271.107 186.25 270.889C186.25 270.449 186.425 270.027 186.736 269.716C187.048 269.404 187.47 269.229 187.91 269.229H227.36C227.8 269.229 228.222 269.404 228.534 269.716C228.845 270.027 229.02 270.449 229.02 270.889C229.02 271.107 228.977 271.323 228.894 271.525C228.81 271.726 228.688 271.909 228.534 272.063C228.38 272.217 228.197 272.34 227.995 272.423C227.794 272.507 227.578 272.549 227.36 272.549Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M376.67 267.909C375.185 269.874 373.435 271.624 371.47 273.109C372.14 272.075 372.951 271.139 373.88 270.329C374.69 269.394 375.629 268.579 376.67 267.909Z"
        fill="#263238"
      />
      <path
        d="M376.55 273.109C374.585 271.624 372.835 269.874 371.35 267.909C373.315 269.394 375.065 271.144 376.55 273.109Z"
        fill="#263238"
      />
      <path
        d="M351.179 264.72C351.179 265.41 350.679 265.93 349.699 265.93C349.161 265.948 348.636 265.762 348.229 265.41L348.429 265.07C348.775 265.387 349.231 265.555 349.699 265.54C350.379 265.54 350.749 265.23 350.749 264.72C350.749 264.21 350.399 263.91 349.649 263.91H349.359V263.6L350.489 262.19H348.399V261.81H350.999V262.11L349.849 263.56C350.739 263.61 351.179 264.07 351.179 264.72Z"
        fill="#E0E0E0"
      />
      <path
        d="M354.52 265.52V265.89H351.66V265.6L353.36 263.94C353.82 263.48 353.9 263.21 353.9 262.94C353.9 262.46 353.57 262.17 352.95 262.17C352.742 262.156 352.534 262.191 352.341 262.271C352.149 262.35 351.977 262.473 351.84 262.63L351.54 262.37C351.723 262.174 351.947 262.02 352.195 261.92C352.444 261.819 352.712 261.775 352.98 261.79C353.81 261.79 354.34 262.21 354.34 262.89C354.328 263.139 354.262 263.383 354.146 263.604C354.03 263.825 353.868 264.018 353.67 264.17L352.28 265.53L354.52 265.52Z"
        fill="#E0E0E0"
      />
      <path
        d="M361.169 261.81V265.89H360.749V262.63L359.149 265.38H358.999L357.389 262.65V265.89H356.979V261.81H357.339L359.109 264.81L360.859 261.81H361.169Z"
        fill="#E0E0E0"
      />
      <path
        d="M365.899 264.8C365.899 265.5 365.389 265.89 364.369 265.89H362.489V261.81H364.249C365.159 261.81 365.679 262.2 365.679 262.86C365.68 263.059 365.622 263.254 365.511 263.419C365.401 263.585 365.243 263.714 365.059 263.79C365.298 263.828 365.514 263.952 365.668 264.137C365.823 264.323 365.905 264.558 365.899 264.8ZM362.899 262.17V263.65H364.199C364.849 263.65 365.199 263.4 365.199 262.91C365.199 262.42 364.819 262.17 364.199 262.17H362.899ZM365.449 264.77C365.449 264.24 365.059 264.01 364.339 264.01H362.899V265.54H364.339C365.079 265.54 365.469 265.31 365.469 264.77H365.449Z"
        fill="#E0E0E0"
      />
      <path
        d="M174.86 315.34H159.4C158.887 315.34 158.395 315.137 158.032 314.775C157.668 314.413 157.463 313.922 157.46 313.41V292C157.46 291.485 157.664 290.992 158.028 290.628C158.392 290.264 158.885 290.06 159.4 290.06H169.2L176.8 297.89V313.41C176.797 313.922 176.592 314.413 176.228 314.775C175.865 315.137 175.373 315.34 174.86 315.34Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M169.199 290.06V296.06C169.202 296.536 169.392 296.992 169.729 297.329C170.066 297.666 170.523 297.857 170.999 297.86H176.799L169.199 290.06Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <g opacity="0.2">
        <path
          d="M169.279 296.62C169.283 297.076 169.443 297.517 169.732 297.87C170.022 298.223 170.423 298.467 170.869 298.56H171.059C171.819 298.7 176.799 299.56 176.799 299.47V297.85H170.999C170.999 297.85 169.559 297.84 169.279 296.62Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M169.199 290.06V296.06C169.202 296.536 169.392 296.992 169.729 297.329C170.066 297.666 170.523 297.857 170.999 297.86H176.799L169.199 290.06Z"
          fill="white"
        />
      </g>
      <path
        d="M167.91 295.84C166.722 295.981 165.525 296.028 164.33 295.98C163.135 296.028 161.938 295.981 160.75 295.84C161.938 295.699 163.135 295.652 164.33 295.7C165.525 295.652 166.722 295.699 167.91 295.84Z"
        fill="#FAFAFA"
      />
      <path
        d="M172.53 299.249C172.53 299.329 169.89 299.389 166.64 299.389C163.39 299.389 160.75 299.329 160.75 299.249C160.75 299.169 163.39 299.109 166.64 299.109C169.89 299.109 172.53 299.169 172.53 299.249Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 302.089C173.59 302.169 170.72 302.229 167.17 302.229C163.62 302.229 160.75 302.169 160.75 302.089C160.75 302.009 163.63 301.949 167.17 301.949C170.71 301.949 173.59 301.999 173.59 302.089Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 304.78C173.59 304.85 170.72 304.92 167.17 304.92C163.62 304.92 160.75 304.85 160.75 304.78C160.75 304.71 163.63 304.64 167.17 304.64C170.71 304.64 173.59 304.7 173.59 304.78Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 307.459C173.59 307.539 170.72 307.599 167.17 307.599C163.62 307.599 160.75 307.539 160.75 307.459C160.75 307.379 163.63 307.319 167.17 307.319C170.71 307.319 173.59 307.379 173.59 307.459Z"
        fill="#FAFAFA"
      />
      <path
        d="M173.59 310.14C173.59 310.22 170.72 310.28 167.17 310.28C163.62 310.28 160.75 310.22 160.75 310.14C160.75 310.06 163.63 310 167.17 310C170.71 310 173.59 310.07 173.59 310.14Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.939 292.74C190.246 292.726 190.549 292.806 190.809 292.97L190.539 293.68C190.381 293.578 190.198 293.522 190.009 293.52C189.619 293.52 189.409 293.74 189.409 294.17V294.42H190.529V295.18H189.439V298.46H188.489V295.18H187.819V294.42H188.489V294.16C188.474 293.967 188.501 293.773 188.568 293.591C188.636 293.41 188.743 293.245 188.881 293.11C189.02 292.974 189.186 292.871 189.369 292.807C189.552 292.743 189.747 292.72 189.939 292.74ZM191.649 293.14C191.651 293.062 191.667 292.985 191.699 292.914C191.731 292.843 191.776 292.778 191.833 292.725C191.89 292.672 191.957 292.631 192.03 292.604C192.104 292.577 192.182 292.566 192.259 292.57C192.336 292.564 192.413 292.574 192.485 292.599C192.558 292.624 192.624 292.664 192.681 292.715C192.738 292.766 192.784 292.829 192.817 292.898C192.849 292.968 192.867 293.043 192.869 293.12C192.869 293.282 192.805 293.437 192.691 293.551C192.576 293.666 192.421 293.73 192.259 293.73C192.098 293.73 191.942 293.666 191.828 293.551C191.714 293.437 191.649 293.282 191.649 293.12V293.14ZM191.779 294.38H192.779V298.46H191.779V294.38Z"
        fill="#263238"
      />
      <path d="M194 292.8H195V298.46H194V292.8Z" fill="#263238" />
      <path
        d="M199.999 296.729H196.809C196.865 297.03 197.032 297.3 197.276 297.485C197.52 297.67 197.824 297.757 198.129 297.729C198.333 297.738 198.537 297.703 198.726 297.627C198.916 297.551 199.087 297.436 199.229 297.289L199.739 297.879C199.43 298.185 199.04 298.396 198.615 298.488C198.191 298.58 197.748 298.549 197.341 298.399C196.933 298.249 196.576 297.986 196.312 297.641C196.048 297.296 195.887 296.882 195.849 296.449C195.838 296.171 195.885 295.892 195.987 295.633C196.089 295.373 196.245 295.138 196.444 294.942C196.642 294.746 196.88 294.594 197.141 294.495C197.402 294.397 197.681 294.354 197.959 294.369C198.237 294.361 198.512 294.41 198.77 294.513C199.027 294.617 199.26 294.773 199.454 294.971C199.647 295.17 199.798 295.406 199.895 295.666C199.992 295.925 200.035 296.202 200.019 296.479C199.999 296.519 199.999 296.639 199.999 296.729ZM196.799 296.089H199.109C199.065 295.815 198.924 295.565 198.713 295.385C198.501 295.205 198.232 295.106 197.954 295.106C197.676 295.106 197.408 295.205 197.196 295.385C196.984 295.565 196.844 295.815 196.799 296.089Z"
        fill="#263238"
      />
      <path
        d="M204.999 296.12V298.46H203.999V296.25C203.999 295.52 203.639 295.17 202.999 295.17C202.839 295.159 202.678 295.185 202.529 295.244C202.38 295.303 202.246 295.395 202.136 295.513C202.027 295.631 201.946 295.772 201.898 295.925C201.85 296.079 201.837 296.241 201.859 296.4V298.46H200.859V294.38H201.769V294.91C201.946 294.717 202.163 294.565 202.405 294.467C202.647 294.368 202.908 294.325 203.169 294.34C203.414 294.3 203.665 294.318 203.902 294.393C204.138 294.468 204.354 294.597 204.532 294.77C204.71 294.943 204.845 295.155 204.926 295.389C205.007 295.624 205.032 295.874 204.999 296.12Z"
        fill="#263238"
      />
      <path
        d="M209.59 296.07V298.46H208.69V298C208.54 298.192 208.344 298.344 208.121 298.44C207.897 298.536 207.652 298.574 207.41 298.55C206.47 298.55 205.89 298.04 205.89 297.33C205.89 296.62 206.34 296.12 207.58 296.12H208.58V296C208.58 295.44 208.25 295.11 207.58 295.11C207.132 295.104 206.695 295.248 206.34 295.52L205.97 294.82C206.476 294.475 207.078 294.3 207.69 294.32C208.91 294.34 209.59 294.9 209.59 296.07ZM208.59 297.18V296.71H207.59C206.94 296.71 206.77 296.96 206.77 297.25C206.77 297.54 207.06 297.82 207.54 297.82C207.767 297.855 208 297.811 208.199 297.694C208.397 297.577 208.549 297.396 208.63 297.18H208.59Z"
        fill="#263238"
      />
      <path
        d="M217.62 296.119V298.459H216.62V296.249C216.62 295.519 216.29 295.169 215.71 295.169C215.13 295.169 214.64 295.579 214.64 296.389V298.459H213.69V296.249C213.69 295.519 213.36 295.169 212.78 295.169C212.2 295.169 211.71 295.579 211.71 296.389V298.459H210.76V294.379H211.67V294.899C211.837 294.714 212.043 294.568 212.272 294.471C212.502 294.374 212.751 294.329 213 294.339C213.269 294.321 213.539 294.374 213.781 294.493C214.024 294.613 214.23 294.794 214.38 295.019C214.564 294.797 214.797 294.621 215.061 294.503C215.324 294.385 215.611 294.329 215.9 294.339C216.137 294.312 216.378 294.34 216.604 294.421C216.829 294.501 217.032 294.633 217.199 294.805C217.365 294.977 217.489 295.185 217.562 295.413C217.635 295.641 217.655 295.883 217.62 296.119Z"
        fill="#263238"
      />
      <path
        d="M222.7 296.729H219.51C219.566 297.03 219.732 297.3 219.976 297.485C220.22 297.67 220.525 297.757 220.83 297.729C221.034 297.738 221.237 297.703 221.427 297.627C221.616 297.551 221.788 297.436 221.93 297.289L222.45 297.879C222.24 298.104 221.983 298.279 221.698 298.393C221.413 298.508 221.106 298.557 220.8 298.539C220.511 298.565 220.221 298.529 219.947 298.435C219.674 298.341 219.423 298.191 219.211 297.994C218.999 297.797 218.83 297.558 218.716 297.292C218.603 297.026 218.546 296.739 218.55 296.449C218.538 296.171 218.585 295.892 218.687 295.633C218.79 295.373 218.945 295.138 219.144 294.942C219.343 294.746 219.58 294.594 219.841 294.495C220.102 294.397 220.381 294.354 220.66 294.369C220.937 294.361 221.213 294.41 221.47 294.513C221.727 294.617 221.96 294.773 222.154 294.971C222.347 295.17 222.498 295.406 222.595 295.666C222.693 295.925 222.735 296.202 222.72 296.479C222.72 296.519 222.71 296.639 222.7 296.729ZM219.5 296.089H221.81C221.782 295.807 221.647 295.547 221.433 295.361C221.219 295.175 220.943 295.078 220.66 295.089C220.376 295.08 220.099 295.178 219.884 295.363C219.669 295.548 219.532 295.807 219.5 296.089Z"
        fill="#263238"
      />
      <path
        d="M223.319 297.88C223.308 297.791 223.316 297.701 223.342 297.616C223.369 297.53 223.413 297.451 223.472 297.384C223.531 297.317 223.604 297.264 223.685 297.227C223.767 297.19 223.855 297.171 223.944 297.171C224.034 297.171 224.122 297.19 224.204 297.227C224.285 297.264 224.358 297.317 224.417 297.384C224.476 297.451 224.52 297.53 224.547 297.616C224.573 297.701 224.581 297.791 224.569 297.88C224.581 297.969 224.573 298.059 224.547 298.144C224.52 298.23 224.476 298.309 224.417 298.376C224.358 298.443 224.285 298.497 224.204 298.534C224.122 298.57 224.034 298.589 223.944 298.589C223.855 298.589 223.767 298.57 223.685 298.534C223.604 298.497 223.531 298.443 223.472 298.376C223.413 298.309 223.369 298.23 223.342 298.144C223.316 298.059 223.308 297.969 223.319 297.88Z"
        fill="#263238"
      />
      <path
        d="M229.83 296.42C229.843 296.696 229.798 296.971 229.699 297.229C229.599 297.487 229.448 297.722 229.253 297.918C229.059 298.115 228.825 298.269 228.568 298.37C228.312 298.472 228.036 298.519 227.76 298.51C227.515 298.52 227.27 298.476 227.044 298.381C226.817 298.286 226.614 298.142 226.45 297.959V299.959H225.5V294.399H226.41V294.94C226.576 294.746 226.784 294.594 227.018 294.493C227.252 294.393 227.506 294.347 227.76 294.359C228.034 294.35 228.306 294.396 228.561 294.496C228.816 294.596 229.048 294.747 229.242 294.941C229.436 295.134 229.588 295.365 229.689 295.619C229.791 295.873 229.838 296.146 229.83 296.42ZM228.83 296.42C228.83 296.099 228.703 295.791 228.476 295.564C228.249 295.337 227.941 295.209 227.62 295.209C227.299 295.209 226.991 295.337 226.764 295.564C226.537 295.791 226.41 296.099 226.41 296.42C226.41 296.74 226.537 297.048 226.764 297.275C226.991 297.502 227.299 297.63 227.62 297.63C227.941 297.63 228.249 297.502 228.476 297.275C228.703 297.048 228.83 296.74 228.83 296.42Z"
        fill="#263238"
      />
      <path
        d="M234.729 292.8V298.46H233.809V297.93C233.643 298.123 233.435 298.276 233.201 298.376C232.967 298.477 232.714 298.522 232.459 298.51C231.931 298.473 231.437 298.238 231.076 297.851C230.714 297.464 230.514 296.954 230.514 296.425C230.514 295.896 230.714 295.386 231.076 294.999C231.437 294.612 231.931 294.376 232.459 294.34C232.706 294.327 232.953 294.37 233.181 294.465C233.41 294.561 233.614 294.706 233.779 294.89V292.8H234.729ZM233.789 296.42C233.777 296.181 233.696 295.951 233.554 295.758C233.413 295.565 233.218 295.418 232.994 295.336C232.769 295.253 232.526 295.237 232.293 295.292C232.06 295.346 231.848 295.468 231.684 295.642C231.52 295.816 231.41 296.034 231.369 296.269C231.328 296.505 231.357 296.747 231.452 296.966C231.547 297.186 231.705 297.372 231.905 297.502C232.106 297.633 232.34 297.701 232.579 297.7C232.745 297.703 232.91 297.672 233.063 297.608C233.216 297.543 233.353 297.448 233.467 297.328C233.581 297.207 233.669 297.064 233.724 296.908C233.78 296.751 233.802 296.585 233.789 296.42Z"
        fill="#263238"
      />
      <path
        d="M236.999 294.17V294.42H238.119V295.18H237.029V298.46H236.029V295.18H235.359V294.42H236.029V294.16C236.015 293.967 236.044 293.772 236.113 293.591C236.182 293.409 236.289 293.245 236.429 293.11C236.568 292.975 236.735 292.871 236.918 292.808C237.101 292.744 237.296 292.721 237.489 292.74C237.793 292.725 238.094 292.805 238.349 292.97L238.089 293.68C237.931 293.578 237.748 293.523 237.559 293.52C237.239 293.52 236.999 293.74 236.999 294.17Z"
        fill="#263238"
      />
      <path
        d="M188.399 302.71H188.829V306.41H191.109V306.79H188.399V302.71Z"
        fill="#E0E0E0"
      />
      <path
        d="M191.38 305.249C191.382 304.941 191.475 304.64 191.647 304.385C191.82 304.13 192.064 303.932 192.35 303.815C192.635 303.699 192.948 303.669 193.25 303.73C193.552 303.792 193.829 303.941 194.046 304.16C194.263 304.378 194.411 304.656 194.47 304.959C194.53 305.261 194.498 305.574 194.38 305.859C194.262 306.143 194.062 306.386 193.805 306.557C193.549 306.728 193.248 306.819 192.94 306.819C192.733 306.825 192.526 306.788 192.334 306.711C192.141 306.634 191.967 306.518 191.821 306.371C191.675 306.224 191.56 306.049 191.484 305.856C191.408 305.663 191.373 305.456 191.38 305.249ZM194.08 305.249C194.068 305.027 193.991 304.812 193.859 304.633C193.727 304.454 193.545 304.317 193.336 304.24C193.127 304.163 192.9 304.148 192.683 304.199C192.466 304.249 192.268 304.363 192.115 304.524C191.961 304.686 191.859 304.889 191.819 305.108C191.78 305.327 191.806 305.553 191.893 305.758C191.981 305.963 192.127 306.138 192.313 306.26C192.499 306.383 192.717 306.449 192.94 306.449C193.095 306.452 193.25 306.423 193.394 306.363C193.538 306.303 193.667 306.214 193.775 306.101C193.882 305.988 193.964 305.854 194.017 305.707C194.07 305.561 194.091 305.405 194.08 305.249Z"
        fill="#E0E0E0"
      />
      <path
        d="M197.639 304.889V306.789H197.239V306.309C197.126 306.48 196.968 306.618 196.784 306.708C196.599 306.797 196.394 306.836 196.189 306.819C195.489 306.819 195.069 306.449 195.069 305.919C195.069 305.389 195.369 305.059 196.249 305.059H197.249V304.869C197.249 304.339 196.959 304.059 196.389 304.059C196.024 304.059 195.67 304.186 195.389 304.419L195.199 304.109C195.558 303.836 195.998 303.691 196.449 303.699C196.609 303.684 196.771 303.704 196.922 303.758C197.074 303.813 197.211 303.9 197.325 304.014C197.439 304.127 197.526 304.265 197.58 304.416C197.635 304.568 197.655 304.729 197.639 304.889ZM197.219 305.889V305.389H196.219C195.629 305.389 195.439 305.619 195.439 305.929C195.439 306.239 195.719 306.509 196.209 306.509C196.424 306.526 196.639 306.472 196.821 306.357C197.003 306.242 197.142 306.071 197.219 305.869V305.889Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.57 302.46V306.79H201.17V306.18C201.047 306.382 200.874 306.547 200.666 306.66C200.459 306.772 200.225 306.828 199.99 306.82C199.576 306.82 199.179 306.656 198.887 306.363C198.594 306.071 198.43 305.674 198.43 305.26C198.43 304.846 198.594 304.449 198.887 304.157C199.179 303.864 199.576 303.7 199.99 303.7C200.22 303.693 200.447 303.744 200.652 303.849C200.857 303.954 201.031 304.109 201.16 304.3V302.46H201.57ZM201.16 305.25C201.148 305.027 201.071 304.813 200.939 304.634C200.807 304.454 200.625 304.318 200.416 304.24C200.207 304.163 199.98 304.149 199.763 304.2C199.546 304.25 199.348 304.363 199.195 304.525C199.041 304.686 198.939 304.889 198.899 305.109C198.86 305.328 198.886 305.554 198.973 305.759C199.061 305.964 199.207 306.138 199.393 306.261C199.579 306.384 199.797 306.45 200.02 306.45C200.175 306.453 200.33 306.424 200.474 306.364C200.618 306.304 200.747 306.215 200.855 306.102C200.962 305.989 201.044 305.855 201.097 305.708C201.149 305.561 201.171 305.405 201.16 305.25Z"
        fill="#E0E0E0"
      />
      <path
        d="M202.63 302.75C202.633 302.672 202.665 302.598 202.721 302.544C202.777 302.49 202.852 302.46 202.93 302.46C202.99 302.46 203.048 302.478 203.098 302.511C203.147 302.545 203.186 302.592 203.208 302.648C203.23 302.703 203.236 302.764 203.223 302.822C203.211 302.881 203.181 302.934 203.138 302.976C203.095 303.017 203.041 303.045 202.982 303.055C202.923 303.066 202.863 303.058 202.808 303.034C202.754 303.01 202.707 302.97 202.676 302.919C202.644 302.869 202.628 302.81 202.63 302.75ZM202.72 303.75H203.13V306.82H202.72V303.75Z"
        fill="#E0E0E0"
      />
      <path
        d="M207.109 304.999V306.779H206.689V305.039C206.711 304.91 206.703 304.778 206.666 304.652C206.628 304.526 206.563 304.411 206.474 304.314C206.385 304.218 206.276 304.143 206.154 304.095C206.031 304.048 205.9 304.028 205.769 304.039C205.622 304.028 205.475 304.05 205.337 304.102C205.2 304.154 205.075 304.236 204.973 304.342C204.871 304.449 204.793 304.576 204.746 304.715C204.699 304.855 204.683 305.003 204.699 305.149V306.759H204.279V303.689H204.679V304.259C204.803 304.065 204.977 303.907 205.183 303.803C205.389 303.7 205.619 303.653 205.849 303.669C206.024 303.656 206.2 303.682 206.364 303.745C206.528 303.808 206.675 303.907 206.796 304.034C206.917 304.162 207.007 304.315 207.062 304.482C207.116 304.648 207.132 304.825 207.109 304.999Z"
        fill="#E0E0E0"
      />
      <path
        d="M211.08 303.719V306.409C211.112 306.619 211.095 306.833 211.029 307.035C210.963 307.236 210.85 307.42 210.7 307.57C210.55 307.72 210.367 307.832 210.165 307.898C209.964 307.964 209.749 307.982 209.54 307.949C209.023 307.971 208.517 307.8 208.12 307.469L208.33 307.159C208.666 307.439 209.092 307.588 209.53 307.579C210.31 307.579 210.66 307.219 210.66 306.469V306.079C210.528 306.271 210.35 306.426 210.142 306.529C209.933 306.633 209.702 306.681 209.47 306.669C209.072 306.669 208.69 306.511 208.409 306.23C208.128 305.949 207.97 305.567 207.97 305.169C207.97 304.771 208.128 304.39 208.409 304.109C208.69 303.827 209.072 303.669 209.47 303.669C209.707 303.659 209.942 303.71 210.154 303.817C210.366 303.924 210.547 304.083 210.68 304.279V303.689L211.08 303.719ZM210.68 305.179C210.69 304.946 210.63 304.715 210.507 304.516C210.385 304.317 210.206 304.16 209.993 304.063C209.78 303.967 209.544 303.937 209.314 303.976C209.084 304.015 208.87 304.123 208.702 304.284C208.533 304.445 208.417 304.654 208.367 304.882C208.318 305.11 208.338 305.348 208.425 305.565C208.512 305.781 208.661 305.967 208.855 306.098C209.048 306.229 209.276 306.299 209.51 306.299C209.662 306.309 209.814 306.287 209.957 306.235C210.1 306.183 210.231 306.101 210.341 305.996C210.451 305.891 210.538 305.764 210.596 305.623C210.654 305.483 210.683 305.331 210.68 305.179Z"
        fill="#E0E0E0"
      />
      <path
        d="M213.489 306.499C213.491 306.438 213.511 306.379 213.546 306.33C213.582 306.28 213.631 306.242 213.688 306.22C213.744 306.198 213.806 306.193 213.866 306.206C213.925 306.22 213.98 306.25 214.022 306.294C214.064 306.337 214.093 306.392 214.104 306.452C214.115 306.512 214.108 306.574 214.085 306.63C214.061 306.686 214.021 306.734 213.971 306.768C213.92 306.801 213.86 306.819 213.799 306.819C213.758 306.819 213.717 306.811 213.678 306.795C213.64 306.778 213.606 306.755 213.577 306.725C213.548 306.695 213.525 306.66 213.51 306.621C213.495 306.582 213.488 306.541 213.489 306.499Z"
        fill="#E0E0E0"
      />
      <path
        d="M214.72 306.5C214.719 306.458 214.726 306.417 214.741 306.378C214.756 306.339 214.778 306.304 214.807 306.274C214.836 306.244 214.871 306.22 214.909 306.204C214.947 306.188 214.988 306.18 215.03 306.18C215.115 306.18 215.196 306.213 215.256 306.273C215.316 306.333 215.35 306.415 215.35 306.5C215.35 306.585 215.316 306.666 215.256 306.726C215.196 306.786 215.115 306.82 215.03 306.82C214.988 306.82 214.947 306.811 214.909 306.795C214.871 306.779 214.836 306.755 214.807 306.725C214.778 306.696 214.756 306.66 214.741 306.621C214.726 306.583 214.719 306.541 214.72 306.5Z"
        fill="#E0E0E0"
      />
      <path
        d="M215.999 306.499C216.001 306.438 216.021 306.379 216.056 306.33C216.091 306.28 216.141 306.242 216.197 306.22C216.254 306.198 216.316 306.193 216.376 306.206C216.435 306.22 216.489 306.25 216.532 306.294C216.574 306.337 216.603 306.392 216.614 306.452C216.625 306.512 216.618 306.574 216.595 306.63C216.571 306.686 216.531 306.734 216.48 306.768C216.43 306.801 216.37 306.819 216.309 306.819C216.268 306.819 216.226 306.811 216.188 306.795C216.15 306.778 216.115 306.755 216.086 306.725C216.057 306.695 216.035 306.66 216.02 306.621C216.005 306.582 215.998 306.541 215.999 306.499Z"
        fill="#E0E0E0"
      />
      <path
        d="M365.8 313.44H187.91C187.47 313.44 187.048 313.265 186.736 312.954C186.425 312.643 186.25 312.22 186.25 311.78C186.25 311.562 186.293 311.346 186.376 311.145C186.46 310.943 186.582 310.76 186.736 310.606C186.89 310.452 187.073 310.33 187.275 310.246C187.476 310.163 187.692 310.12 187.91 310.12H365.8C366.018 310.12 366.234 310.163 366.435 310.246C366.637 310.33 366.82 310.452 366.974 310.606C367.128 310.76 367.25 310.943 367.334 311.145C367.417 311.346 367.46 311.562 367.46 311.78C367.46 311.998 367.417 312.214 367.334 312.415C367.25 312.617 367.128 312.8 366.974 312.954C366.82 313.108 366.637 313.23 366.435 313.314C366.234 313.397 366.018 313.44 365.8 313.44Z"
        fill="#E0E0E0"
      />
      <path
        d="M340.52 313.44H187.91C187.47 313.44 187.048 313.265 186.736 312.954C186.425 312.643 186.25 312.22 186.25 311.78C186.25 311.562 186.293 311.346 186.376 311.145C186.46 310.943 186.582 310.76 186.736 310.606C186.89 310.452 187.073 310.33 187.275 310.246C187.476 310.163 187.692 310.12 187.91 310.12H340.52C340.738 310.12 340.954 310.163 341.155 310.246C341.357 310.33 341.54 310.452 341.694 310.606C341.848 310.76 341.97 310.943 342.054 311.145C342.137 311.346 342.18 311.562 342.18 311.78C342.18 312.22 342.005 312.643 341.694 312.954C341.382 313.265 340.96 313.44 340.52 313.44Z"
        [attr.fill]="'var(--primary-color)'"
      />
      <path
        d="M376.67 308.8C376 309.84 375.185 310.78 374.25 311.59C373.44 312.518 372.504 313.33 371.47 314C372.14 312.966 372.951 312.03 373.88 311.22C374.69 310.284 375.629 309.47 376.67 308.8Z"
        fill="#263238"
      />
      <path
        d="M376.55 314C375.515 313.33 374.58 312.518 373.77 311.59C372.834 310.78 372.019 309.84 371.35 308.8C373.315 310.285 375.065 312.035 376.55 314Z"
        fill="#263238"
      />
      <path
        d="M349.609 302.71V306.79H349.189V303.08H348.189V302.71H349.609Z"
        fill="#E0E0E0"
      />
      <path
        d="M353.349 305.59C353.349 306.28 352.869 306.82 351.869 306.82C351.334 306.84 350.811 306.654 350.409 306.3L350.609 305.96C350.777 306.119 350.975 306.244 351.191 306.326C351.407 306.409 351.638 306.447 351.869 306.44C352.559 306.44 352.929 306.1 352.929 305.6C352.929 305.1 352.599 304.75 351.559 304.75H350.689L350.899 302.75H353.139V303.12H351.269L351.129 304.42H351.659C352.889 304.38 353.349 304.86 353.349 305.59Z"
        fill="#E0E0E0"
      />
      <path
        d="M360 302.71V306.79H359.59V303.52L357.99 306.27H357.78L356.18 303.54V306.79H355.76V302.71H356.12L357.89 305.71L359.65 302.71H360Z"
        fill="#E0E0E0"
      />
      <path
        d="M364.74 305.7C364.74 306.39 364.22 306.79 363.2 306.79H361.33V302.71H363.09C364 302.71 364.52 303.09 364.52 303.76C364.528 303.959 364.472 304.156 364.36 304.321C364.249 304.487 364.088 304.612 363.9 304.68C364.14 304.719 364.358 304.844 364.513 305.031C364.667 305.219 364.748 305.457 364.74 305.7ZM361.74 303.06V304.54H363.04C363.69 304.54 364.04 304.29 364.04 303.8C364.04 303.31 363.66 303.06 363.04 303.06H361.74ZM364.28 305.66C364.28 305.13 363.89 304.9 363.18 304.9H361.74V306.43H363.18C363.91 306.43 364.3 306.2 364.3 305.66H364.28Z"
        fill="#E0E0E0"
      />
      <path
        d="M150.05 124.77V121.42H150.9V124.73C150.9 126.04 151.49 126.62 152.55 126.62C153.61 126.62 154.22 126.04 154.22 124.73V121.42H155V124.77C155 125.433 154.737 126.069 154.268 126.538C153.799 127.007 153.163 127.27 152.5 127.27C151.837 127.27 151.201 127.007 150.732 126.538C150.263 126.069 150 125.433 150 124.77H150.05Z"
        fill="#263238"
      />
      <path
        d="M161.26 125.08C161.275 125.381 161.228 125.683 161.12 125.966C161.012 126.248 160.846 126.505 160.634 126.719C160.421 126.934 160.166 127.102 159.884 127.212C159.603 127.322 159.302 127.373 159 127.36C158.702 127.368 158.407 127.306 158.138 127.179C157.868 127.052 157.633 126.864 157.45 126.63V128.94H156.64V122.85H157.41V123.56C157.595 123.316 157.836 123.12 158.113 122.99C158.39 122.859 158.694 122.797 159 122.81C159.301 122.797 159.601 122.847 159.882 122.956C160.162 123.066 160.417 123.233 160.63 123.447C160.842 123.66 161.008 123.916 161.117 124.197C161.225 124.478 161.274 124.779 161.26 125.08ZM160.44 125.08C160.426 124.786 160.326 124.503 160.154 124.266C159.981 124.029 159.742 123.848 159.467 123.745C159.192 123.642 158.893 123.622 158.606 123.687C158.32 123.753 158.06 123.901 157.857 124.113C157.654 124.326 157.518 124.593 157.465 124.882C157.413 125.171 157.446 125.469 157.562 125.739C157.677 126.009 157.869 126.239 158.114 126.401C158.359 126.563 158.646 126.649 158.94 126.65C159.143 126.653 159.346 126.614 159.534 126.535C159.722 126.456 159.892 126.34 160.033 126.192C160.173 126.045 160.282 125.87 160.352 125.678C160.422 125.487 160.452 125.283 160.44 125.08Z"
        fill="#263238"
      />
      <path d="M162.38 121.069H163.18V127.309H162.38V121.069Z" fill="#263238" />
      <path
        d="M164.31 125.08C164.302 124.619 164.431 124.167 164.681 123.78C164.931 123.393 165.291 123.09 165.714 122.908C166.137 122.726 166.605 122.675 167.057 122.76C167.51 122.844 167.927 123.062 168.256 123.385C168.584 123.708 168.809 124.121 168.902 124.572C168.995 125.023 168.951 125.492 168.777 125.918C168.602 126.344 168.305 126.709 167.923 126.966C167.54 127.223 167.09 127.36 166.63 127.36C166.324 127.375 166.019 127.327 165.733 127.218C165.448 127.11 165.188 126.943 164.97 126.729C164.751 126.514 164.58 126.257 164.467 125.973C164.353 125.69 164.3 125.385 164.31 125.08ZM168.13 125.08C168.116 124.786 168.017 124.504 167.844 124.266C167.671 124.029 167.432 123.848 167.157 123.745C166.882 123.642 166.583 123.622 166.297 123.688C166.01 123.753 165.75 123.901 165.547 124.114C165.344 124.326 165.208 124.593 165.156 124.882C165.103 125.171 165.137 125.469 165.252 125.739C165.367 126.009 165.559 126.239 165.804 126.401C166.049 126.563 166.336 126.649 166.63 126.65C166.834 126.654 167.037 126.616 167.226 126.538C167.415 126.46 167.585 126.343 167.726 126.196C167.867 126.048 167.976 125.872 168.046 125.68C168.115 125.488 168.144 125.284 168.13 125.08Z"
        fill="#263238"
      />
      <path
        d="M173.55 124.61V127.31H172.79V126.72C172.617 126.94 172.393 127.115 172.136 127.226C171.879 127.338 171.599 127.384 171.32 127.36C170.32 127.36 169.68 126.82 169.68 126.04C169.68 125.26 170.14 124.74 171.47 124.74H172.74V124.58C172.74 123.9 172.35 123.5 171.55 123.5C171.039 123.496 170.542 123.665 170.14 123.98L169.8 123.38C170.334 122.993 170.98 122.792 171.64 122.81C172.86 122.81 173.55 123.4 173.55 124.61ZM172.74 125.94V125.32H171.5C170.7 125.32 170.5 125.63 170.5 126.01C170.5 126.39 170.87 126.73 171.5 126.73C171.763 126.741 172.023 126.672 172.244 126.53C172.466 126.389 172.639 126.183 172.74 125.94Z"
        fill="#263238"
      />
      <path
        d="M179.28 121.069V127.309H178.51V126.599C178.325 126.844 178.084 127.04 177.807 127.173C177.531 127.305 177.227 127.369 176.92 127.359C176.343 127.321 175.802 127.065 175.406 126.642C175.011 126.22 174.791 125.663 174.791 125.084C174.791 124.506 175.011 123.949 175.406 123.526C175.802 123.104 176.343 122.848 176.92 122.809C177.216 122.797 177.511 122.855 177.78 122.979C178.05 123.102 178.286 123.287 178.47 123.519V121.069H179.28ZM178.48 125.069C178.466 124.776 178.367 124.493 178.194 124.256C178.021 124.019 177.782 123.837 177.507 123.734C177.232 123.632 176.933 123.612 176.647 123.677C176.361 123.743 176.1 123.891 175.897 124.103C175.694 124.315 175.558 124.583 175.506 124.871C175.453 125.16 175.487 125.458 175.602 125.728C175.718 125.998 175.91 126.229 176.154 126.391C176.399 126.553 176.686 126.639 176.98 126.639C177.183 126.644 177.386 126.606 177.574 126.529C177.762 126.451 177.932 126.335 178.073 126.189C178.213 126.042 178.323 125.868 178.393 125.677C178.463 125.486 178.493 125.282 178.48 125.079V125.069Z"
        fill="#263238"
      />
      <path
        d="M184.66 121C184.983 120.984 185.303 121.076 185.57 121.26L185.33 121.87C185.15 121.73 184.927 121.656 184.7 121.66C184.23 121.66 183.98 121.93 183.98 122.44V122.83H185.28V123.49H184V127.29H183.19V123.49H182.43V122.83H183.19V122.43C183.176 122.235 183.204 122.039 183.274 121.857C183.343 121.674 183.452 121.509 183.592 121.372C183.732 121.236 183.9 121.132 184.085 121.068C184.269 121.003 184.465 120.98 184.66 121ZM186.57 121.45C186.571 121.379 186.586 121.309 186.615 121.244C186.643 121.179 186.684 121.12 186.735 121.071C186.786 121.021 186.846 120.983 186.912 120.957C186.978 120.931 187.049 120.918 187.12 120.92C187.2 120.892 187.285 120.883 187.369 120.895C187.453 120.907 187.533 120.938 187.602 120.987C187.671 121.036 187.727 121.101 187.766 121.176C187.805 121.251 187.825 121.335 187.825 121.42C187.825 121.504 187.805 121.588 187.766 121.663C187.727 121.738 187.671 121.803 187.602 121.852C187.533 121.901 187.453 121.933 187.369 121.945C187.285 121.956 187.2 121.948 187.12 121.92C186.989 121.926 186.86 121.884 186.758 121.8C186.656 121.717 186.589 121.599 186.57 121.47V121.45ZM186.71 122.83H187.52V127.29H186.71V122.83Z"
        fill="#263238"
      />
      <path d="M189.06 121.069H189.87V127.309H189.06V121.069Z" fill="#263238" />
      <path
        d="M195.42 125.35H191.8C191.852 125.732 192.05 126.081 192.351 126.322C192.652 126.564 193.035 126.681 193.42 126.65C193.66 126.658 193.898 126.614 194.12 126.521C194.341 126.428 194.539 126.287 194.7 126.11L195.15 126.63C194.822 126.966 194.404 127.2 193.946 127.303C193.487 127.406 193.009 127.373 192.569 127.208C192.129 127.044 191.747 126.755 191.468 126.377C191.189 125.999 191.027 125.548 191 125.08C190.988 124.782 191.036 124.484 191.144 124.206C191.251 123.927 191.414 123.674 191.623 123.461C191.832 123.248 192.082 123.081 192.359 122.969C192.635 122.857 192.932 122.802 193.23 122.81C193.529 122.801 193.828 122.854 194.105 122.967C194.383 123.08 194.634 123.25 194.842 123.466C195.05 123.681 195.21 123.938 195.313 124.219C195.417 124.501 195.46 124.801 195.44 125.1C195.438 125.183 195.431 125.267 195.42 125.35ZM191.8 124.77H194.66C194.618 124.419 194.45 124.097 194.186 123.863C193.923 123.628 193.583 123.499 193.23 123.499C192.877 123.499 192.537 123.628 192.273 123.863C192.01 124.097 191.841 124.419 191.8 124.77Z"
        fill="#263238"
      />
      <path
        d="M196 126.83L196.34 126.2C196.806 126.497 197.347 126.657 197.9 126.66C198.65 126.66 198.96 126.43 198.96 126.05C198.96 125.05 196.15 125.92 196.15 124.14C196.15 123.35 196.86 122.81 198 122.81C198.569 122.801 199.13 122.938 199.63 123.21L199.28 123.85C198.897 123.611 198.452 123.49 198 123.5C197.29 123.5 196.94 123.76 196.94 124.11C196.94 125.11 199.77 124.3 199.77 126.04C199.77 126.84 199.03 127.36 197.84 127.36C197.187 127.377 196.544 127.192 196 126.83Z"
        fill="#263238"
      />
      <path
        d="M403.94 140.34C403.94 140.49 342.86 140.61 267.54 140.61C192.22 140.61 131.14 140.49 131.14 140.34C131.14 140.19 192.2 140.08 267.54 140.08C342.88 140.08 403.94 140.2 403.94 140.34Z"
        fill="#263238"
      />
      <path
        d="M122.99 343.55V343.05H122.49H96.8267L140.18 303.765L183.534 343.05H157.87H157.37V343.55V400.3H141.19H139.17H122.99V343.55Z"
        [attr.fill]="'var(--secondary-color)'"
        [attr.stroke]="'var(--primary-color)'"
      />
      <path
        d="M226.19 292C224.13 285.39 224.99 274.3 229.54 268.83C234.09 263.36 241.98 260.2 249.04 259.33C250.213 259.094 251.43 259.22 252.53 259.69C253.072 259.945 253.496 260.398 253.716 260.956C253.935 261.513 253.933 262.134 253.71 262.69C255.142 261.867 256.699 261.286 258.32 260.97C259.133 260.822 259.97 260.875 260.758 261.124C261.545 261.373 262.26 261.811 262.84 262.4C263.11 262.706 263.316 263.062 263.448 263.448C263.58 263.833 263.635 264.241 263.609 264.648C263.583 265.055 263.477 265.453 263.297 265.819C263.117 266.184 262.867 266.511 262.56 266.78C263.565 266.513 264.626 266.546 265.613 266.875C266.599 267.203 267.468 267.814 268.112 268.63C268.757 269.446 269.148 270.433 269.238 271.469C269.328 272.505 269.113 273.545 268.62 274.46C267.501 276.328 266.031 277.962 264.29 279.27C262.72 280.47 262.35 281.83 262.42 283.81"
        fill="#263238"
      />
      <path
        d="M228.9 280.48L223.56 319.33C223.344 320.896 223.448 322.49 223.867 324.015C224.285 325.539 225.01 326.963 225.995 328.199C226.981 329.436 228.208 330.459 229.601 331.207C230.994 331.955 232.525 332.411 234.1 332.55C237.084 332.808 240.054 331.909 242.394 330.038C244.734 328.167 246.265 325.468 246.67 322.5C247.07 319.57 247.41 317.13 247.47 316.83C247.47 316.83 257.47 317.27 259.55 307.57C260.55 302.89 261.82 293.08 262.86 284.19C263.381 279.592 262.158 274.967 259.432 271.228C256.706 267.489 252.676 264.91 248.14 264L247.2 263.81C237 262.34 230.05 270.22 228.9 280.48Z"
        fill="#FFBE9D"
      />
      <path
        d="M247.52 316.7C242.999 315.896 238.821 313.762 235.52 310.57C235.52 310.57 237.12 318.16 246.99 319.22L247.52 316.7Z"
        fill="#EB996E"
      />
      <path
        d="M258.73 290.13C258.66 290.492 258.451 290.812 258.149 291.023C257.847 291.234 257.474 291.319 257.11 291.26C256.931 291.239 256.759 291.183 256.602 291.094C256.445 291.005 256.308 290.886 256.198 290.744C256.088 290.601 256.008 290.438 255.962 290.264C255.916 290.09 255.905 289.908 255.93 289.73C255.999 289.367 256.206 289.044 256.509 288.831C256.811 288.618 257.185 288.532 257.55 288.59C257.73 288.612 257.903 288.67 258.059 288.76C258.216 288.849 258.353 288.969 258.463 289.113C258.573 289.256 258.653 289.42 258.699 289.595C258.745 289.769 258.756 289.951 258.73 290.13Z"
        fill="#263238"
      />
      <path
        d="M259.82 288.54C259.61 288.69 258.71 287.7 257.22 287.4C255.73 287.1 254.48 287.64 254.36 287.4C254.24 287.16 254.54 287.01 255.08 286.76C255.817 286.442 256.634 286.358 257.42 286.52C258.19 286.676 258.89 287.071 259.42 287.65C259.83 288.12 259.92 288.47 259.82 288.54Z"
        fill="#263238"
      />
      <path
        d="M244 287.71C243.931 288.071 243.725 288.391 243.424 288.602C243.123 288.813 242.752 288.899 242.39 288.84C242.211 288.819 242.038 288.763 241.881 288.674C241.725 288.585 241.587 288.466 241.477 288.324C241.367 288.181 241.287 288.018 241.241 287.844C241.195 287.67 241.184 287.488 241.21 287.31C241.276 286.947 241.481 286.625 241.782 286.412C242.083 286.199 242.455 286.112 242.82 286.17C242.999 286.191 243.173 286.248 243.331 286.337C243.488 286.426 243.626 286.546 243.736 286.69C243.846 286.834 243.926 286.998 243.971 287.173C244.017 287.348 244.026 287.531 244 287.71Z"
        fill="#263238"
      />
      <path
        d="M245.09 285.89C244.88 286.03 243.98 285.04 242.49 284.75C241 284.46 239.75 284.98 239.63 284.75C239.51 284.52 239.8 284.36 240.35 284.11C241.087 283.791 241.904 283.708 242.69 283.87C243.46 284.025 244.16 284.421 244.69 285C245.1 285.46 245.19 285.82 245.09 285.89Z"
        fill="#263238"
      />
      <path
        d="M248.29 298C249.127 297.893 249.973 297.893 250.81 298C251.19 298 251.57 298 251.69 297.78C251.805 297.395 251.805 296.985 251.69 296.6C251.51 295.6 251.33 294.6 251.14 293.47C250.4 289.03 249.95 285.4 250.14 285.37C250.33 285.34 251.06 288.93 251.8 293.37C251.97 294.47 252.14 295.51 252.3 296.51C252.44 297.017 252.405 297.556 252.2 298.04C252.117 298.164 252.008 298.268 251.88 298.344C251.752 298.42 251.608 298.467 251.46 298.48C251.237 298.502 251.013 298.502 250.79 298.48C249.939 298.432 249.098 298.271 248.29 298Z"
        fill="#263238"
      />
      <path
        d="M243.69 298.19C243.94 298.19 243.62 299.85 244.78 301.28C245.94 302.71 247.69 302.87 247.66 303.1C247.63 303.33 247.21 303.34 246.48 303.21C245.528 303.032 244.668 302.526 244.05 301.78C243.462 301.063 243.175 300.145 243.25 299.22C243.32 298.53 243.58 298.16 243.69 298.19Z"
        fill="#263238"
      />
      <path
        d="M246.64 280.11C246.41 280.49 244.96 280 243.17 279.86C241.38 279.72 239.86 279.91 239.7 279.5C239.63 279.31 239.96 278.98 240.61 278.7C241.464 278.362 242.386 278.231 243.3 278.32C244.219 278.384 245.108 278.673 245.89 279.16C246.48 279.55 246.74 279.93 246.64 280.11Z"
        fill="#263238"
      />
      <path
        d="M260.13 284.11C259.8 284.4 258.84 283.84 257.61 283.57C256.38 283.3 255.28 283.35 255.11 282.94C255.04 282.75 255.27 282.44 255.79 282.21C256.476 281.961 257.219 281.918 257.93 282.085C258.64 282.252 259.286 282.622 259.79 283.15C260.21 283.59 260.28 284 260.13 284.11Z"
        fill="#263238"
      />
      <path
        d="M228.26 287.83C228.11 287.73 221.98 284.53 220.86 291.21C219.74 297.89 226.69 297.631 226.73 297.441C226.77 297.251 228.26 287.83 228.26 287.83Z"
        fill="#FFBE9D"
      />
      <path
        d="M225.11 294.57C225.11 294.57 224.98 294.63 224.77 294.69C224.474 294.763 224.161 294.716 223.9 294.56C223.514 294.215 223.227 293.774 223.069 293.282C222.91 292.79 222.887 292.264 223 291.76C223.08 291.182 223.284 290.629 223.6 290.14C223.696 289.946 223.836 289.777 224.01 289.649C224.184 289.52 224.386 289.434 224.6 289.4C224.739 289.385 224.878 289.42 224.994 289.499C225.109 289.578 225.193 289.695 225.23 289.83C225.29 290.03 225.23 290.16 225.23 290.18C225.23 290.2 225.39 290.09 225.38 289.8C225.371 289.629 225.304 289.467 225.19 289.34C225.027 289.17 224.805 289.07 224.57 289.06C224.286 289.07 224.009 289.152 223.766 289.299C223.523 289.446 223.321 289.653 223.18 289.9C222.801 290.43 222.557 291.044 222.47 291.69C222.337 292.279 222.383 292.895 222.6 293.459C222.817 294.024 223.196 294.511 223.69 294.86C223.86 294.943 224.046 294.986 224.235 294.986C224.424 294.986 224.61 294.943 224.78 294.86C225.07 294.73 225.13 294.59 225.11 294.57Z"
        fill="#EB996E"
      />
      <path
        d="M269.23 272.29C268.56 281.09 257.29 282.91 253.41 277.29C251.62 274.69 250.2 271.57 247.41 270.07C246.454 269.6 245.415 269.325 244.353 269.26C243.29 269.195 242.225 269.341 241.22 269.69H241.15C238.15 270.69 236 273.57 235.06 276.58C233.75 280.76 233.8 284.65 232.06 288.4C231.859 288.965 231.48 289.449 230.98 289.78C230.07 290.23 228.98 289.52 228.45 288.66C227.685 287.222 227.358 285.591 227.51 283.97C227.526 280.271 228.281 276.613 229.73 273.21C231.188 269.794 233.623 266.885 236.73 264.85C240.705 262.416 245.472 261.627 250.02 262.65C254.543 263.699 258.62 266.149 261.67 269.65C263.158 271.197 264.237 273.091 264.81 275.16"
        fill="#263238"
      />
      <path
        d="M263 266.34C263.318 266.326 263.634 266.391 263.92 266.53C264.723 266.866 265.416 267.42 265.92 268.13C266.682 269.186 267.066 270.468 267.01 271.77C266.882 273.365 266.17 274.857 265.01 275.96C263.879 277.064 262.463 277.832 260.92 278.18C259.679 278.447 258.403 278.515 257.14 278.38C256.297 278.288 255.464 278.121 254.65 277.88C254.344 277.818 254.05 277.707 253.78 277.55C253.78 277.45 255.07 277.86 257.17 278.01C258.389 278.098 259.613 277.997 260.8 277.71C262.247 277.359 263.573 276.627 264.64 275.59C265.717 274.577 266.392 273.21 266.54 271.74C266.611 270.538 266.292 269.345 265.63 268.34C265.182 267.656 264.565 267.098 263.84 266.72C263.27 266.45 263 266.39 263 266.34Z"
        fill="#455A64"
      />
      <path
        d="M259 262.53C259 262.53 259.26 262.53 259.62 262.75C259.859 262.944 260.055 263.188 260.193 263.464C260.331 263.74 260.408 264.042 260.42 264.35C260.404 265.258 260.072 266.132 259.48 266.82C258.84 267.676 258.078 268.434 257.22 269.07C255.67 270.237 253.856 271.003 251.94 271.3C251.156 271.472 250.344 271.472 249.56 271.3C249.56 271.21 250.47 271.24 251.87 270.94C253.682 270.547 255.388 269.766 256.87 268.65C257.687 268.04 258.423 267.327 259.06 266.53C259.602 265.929 259.936 265.167 260.01 264.36C260.022 263.819 259.821 263.294 259.45 262.9C259.21 262.63 259 262.57 259 262.53Z"
        fill="#455A64"
      />
      <path
        d="M263.92 392.19C264.07 392.19 264.1 382.41 264 370.37C263.9 358.33 263.71 348.55 263.56 348.55C263.41 348.55 263.38 358.32 263.48 370.37C263.58 382.42 263.78 392.19 263.92 392.19Z"
        fill="#263238"
      />
      <path
        d="M216.65 346.56C216.715 346.829 216.755 347.103 216.77 347.38C216.83 347.96 216.91 348.75 217.01 349.75L217.8 358.45C218.12 362.15 218.5 366.52 218.91 371.37C219.02 372.59 219.12 373.84 219.23 375.11C219.3 376.399 219.461 377.682 219.71 378.95C219.967 380.238 220.617 381.415 221.57 382.32C222.598 383.216 223.763 383.941 225.02 384.47C235.35 388.94 244.87 392.63 251.78 395.21L259.98 398.21L262.21 399.03C262.477 399.11 262.735 399.217 262.98 399.35C262.708 399.292 262.441 399.215 262.18 399.12L259.92 398.39C257.92 397.74 255.13 396.77 251.66 395.52C244.71 393.01 235.17 389.38 224.82 384.89C223.508 384.347 222.294 383.59 221.23 382.65C220.208 381.677 219.51 380.413 219.23 379.03C218.98 377.739 218.82 376.432 218.75 375.12C218.65 373.85 218.55 372.6 218.45 371.38C218.08 366.53 217.74 362.15 217.45 358.45C217.16 354.75 216.97 351.84 216.82 349.73C216.76 348.73 216.71 347.94 216.67 347.36C216.647 347.094 216.64 346.826 216.65 346.56Z"
        fill="#263238"
      />
      <path
        d="M195.19 366.08C195.238 366.236 195.265 366.397 195.27 366.56C195.27 366.94 195.34 367.39 195.39 367.95C195.5 369.23 195.65 370.95 195.84 373.04C196.03 375.13 196.3 377.75 196.68 380.58C197.046 383.643 197.61 386.68 198.37 389.67C198.722 391.211 199.337 392.679 200.19 394.01C201.022 395.236 201.959 396.387 202.99 397.45C204.741 399.253 206.766 400.768 208.99 401.94C211.054 402.998 213.271 403.727 215.56 404.1C215.401 404.122 215.239 404.122 215.08 404.1C214.613 404.067 214.149 404.007 213.69 403.92C212 403.602 210.364 403.047 208.83 402.27C206.541 401.122 204.456 399.606 202.66 397.78C201.598 396.711 200.637 395.546 199.79 394.3C198.896 392.93 198.25 391.414 197.88 389.82C197.118 386.805 196.564 383.741 196.22 380.65C195.87 377.81 195.64 375.24 195.48 373.08C195.32 370.92 195.24 369.18 195.2 367.97V366.58C195.181 366.414 195.178 366.247 195.19 366.08Z"
        fill="#263238"
      />
      <path
        d="M211.21 391.21C211.306 390.506 211.537 389.827 211.89 389.21C212.663 387.679 213.733 386.316 215.038 385.202C216.343 384.088 217.856 383.244 219.49 382.72C220.154 382.47 220.86 382.347 221.57 382.36C221.57 382.44 220.8 382.6 219.57 383.06C218.022 383.656 216.585 384.508 215.32 385.58C214.064 386.664 212.996 387.948 212.16 389.38C211.57 390.48 211.29 391.24 211.21 391.21Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M250.89 395.07C246.89 392.81 242.96 391.07 238.77 389.17C234.58 387.27 230.49 384.83 227.77 381.17C222.61 374.17 223.19 364.17 217.9 357.27C218.247 361.896 218.58 366.52 218.9 371.14C219.22 375.38 218.57 379.57 221.3 382.45C223.6 384.87 228.08 386.73 231.25 387.78C237.63 389.89 244.5 393.01 250.88 395.11"
          fill="black"
        />
      </g>
      <path
        d="M304.11 385C302.67 386.607 300.907 387.893 298.937 388.774C296.967 389.654 294.833 390.109 292.675 390.109C290.517 390.109 288.383 389.654 286.412 388.774C284.442 387.893 282.679 386.607 281.24 385L263.56 365.2L263.35 393.41L269.59 411.5L207.59 422.25L205 373.32L200.54 335.76C200.272 333.521 200.754 331.256 201.911 329.32C203.067 327.384 204.832 325.886 206.93 325.06L224 318.35L248 322.75L257.66 326.43C261.719 327.966 265.336 330.48 268.19 333.75L295.3 364.75"
        [attr.fill]="'var(--secondary-color)'"
      />
      <path
        d="M261.65 399.36L224.19 384.28C222.709 383.685 221.425 382.686 220.483 381.397C219.54 380.109 218.978 378.582 218.86 376.99L216.12 341.08C216.12 341.08 216.44 325.81 206.9 325.08L201.47 327.22C194.47 330.95 192.72 341.16 193.68 349.04C195.03 360.24 195.6 376.1 197.12 385.82C198.36 393.77 202.92 400.77 210.7 402.82L256.83 412.82L261.65 399.36Z"
        [attr.fill]="'var(--secondary-color)'"
      />
      <path
        d="M222.49 320.05L224.32 313.18L247.6 318.65C247.909 318.724 248.2 318.86 248.455 319.049C248.71 319.239 248.923 319.479 249.082 319.754C249.241 320.029 249.343 320.334 249.38 320.65C249.418 320.965 249.39 321.285 249.3 321.59L247.52 327.65L239.12 338.43L229.59 336.73L222.49 331.28V320.05Z"
        [attr.fill]="'var(--secondary-color)'"
      />
      <path
        d="M248 323.17C247.738 323.275 247.462 323.339 247.18 323.36C246.425 323.475 245.664 323.548 244.9 323.58C239.812 323.796 234.765 322.583 230.33 320.08C229.662 319.71 229.017 319.3 228.4 318.85C227.96 318.53 227.73 318.34 227.75 318.31C227.77 318.28 228.8 318.89 230.51 319.76C234.962 322.035 239.891 323.217 244.89 323.21C246.84 323.21 248 323.08 248 323.17Z"
        fill="#F5F5F5"
      />
      <path
        d="M230.7 320.071C230.7 320.071 230.38 319.88 230.14 319.36C229.851 318.675 229.788 317.915 229.96 317.191C230.123 316.463 230.515 315.807 231.08 315.321C231.53 314.961 231.88 314.88 231.9 314.93C231.92 314.98 231.67 315.2 231.34 315.58C230.918 316.081 230.62 316.674 230.47 317.31C230.317 317.945 230.317 318.606 230.47 319.24C230.61 319.73 230.77 320.001 230.7 320.071Z"
        fill="#F5F5F5"
      />
      <path
        d="M240.71 323.47C241.306 321.264 241.049 318.915 239.99 316.89C239.99 316.89 240.34 317.11 240.69 317.67C241.128 318.398 241.401 319.214 241.49 320.06C241.584 320.905 241.496 321.761 241.23 322.57C241 323.18 240.77 323.5 240.71 323.47Z"
        fill="#F5F5F5"
      />
      <path
        d="M241.52 391.14C241.493 390.908 241.493 390.673 241.52 390.44C241.52 389.98 241.66 389.31 241.69 388.44C241.856 385.986 241.856 383.524 241.69 381.07C241.427 377.435 240.966 373.816 240.31 370.23C239.61 366.17 238.69 361.72 237.95 357.01C236.699 348.956 236.141 340.81 236.28 332.66C236.34 329.54 236.48 327.01 236.62 325.27C236.68 324.4 236.76 323.73 236.81 323.27C236.82 323.034 236.853 322.8 236.91 322.57C236.936 322.806 236.936 323.044 236.91 323.28C236.91 323.79 236.91 324.45 236.82 325.28C236.75 327.03 236.66 329.55 236.65 332.67C236.616 340.792 237.225 348.904 238.47 356.93C239.21 361.62 240.11 366.07 240.78 370.15C241.414 373.756 241.841 377.396 242.06 381.05C242.202 383.52 242.152 385.998 241.91 388.46C241.85 389.33 241.71 390 241.65 390.46C241.628 390.691 241.585 390.918 241.52 391.14Z"
        fill="#F5F5F5"
      />
      <path
        d="M253 324.52C253.032 324.576 253.056 324.636 253.07 324.7C253.11 324.82 253.16 325 253.22 325.24C253.368 325.941 253.458 326.653 253.49 327.37C253.61 329.24 253.35 331.92 253.2 335.23C253.05 338.54 252.87 342.49 253 346.87C253.083 351.629 253.673 356.365 254.76 361C255.92 365.91 257.27 370.58 257.76 375C258.223 378.875 258.353 382.782 258.15 386.68C257.98 390 257.68 392.68 257.44 394.51C257.32 395.43 257.21 396.15 257.13 396.63C257.097 396.875 257.05 397.119 256.99 397.36C256.961 397.11 256.961 396.859 256.99 396.61C256.99 396.08 257.11 395.37 257.2 394.49C257.38 392.64 257.62 389.97 257.74 386.66C257.887 382.786 257.72 378.906 257.24 375.06C256.73 370.72 255.37 366.06 254.24 361.15C253.141 356.497 252.551 351.739 252.48 346.96C252.38 342.56 252.58 338.6 252.8 335.29C253.02 331.98 253.3 329.29 253.25 327.46C253.24 326.75 253.18 326.041 253.07 325.34C253.031 325.068 253.007 324.794 253 324.52Z"
        fill="#F5F5F5"
      />
      <path
        d="M274.31 364.53C273.64 364.133 273.028 363.645 272.49 363.08C271.106 361.82 269.81 360.467 268.61 359.03C267.4 357.601 266.284 356.095 265.27 354.52C264.808 353.907 264.431 353.235 264.15 352.52C264.28 352.44 266.15 355.41 269 358.73C271.85 362.05 274.41 364.42 274.31 364.53Z"
        fill="#F5F5F5"
      />
      <path
        d="M287.12 361.49C286.714 361.161 286.34 360.797 286 360.4C285.32 359.68 284.36 358.62 283.2 357.29C280.86 354.63 277.73 350.88 274.43 346.61C271.13 342.34 268.3 338.34 266.32 335.41C265.32 333.94 264.55 332.75 264.03 331.91C263.732 331.49 263.471 331.045 263.25 330.58C263.621 330.951 263.956 331.356 264.25 331.79L266.68 335.2C268.68 338.07 271.6 342.02 274.89 346.29C278.18 350.56 281.27 354.29 283.53 357.05L286.2 360.27C286.542 360.648 286.85 361.057 287.12 361.49Z"
        fill="#F5F5F5"
      />
      <path
        d="M223.75 384.49C223.603 384.319 223.472 384.135 223.36 383.94C223.032 383.374 222.751 382.782 222.52 382.17C221.742 379.896 221.443 377.485 221.64 375.09C221.78 372.09 222.64 368.54 223.15 364.61C223.585 360.326 223.632 356.012 223.29 351.72C222.99 347.14 222.54 342.78 222.37 338.79C222.177 335.254 222.281 331.709 222.68 328.19C222.953 325.812 223.462 323.467 224.2 321.19C224.396 320.571 224.626 319.963 224.89 319.37C224.964 319.154 225.061 318.946 225.18 318.75C225.18 318.75 224.86 319.63 224.4 321.25C223.734 323.537 223.282 325.88 223.05 328.25C222.708 331.753 222.641 335.277 222.85 338.79C223.04 342.79 223.51 347.11 223.85 351.71C224.198 356.04 224.134 360.392 223.66 364.71C223.14 368.71 222.23 372.16 222.05 375.15C221.821 377.507 222.058 379.885 222.75 382.15C223.035 382.95 223.369 383.731 223.75 384.49Z"
        fill="#F5F5F5"
      />
      <path
        d="M212.76 387.91C211.865 387.58 211.033 387.1 210.3 386.49C208.311 384.793 206.833 382.577 206.03 380.09C205.456 378.387 205.12 376.614 205.03 374.82C204.949 372.768 205.052 370.713 205.34 368.68C205.91 364.39 207.06 359.79 207.43 354.85C207.828 350.21 207.788 345.542 207.31 340.91C207.1 338.77 206.88 336.72 206.88 334.79C206.84 332.992 207.073 331.198 207.57 329.47C208.284 326.935 209.903 324.751 212.12 323.33C212.744 322.968 213.418 322.699 214.12 322.53C214.36 322.481 214.604 322.447 214.85 322.43C213.941 322.656 213.071 323.016 212.27 323.5C210.155 324.949 208.627 327.105 207.96 329.58C207.499 331.277 207.294 333.032 207.35 334.79C207.35 336.69 207.6 338.72 207.83 340.86C208.33 345.511 208.383 350.199 207.99 354.86C207.61 359.86 206.45 364.47 205.86 368.71C205.555 370.711 205.438 372.737 205.51 374.76C205.571 376.517 205.877 378.257 206.42 379.93C207.174 382.375 208.573 384.571 210.47 386.29C211.19 386.888 211.955 387.43 212.76 387.91Z"
        fill="#F5F5F5"
      />
      <path
        d="M255.66 403C255.091 402.819 254.536 402.595 254 402.33L249.55 400.39C245.79 398.78 240.55 396.63 234.67 394.52C228.79 392.41 223.38 390.74 219.44 389.6L214.78 388.27C214.198 388.141 213.63 387.961 213.08 387.73C213.67 387.804 214.255 387.918 214.83 388.07C215.95 388.32 217.56 388.72 219.54 389.25C223.54 390.3 228.95 391.92 234.85 394.03C240.75 396.14 245.97 398.35 249.7 400.03C251.57 400.88 253.07 401.6 254.09 402.12C254.639 402.366 255.164 402.661 255.66 403Z"
        fill="#F5F5F5"
      />
      <path
        d="M207.31 401C207.31 401 207.24 401 207.14 400.92C206.977 400.839 206.819 400.745 206.67 400.64C206.092 400.245 205.55 399.8 205.05 399.31C203.205 397.501 201.734 395.347 200.72 392.97C200.404 392.164 200.215 391.314 200.16 390.45C200.06 389.57 199.96 388.66 199.85 387.71C199.65 385.82 199.42 383.8 199.19 381.71C198.708 377.077 197.977 372.474 197 367.92C196.072 363.349 195.569 358.703 195.5 354.04C195.39 349.75 195.57 345.89 195.77 342.66C195.97 339.43 196.2 336.81 196.37 335.01C196.46 334.15 196.53 333.46 196.59 332.94C196.599 332.696 196.636 332.455 196.7 332.22C196.716 332.463 196.716 332.707 196.7 332.95C196.7 333.47 196.63 334.16 196.59 335.03C196.48 336.83 196.3 339.45 196.15 342.68C196 345.91 195.86 349.76 195.99 354.03C196.089 358.659 196.601 363.271 197.52 367.81C198.485 372.36 199.196 376.96 199.65 381.59C199.86 383.72 200.07 385.74 200.26 387.64C200.36 388.58 200.45 389.5 200.54 390.38C200.581 391.212 200.753 392.032 201.05 392.81C202.017 395.16 203.418 397.308 205.18 399.14C205.656 399.639 206.167 400.104 206.71 400.53C206.922 400.67 207.123 400.827 207.31 401Z"
        fill="#F5F5F5"
      />
      <path
        d="M316 447.85C316 447.85 347.34 456.53 348.6 456.97C350.48 457.63 350.17 464.55 347.23 464.44C345.84 464.39 323.16 465.29 320.29 465.11C317.42 464.93 308.78 461.52 308.78 461.52L316 447.85Z"
        fill="white"
      />
      <path
        d="M329 459.81C329.623 457.443 330.865 455.285 332.6 453.56C331.489 454.177 330.568 455.084 329.933 456.185C329.299 457.286 328.977 458.539 329 459.81Z"
        fill="#263238"
      />
      <path
        d="M325.76 458.33C325.91 458.33 326.23 456.88 327.06 455.24C327.89 453.6 328.88 452.46 328.77 452.35C327.854 453.063 327.11 453.972 326.591 455.01C326.072 456.048 325.791 457.189 325.77 458.35L325.76 458.33Z"
        fill="#263238"
      />
      <path
        d="M322.69 457.51C322.848 456.389 323.092 455.282 323.42 454.2C323.91 453.179 324.482 452.199 325.13 451.27C324.139 451.94 323.376 452.896 322.94 454.01C322.504 455.125 322.417 456.345 322.69 457.51Z"
        fill="#263238"
      />
      <path
        d="M323.25 453.46C323.522 452.715 323.635 451.921 323.58 451.13C323.577 450.267 323.473 449.408 323.27 448.57C323.147 448.076 322.99 447.592 322.8 447.12C322.704 446.823 322.532 446.558 322.3 446.35C322.148 446.225 321.957 446.156 321.76 446.156C321.563 446.156 321.372 446.225 321.22 446.35C320.976 446.585 320.827 446.902 320.8 447.24C320.785 447.516 320.785 447.793 320.8 448.07C320.808 448.586 320.876 449.099 321 449.6C321.219 450.443 321.556 451.251 322 452C322.341 452.713 322.821 453.352 323.41 453.88C323.48 453.83 322.96 453.1 322.33 451.83C321.963 451.089 321.688 450.307 321.51 449.5C321.408 449.027 321.358 448.544 321.36 448.06C321.36 447.58 321.36 446.98 321.65 446.75C321.706 446.698 321.779 446.669 321.855 446.669C321.931 446.669 322.004 446.698 322.06 446.75C322.217 446.899 322.334 447.084 322.4 447.29C322.592 447.741 322.753 448.206 322.88 448.68C323.098 449.477 323.236 450.295 323.29 451.12C323.3 452.56 323.16 453.45 323.25 453.46Z"
        fill="#263238"
      />
      <path
        d="M323.21 453.14C322.575 452.886 321.892 452.777 321.21 452.82C320.458 452.816 319.709 452.907 318.98 453.09C318.533 453.186 318.102 453.344 317.7 453.56C317.441 453.69 317.233 453.901 317.106 454.162C316.98 454.422 316.942 454.716 317 455C317.072 455.27 317.215 455.516 317.413 455.712C317.612 455.909 317.859 456.05 318.13 456.12C318.593 456.235 319.077 456.235 319.54 456.12C320.268 455.927 320.949 455.587 321.54 455.12C322.111 454.738 322.604 454.249 322.99 453.68C322.409 454.02 321.851 454.397 321.32 454.81C320.741 455.211 320.089 455.494 319.4 455.64C319.019 455.735 318.621 455.735 318.24 455.64C318.055 455.599 317.886 455.507 317.751 455.375C317.617 455.242 317.523 455.074 317.48 454.89C317.448 454.704 317.482 454.513 317.577 454.35C317.672 454.187 317.822 454.063 318 454C318.358 453.802 318.741 453.654 319.14 453.56C319.827 453.358 320.535 453.234 321.25 453.19C322.43 453.1 323.2 453.23 323.21 453.14Z"
        fill="#263238"
      />
      <path
        d="M333.84 460.15C333.96 459.19 334.168 458.243 334.46 457.32C334.897 456.46 335.409 455.64 335.99 454.87C335.075 455.365 334.361 456.162 333.969 457.125C333.577 458.088 333.531 459.157 333.84 460.15Z"
        fill="#263238"
      />
      <path
        d="M312 461.59C312.38 461.825 312.786 462.017 313.21 462.16C314.348 462.6 315.514 462.964 316.7 463.25C320.64 464.109 324.669 464.488 328.7 464.38C333.39 464.33 337.62 464.11 340.7 463.99L344.34 463.8C344.787 463.792 345.231 463.745 345.67 463.66C345.227 463.589 344.778 463.566 344.33 463.59H340.69L328.69 463.82C324.706 463.913 320.723 463.578 316.81 462.82C313.76 462.27 312 461.5 312 461.59Z"
        fill="#263238"
      />
      <path
        d="M311.05 462.31C311.05 462.38 311.95 461.88 313.39 461.24C315.234 460.357 317.288 460.01 319.32 460.24C321.317 460.67 323.118 461.741 324.45 463.29C325.004 463.95 325.609 464.565 326.26 465.13C325.839 464.369 325.322 463.666 324.72 463.04C324.104 462.312 323.406 461.658 322.64 461.09C321.687 460.383 320.58 459.913 319.41 459.72C317.282 459.46 315.125 459.869 313.24 460.89C312.436 461.239 311.696 461.719 311.05 462.31Z"
        fill="#263238"
      />
      <path
        d="M282.7 439.2C282.7 439.2 253.05 458.62 252 459.42C250.41 460.63 252.24 466.37 255 465.42C256.3 464.95 281.19 458.03 283.87 456.99C286.55 455.95 293.74 450.07 293.74 450.07L282.7 439.2Z"
        fill="white"
      />
      <path
        d="M273.9 454.56C273.537 453.343 272.848 452.247 271.909 451.391C270.971 450.535 269.816 449.95 268.57 449.7C269.613 450.326 270.615 451.017 271.57 451.77C272.401 452.654 273.179 453.585 273.9 454.56Z"
        fill="#263238"
      />
      <path
        d="M276.56 452.16C276.26 451.029 275.63 450.012 274.75 449.24C273.993 448.35 272.992 447.7 271.87 447.37C271.8 447.51 273.1 448.29 274.38 449.61C275.66 450.93 276.42 452.23 276.56 452.16Z"
        fill="#263238"
      />
      <path
        d="M279.23 450.44C279.138 449.247 278.685 448.111 277.931 447.182C277.177 446.253 276.158 445.576 275.01 445.24C275.908 445.929 276.751 446.688 277.53 447.51C278.176 448.438 278.745 449.418 279.23 450.44Z"
        fill="#263238"
      />
      <path
        d="M277.47 446.75C277.55 446.75 277.14 445.91 276.8 444.54C276.603 443.733 276.486 442.909 276.45 442.08C276.42 441.594 276.42 441.106 276.45 440.62C276.454 440.399 276.513 440.183 276.62 439.99C276.661 439.925 276.726 439.879 276.801 439.862C276.876 439.845 276.955 439.859 277.02 439.9C277.36 440.03 277.54 440.61 277.7 441.06C277.842 441.519 277.94 441.992 277.99 442.47C278.062 443.293 278.035 444.123 277.91 444.94C277.7 446.35 277.42 447.2 277.51 447.23C277.902 446.544 278.163 445.791 278.28 445.01C278.463 444.163 278.527 443.295 278.47 442.43C278.427 441.915 278.334 441.406 278.19 440.91C278.11 440.65 278.01 440.4 277.9 440.13C277.767 439.822 277.53 439.571 277.23 439.42C277.058 439.343 276.864 439.328 276.682 439.377C276.5 439.426 276.34 439.537 276.23 439.69C276.07 439.959 275.984 440.266 275.98 440.58C275.887 441.947 276.046 443.32 276.45 444.63C276.632 445.402 276.98 446.126 277.47 446.75Z"
        fill="#263238"
      />
      <path
        d="M277.41 446.44C277.41 446.51 278.14 446.16 279.34 445.87C280.036 445.706 280.746 445.609 281.46 445.58C281.869 445.551 282.279 445.578 282.68 445.66C282.858 445.681 283.024 445.759 283.153 445.884C283.281 446.009 283.365 446.173 283.39 446.35C283.397 446.537 283.355 446.723 283.267 446.888C283.179 447.053 283.049 447.192 282.89 447.29C282.557 447.498 282.181 447.624 281.79 447.66C281.086 447.725 280.377 447.654 279.7 447.45C279.071 447.214 278.426 447.024 277.77 446.88C278.316 447.298 278.932 447.613 279.59 447.81C280.31 448.073 281.075 448.185 281.84 448.14C282.314 448.108 282.773 447.964 283.18 447.72C283.416 447.571 283.608 447.362 283.737 447.115C283.865 446.867 283.925 446.589 283.91 446.31C283.876 446.024 283.749 445.756 283.549 445.548C283.348 445.341 283.085 445.204 282.8 445.16C282.352 445.077 281.894 445.057 281.44 445.1C280.693 445.146 279.954 445.284 279.24 445.51C278.565 445.672 277.938 445.99 277.41 446.44Z"
        fill="#263238"
      />
      <path
        d="M258.76 464.3C258.76 464.3 259.26 464 259.49 463.15C259.728 462.044 259.527 460.89 258.93 459.93C258.638 459.41 258.303 458.915 257.93 458.45C257.648 458.045 257.312 457.681 256.93 457.37C256.663 457.145 256.336 457.006 255.99 456.97C255.74 456.97 255.61 456.97 255.62 457.05C256.018 457.164 256.384 457.37 256.69 457.65C257.011 457.981 257.299 458.342 257.55 458.73C257.884 459.195 258.191 459.679 258.47 460.18C259.003 461.044 259.235 462.06 259.13 463.07C259.027 463.486 258.904 463.896 258.76 464.3Z"
        fill="#263238"
      />
      <path
        d="M269.41 456.34C269.458 455.822 269.394 455.301 269.221 454.81C269.048 454.32 268.772 453.873 268.41 453.5C268.108 453.074 267.72 452.716 267.27 452.451C266.82 452.185 266.319 452.018 265.8 451.96C265.74 452.11 266.99 452.6 268 453.84C268.544 454.629 269.016 455.466 269.41 456.34Z"
        fill="#263238"
      />
      <path
        d="M290.679 451.08C290.225 451.344 289.79 451.642 289.38 451.97C288.206 452.791 286.984 453.539 285.719 454.21C283.846 455.202 281.909 456.068 279.92 456.8C277.67 457.64 275.149 458.43 272.489 459.2C267.149 460.73 262.319 462.14 258.839 463.2L254.719 464.46C254.21 464.597 253.711 464.774 253.229 464.99C253.752 464.931 254.268 464.821 254.77 464.66L258.939 463.55C262.449 462.55 267.3 461.25 272.64 459.71C275.3 458.94 277.829 458.13 280.089 457.26C282.091 456.503 284.033 455.598 285.899 454.55C287.156 453.838 288.359 453.036 289.499 452.15C289.93 451.837 290.326 451.478 290.679 451.08Z"
        fill="#263238"
      />
      <path
        d="M291.78 451.48C290.986 451.122 290.138 450.895 289.27 450.81C287.165 450.415 284.989 450.68 283.04 451.57C281.978 452.107 281.062 452.893 280.37 453.86C279.816 454.633 279.35 455.465 278.98 456.34C278.596 457.123 278.321 457.954 278.16 458.81C278.596 458.066 278.983 457.294 279.32 456.5C280.115 454.622 281.502 453.056 283.27 452.04C285.149 451.194 287.229 450.896 289.27 451.18C290.8 451.34 291.77 451.56 291.78 451.48Z"
        fill="#263238"
      />
      <path
        d="M344.33 391.851C344.33 391.851 353.23 395.021 353.27 405.001C353.34 424.111 314.66 445.171 314.66 445.171L263.18 411.061L259.96 409.761C259.96 409.761 329.44 383.661 344.33 391.851Z"
        fill="#263238"
      />
      <path
        d="M289.26 428.29C289.731 428.024 290.179 427.72 290.6 427.38C291.44 426.77 292.6 425.86 294.12 424.72C297.07 422.45 301.12 419.22 305.39 415.51C309.66 411.8 313.5 408.33 316.2 405.76C317.55 404.48 318.63 403.43 319.36 402.69C319.758 402.324 320.126 401.926 320.46 401.5C320.014 401.791 319.599 402.126 319.22 402.5L316 405.49C313.24 407.99 309.4 411.42 305.1 415.12C300.8 418.82 296.82 422.12 293.94 424.43L290.52 427.22C290.069 427.539 289.648 427.897 289.26 428.29Z"
        fill="#455A64"
      />
      <path
        d="M329.8 399C326.434 398.643 323.064 399.636 320.43 401.76C320.52 401.9 322.43 400.71 324.99 399.95C327.55 399.19 329.8 399.15 329.8 399Z"
        fill="#455A64"
      />
      <path
        d="M325.37 396C324.135 396.418 323.066 397.22 322.32 398.29C321.429 399.247 320.865 400.462 320.71 401.76C320.86 401.81 321.43 400.21 322.71 398.62C323.99 397.03 325.45 396.11 325.37 396Z"
        fill="#455A64"
      />
      <path
        d="M347.16 456.48C347.16 456.42 346.55 456.3 345.84 456.88C344.997 457.667 344.467 458.733 344.35 459.88C344.182 461.003 344.346 462.15 344.82 463.18C345.18 463.96 345.58 464.32 345.63 464.29C345.68 464.26 345.42 463.8 345.16 463.04C344.823 462.047 344.724 460.989 344.87 459.95C344.987 458.924 345.408 457.955 346.08 457.17C346.399 456.881 346.764 456.648 347.16 456.48Z"
        fill="#263238"
      />
      <path
        d="M332.49 360.63L314.16 407.1L235.22 408.39L233.76 404.12L261.43 403.12L274.72 361.89L332.49 360.63Z"
        fill="white"
      />
      <path
        d="M295.63 380.75C296.233 380.443 296.912 380.317 297.584 380.388C298.257 380.459 298.895 380.724 299.42 381.15C299.869 381.571 300.18 382.118 300.314 382.719C300.448 383.32 300.398 383.947 300.17 384.52C299.924 385.082 299.528 385.566 299.025 385.919C298.523 386.272 297.933 386.479 297.32 386.52C296.671 386.556 296.027 386.381 295.486 386.021C294.945 385.66 294.536 385.133 294.32 384.52C294.111 383.831 294.126 383.094 294.366 382.416C294.605 381.738 295.055 381.154 295.65 380.75"
        [attr.fill]="'var(--secondary-color)'"
      />
      <path
        d="M319.53 447.49C319.53 447.49 246.8 399.79 243.83 398.8C240.86 397.81 233.65 393.09 226.46 395.07C219.27 397.05 212.81 403.51 209.58 412.94C207.74 418.33 206.87 427.52 206.46 434.41C205.9 442.663 208.571 450.811 213.91 457.13C216.28 460.074 219.419 462.303 222.98 463.57C223.74 463.8 224.52 464 225.33 464.18C232.081 465.565 239.092 464.878 245.446 462.211C251.8 459.543 257.201 455.019 260.94 449.23L262.63 446.65C262.63 446.65 310.21 464.12 310.95 463.88L319.53 447.49Z"
        fill="#263238"
      />
      <path
        d="M229.56 434.36C229.56 434.36 229.69 434.43 229.96 434.54L231.15 435.01L235.76 436.78L252.89 443.24C260.17 445.96 268.89 449.18 278.67 452.74C288.45 456.3 299.29 460.2 310.87 464.12L311.08 464.19L311.19 464C313 460.53 314.92 456.86 316.87 453.15L319.76 447.61L319.87 447.4L319.67 447.27L264.2 412.06L247.53 401.54L243 398.72L241.82 398L241.41 397.77C241.532 397.866 241.658 397.956 241.79 398.04L242.94 398.81L247.42 401.71L264 412.36L319.38 447.71L319.29 447.37L316.39 452.9C314.45 456.62 312.53 460.28 310.71 463.76L311.03 463.63C299.46 459.72 288.61 455.84 278.82 452.31L253 442.91L235.81 436.61L231.17 434.92L229.96 434.49L229.56 434.36Z"
        fill="#455A64"
      />
    </svg>
  `,
})
export class AuthImgComponent {
}
