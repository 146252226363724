import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { CompanyDataModel } from '../../../variables/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FolderPath } from '../../../variables/constant';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';


const getLogo = async (companyService: CompanyService, fileName: string): Promise<string> => {

  let data = await companyService.getLogo(fileName)
    .toPromise()
    .catch(error => {
      if (error.message != undefined && error.message != "") {
        console.log(error.message);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'image/png' });
    console.log(window.URL.createObjectURL(blob));

    return window.URL.createObjectURL(blob);
  }

  return null;
}

const updateCompany = async (companyService: CompanyService, company: CompanyDataModel): Promise<CompanyDataModel> => {

  let response = await companyService.updateCompany(company)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CompanyDataModel>response["result"];

    return result;
  }

}

const uploadLogo = async (companyService: CompanyService, fileName: string, obj: FormData): Promise<any> => {

  let response = await companyService.uploadPdfFile(fileName, obj)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = response["result"];

    return result;
  }

}

const getCompany = async (companyService: CompanyService): Promise<CompanyDataModel> => {

  let response = await companyService.getCurrentCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CompanyDataModel>response["result"];

    return result;
  }

}


@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  frmCompany: UntypedFormGroup;
  submitted: boolean = false;  
  logoUrl: SafeResourceUrl;
  frmData: FormData = new FormData();
  company: CompanyDataModel;
  isUploadLogo: boolean; false;

  constructor(private companyService: CompanyService,
    private commonService: CommonService,
    public formBuilder: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router) {
    
    getCompany(companyService).then(data => {
      this.frmCompany.patchValue({
        companyId: data.companyId,
        companyName: data.companyName, 
        companyCode: data.companyCode, 
        emailAddress: data.emailAddress, 
      });
      getLogo(companyService, data.companyCode + ".png").then(data => {
        this.logoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      });
      console.log(this.logoUrl);
    });
  }

  ngOnInit(): void {
    this.frmCompany = this.formBuilder.group({
      companyId: null,
      companyName: ["", Validators.required],
      companyCode: ["", Validators.required],
      emailAddress: ["", Validators.required]
    });
  }

  get f() { return this.frmCompany.controls; }

  onFileChange(event) {
    let file = event.target.files[0];
    if (file != "") {
      this.isUploadLogo = true;
      this.frmData.append('file', file);
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {        
        return;
      }

      var reader = new FileReader();      
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
      this.logoUrl = reader.result;
      }
    }
    else {
      this.logoUrl = null;
    }
  }

  submitForm() {
    this.submitted = true;

    if (!this.frmCompany.invalid) {
      updateCompany(this.companyService, this.frmCompany.value).then(data => {
        if (this.isUploadLogo) {
          uploadLogo(this.companyService, this.f.companyCode.value + ".png", this.frmData).then(data => {
            if (data != "") {
              this.router.navigate(['admin']);
              this.commonService.setCurrentNav("Dashboard");
            }
          });
        }  
      });
          

      
    }    
  }
}
