import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';


@Injectable({
  providedIn: 'root'
})
export class StatesService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = 'https://dotnetcore-cvt.agiletechnologies.in/';
  }


  getStates() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/forms/states');
  }

  getAllStates() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/forms/customer-required-to-submit-states');
  }

  getAllStateAddress() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/forms/customer-required-to-submit-states');
  }

  getAllFormsByState(state) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/forms/state/' + state);
  }

  getFormById(id: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/forms/' + id);
  }
}

