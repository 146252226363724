import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { SystemRole } from '../../variables/constant';
import { CommonService } from 'src/app/services/common.service';
import { timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ThemeService } from 'src/app/common/theme.service';

export const NoWhiteSpaceValidator = Validators.pattern(/^\S*$/);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  otpForm: FormGroup;
  sendOTPForm!: FormGroup;
  loading = false;
  submitted = false;
  otpSubmitted = false;
  error = '';
  public companylogo = "../../assets/img/bcvt_logo.png";
  showLoginScreen: boolean = true;
  showSendOtpScreen: boolean = false;
  showOtpScreen: boolean = false;
  showOktaBtn: boolean = false;
  oktaLink: string = "";
  canResend: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private toastr: ToastrService,
    private themeService : ThemeService
  ) {
    this.commonService.companyLogo.subscribe((res) => {
      this.companylogo = res;
    });
    this.commonService._isOktaLink$.subscribe((res) => {
      if (res) {
        this.showOktaBtn = true;
        this.oktaLink = res;
      }
    })
    this.activatedRoute.queryParams.subscribe((param: Params) => {
      if (param['status'] == 's' && param['token']) {
        if (this.authService.currentUserValue != null) {
          this.authService.logout();
        }
        this.authService.setUserEmail("");
        this.commonService.setCurrentCompany(0);
        const paramsData = {
          token: param['token'],
          companyCode: param['companyCode']
        };

        this.authService.setAuth(paramsData);
        this.redirect();
      }
    });
  }

  ngOnInit() {
    const company_code = localStorage.getItem('company_code');
    if (company_code) {
      this.themeService.loadTheme(company_code);
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      OtpCode: ['']
    });
    this.sendOTPForm = this.formBuilder.group({
      type: ["", [Validators.required]]
    });
    this.otpForm = this.fb.group({
      OtpCode: ["", Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  get otp() { return this.otpForm.controls; }

  sendOTP(isSendOtp?: boolean) {
    this.submitted = true;
    if (this.loginForm.invalid) { return; }
    this.loading = true;

    let payload: any = {
      username: this.loginForm.value['username'],
      password: this.loginForm.value['password'],
      isNeedToSendOTP: false,
      companyCode: localStorage.getItem('company_code')
    };

    if (isSendOtp) {
      payload.isNeedToSendOTP = true;
      payload.isSendOTPOnMobile = this.sendOTPForm.value['type'] == 'SMS' ? true : false
    }

    this.authService.checkIfPasswordShouldUpdate(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            window.location.href = '/changepassword/' + data;
            // this.router.navigate(['changepassword/' + data]);
          } else {
            this.authService.sendOTP(payload)
              .pipe(first())
              .subscribe({
                next: () => {
                  if (isSendOtp) {
                    this.showOtpScreen = true;
                    this.showLoginScreen = false;
                    this.showSendOtpScreen = false;
                  } else {
                    this.showSendOtpScreen = true;
                    this.showOtpScreen = false;
                    this.showLoginScreen = false;
                  }
                  this.loading = false;
                },
                error: (error) => {
                  console.log(error);
                  alert(error.ResponseException);
                  this.error = error;
                  this.loading = false;
                }
              })
          }
        }, (error) => {
          this.error = error;
          this.loading = false;
          alert(error.ResponseException);
        })
  }

  onSubmit() {
    this.otpSubmitted = true;
    if (this.otpForm.invalid) { return; }

    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value, this.otp.OtpCode.value?.toString(), localStorage.getItem('company_code'))
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.redirect();
          // this.router.navigate([returnUrl]);
        },
        error: (error) => {
          console.log(error);
          this.error = error;
          this.loading = false;
          this.toastr.error(error.message, 'Error');
        }
      })
  }

  resendOTP() {
    this.sendOTP(true);
    this.canResend = false;

    timer(60000).subscribe(() => {
      this.canResend = true;
    });
  }

  redirect() {
    let returnUrl = "";
    if (this.authService.userRole$ == SystemRole.ADMIN || this.authService.userRole$ == SystemRole.COMPANYADMIN) {
      returnUrl = 'admin';//this.route.snapshot.queryParams['returnUrl'] || '/admin';                    
    }
    else if (this.authService.userRole$ == SystemRole.CLIENT) {
      returnUrl = "client";
      console.log(64);
    }
    window.location.href = returnUrl;
  }
}
