import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { SystemRole } from "../variables/constant";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    
    if (currentUser && this.authService.userRole$ == SystemRole.ADMIN) { 
      return true;
    }

    if (currentUser && this.authService.userRole$ == SystemRole.COMPANYADMIN) { 
      return true;
    }

    if (currentUser && this.authService.userRole$ == SystemRole.CLIENT) {
      return true;
    }

    if (state == undefined) {
      this.router.navigate(['/login']);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
    return false;
  }

}
