import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SystemRole } from '../../variables/constant';

const logout = async (authService: AuthService): Promise<String> => {

  let response = await authService.logoutCustomer()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"]
    }

    return "";
  }

}

const validateSession = async (authService: AuthService, src: string): Promise<String> => {

  let response = await authService.validateSession(src)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"]
    }

    return "";
  }

}
@Component({
  selector: 'app-customer-layout',
  templateUrl: './customer-layout.component.html',
  styleUrls: ['./customer-layout.component.scss']
})
export class CustomerLayoutComponent implements OnInit {
  public companylogo: string = "./assets/img/bertelsmann.png";
  test: Date = new Date();
  
  constructor(private location: Location,
    public authService: AuthService,
    public commonService: CommonService,
    private router: Router) {

      this.commonService.companyLogo.subscribe((res)=>{
        this.companylogo = res;
      })
    
    if (router.url != "/eCert") {
      if (this.authService.userRole$ != SystemRole.CUSTOMER || this.authService.currentUserValue == null) {
        this.router.navigate(['/eCert']);
      }        
    }
  }

  ngOnInit(): void {
  }

  logout() {
    logout(this.authService).then(data => {
      if (data == "Logged out successfully") {
        sessionStorage.clear();
        this.authService.logout();
        this.router.navigate(['/eCert']);
      }
    });
  }

  checksession() {
    validateSession(this.authService, "eCert").then(data => {
      if (data == "Session expired. Please login again.") {
        sessionStorage.clear();
        this.authService.logout();
        this.router.navigate(['/eCert']);
      }
    });
  }
}
