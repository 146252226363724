import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatesService } from '../../../services/states.service';
import { FormsByStatesDataModel } from '../../../variables/states';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { PdfService } from '../../../services/pdf.services';
import { UploadModel, UploadResultModel } from '../../../variables/customer';

const uploadPdf = async (pdfService: PdfService, fileName: string, obj: FormData): Promise<any> => {

  let response = await pdfService.uploadPdfFile(fileName, obj)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response instanceof Object) {      
      let result = response["result"];

      return result;
    }
    return null;
  }

}

const afterUploadPdf = async (pdfService: PdfService, obj: UploadModel): Promise<UploadResultModel> => {

  let response = await pdfService.afterUploadPdf(obj)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <UploadResultModel>response["result"];

    return result;
  }

}


const getFormById = async (statesService: StatesService, id: number): Promise<FormsByStatesDataModel> => {

  let response = await statesService.getFormById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response instanceof Object) {
      if (response["message"] != "" && response["message"] != undefined) {
        alert(response["message"]);
      }
      let result = <FormsByStatesDataModel>response["result"];

      return result;
    }
    return null;
  }

}



@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']  
})
export class UploadComponent implements OnInit {
  formId: number = null;
  formName: string = "";
  state: string = "";
 
  selectedFile: any =null;
  destinationFile: SafeResourceUrl;

  consentForm: UntypedFormGroup;
  consentSubmitted: boolean = false;

  isSummary: boolean = false;
  dateTimeNow: Date = new Date();
  newFileName: string = "";
  referenceNumber: string = "";
  emailRecipient: string = "";

  frmData: FormData = new FormData();
  isLoading: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private statesService: StatesService,
    private authService: AuthService,
    private pdfService: PdfService,
    public sanitizer: DomSanitizer,
    private activeModal: NgbModal,
    route: ActivatedRoute,
    router: Router
  ) {
    this.formId = parseInt(route.snapshot.params["id"]);

    if (this.formId != undefined && this.formId != null) {
      getFormById(statesService, this.formId).then(data => {
        if (data != undefined && data != null) {
          this.state = data.state;
          this.formName = data.form;

          this.consentForm = this.formBuilder.group({
            hasConsent: [false, Validators.requiredTrue]
          });
        }
        else {
          router.navigate(["/eCert/certificates"]);
        }
      });
    }
  }

  ngOnInit(): void {
  }

  get cf() { return this.consentForm.controls; }

  onFileChange(event) {
    let file= event.target.files[0];
    if (file != "") {
      this.selectedFile = file;      
      let urlToBlob = window.URL.createObjectURL(file);
      this.destinationFile = this.sanitizer.bypassSecurityTrustResourceUrl(urlToBlob);      
      this.frmData.append('file', file);
    }
    else {
      this.destinationFile = null;
    }    
  }

  openModalConsent(modal) {
    this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  onConsentSubmit() {
    this.consentSubmitted = true;
    if (this.consentForm.invalid) {
      return;
    }
    else {
      this.activeModal.dismissAll();
      this.submitForm();
    }
  }

  submitForm() {      
    this.isLoading = true;
    let dateNow: Date = new Date();
    let ts: string = dateNow.getFullYear().toString() + dateNow.getMonth().toString() + dateNow.getDate().toString();
    ts += "_" + dateNow.getHours().toString() + dateNow.getMinutes().toString() + dateNow.getSeconds().toString();
    this.newFileName = this.authService.getCustomerNumber() + "_" + this.formName + "_" + ts + ".pdf";
    this.dateTimeNow = dateNow;
    this.emailRecipient = this.authService.getEmail();
    let obj: UploadModel = <UploadModel>{
      fileName: this.newFileName,
      dateSubmitted: this.dateTimeNow,
      formId: this.formId,
      formName: this.formName
    };
    
    uploadPdf(this.pdfService, this.newFileName, this.frmData).then(data => {
      if (data != "") {
        afterUploadPdf(this.pdfService, obj).then(data => {
          if (data != null && data != undefined) {
            this.referenceNumber = data.referenceNumber;
            this.isSummary = true;
            this.isLoading = false;
          }          
        });
      }      
    });

    
  }
}
